import {createContext} from 'react'

export const ProjectDetailsContext = createContext({
  projectDetails: {} as any,
  defaultProjectMapping: {} as any,
  isLoading: false,
})

export const AgileBoardPageContext = createContext({
  hasBoardPermission: false,

  projectId: null as any,
  projectName: '',
  projectStatus: '',

  agileBoardStructure: [],
  isLoadingBoardStructure: false,

  agileBoardData: [],
  isLoadingAgileBoardData: false,

  projectMembers: [],
  isLoadingProjectMembers: false,

  projectKanbanStages: [],
  isLoadingProjectKanbanStages: false,

  projectModules: [],
  isLoadingProjectModules: false,

  projectStatuses: [],
  isLoadingProjectStatuses: false,

  projectPriorities: [],
  isLoadingProjectPriorities: false,

  createTicket: (moduleId: string, data: any, setError: Function) => {},
  isLoadingTicketOperation: false,

  updateAgileBoard: (updatedTaskDetails: any, taskId: string) => {},
  isUpdatingAgileBoard: false,
})

export const ProjectMembersPageContext = createContext({
  hasMembersPermission: false,

  projectId: null as any,
  projectName: '',
  projectStatus: '',

  projectMembersList: [],
  isLoadingProjectMembersList: false,
  filters: {},
  onSearch: (e: string) => {},

  membersList: [],
  isLoadingMembersList: false,

  addProjectMembers: (memberDetails: any) => {},
  removeProjectMember: (memberID: number) => {},
  transferProjectOwnership: (payload: any) => {},
  isLoadingOperation: false,
})

export const BoardSettingsPageContext = createContext({
  hasBoardSettingsPermission: false,

  projectId: null as any,
  projectName: '',
  projectStatus: '',

  boardColumnsList: [],
  isLoadingBoardColumnsList: false,

  boardModulesList: [],
  isLoadingBoardModulesList: false,

  boardCustomFieldsList: [],
  isLoadingBoardCustomFieldsList: false,

  createColumn: (data: any, setError: Function) => {},
  updateColumn: (moduleId: string, data: any, dirtyFields?: any, setError?: Function) => {},
  updateColumnTableSorting: (columnTableRowOrder: any) => {},
  deleteColumn: (moduleId: string) => {},
  columnOperationId: null,
  isLoadingColumnOperation: false,

  createModule: (data: any, setError: Function) => {},
  updateModule: (moduleId: string, data: any, dirtyFields?: any, setError?: Function) => {},
  updateModuleTableSorting: (moduleTableRowOrder: any) => {},
  deleteModule: (moduleId: string) => {},
  moduleOperationId: null,
  isLoadingModulesOperation: false,

  createCustomField: (customFieldDetails: any, setError: Function) => {},
  updateCustomField: (
    cFieldId: string,
    customFieldDetails: any,
    dirtyFields?: any,
    setError?: Function
  ) => {},
  updateCustomFieldTableSorting: (customFieldsTableRowOrder: any) => {},
  deleteCustomField: (cFieldId: string) => {},
  customFieldOperationId: null,
  isLoadingCustomFieldOperation: false,

  projectMappingDetails: {} as any,
  setProjectMappingDetails: (projectMappingDetails: any) => {},
  projectTablesDropdownList: [],
  isLoadingProjectTablesDropdownList: false,
  tableColumnsDropdownList: [],
  isLoadingTableColumnsDropdownList: false,
  tableStatusDropdownList: [],
  isLoadingTableStatusDropdownList: false,
  updateTableColumn: () => {},
  mapProjectTableStatus: (selectedOption: any, columnId: number, setError: Function) => {},
  isLoadingProjectMappingOperations: false,
})
