interface RoleActionDropdownTypes {
  roleId: string
  onEdit: any
  handleModel: any
  // toggleEditRoleModal: () => void
}

export default function RoleActionDropdown(props: RoleActionDropdownTypes) {
  const {onEdit, handleModel} = props

  return (
    <>
      <div className='px-4 py-2' onClick={onEdit}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-4 py-2'>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label onClick={handleModel}>Delete</label>
        </span>
      </div>
    </>
  )
}
