import {useContext} from 'react'
import {ReachSelect} from '../../../../../components/reachSelect'
import ColumnActionsOverlay from '../../overlays/ColumnActionsOverlay'
import {BoardSettingsPageContext} from '../../../context'
import {useForm} from 'react-hook-form'
import {getBadgeColor} from '../../../../../utils/badge'

const ColumnsTableRow = ({
  dndSettings,
  row,
  hasWritePermission,
  dndImage,
  statusMappingDropdownList,
}: any) => {
  const {
    isLoadingTableStatusDropdownList,
    mapProjectTableStatus,
    isLoadingProjectMappingOperations,
    projectMappingDetails,
    isLoadingColumnOperation,
    columnOperationId,
  } = useContext(BoardSettingsPageContext)

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {errors},
  } = useForm<any>({
    defaultValues: {
      columnValue: row?.mappedColumnValue,
      columnId: row?.columnId,
    },
  })

  const handleMapProjectTableStatus = () => {
    mapProjectTableStatus(watch('columnValue'), row?.columnId, setError)
  }

  const {columnId} = projectMappingDetails
  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row?.columnName}
      </td>
      <td>{row?.description}</td>
      {!!columnId && (
        <td>
          <ReachSelect
            id={row?.columnId}
            control={control}
            registerKey='columnValue'
            options={statusMappingDropdownList}
            labelKey='label'
            valueKey='label'
            disabledKey='disabled'
            isNullable={true}
            isLoading={isLoadingTableStatusDropdownList || isLoadingProjectMappingOperations}
            disabled={
              isLoadingTableStatusDropdownList ||
              isLoadingProjectMappingOperations ||
              !hasWritePermission
            }
            onChange={handleSubmit(handleMapProjectTableStatus)}
            error={errors?.columnValue}
          />
        </td>
      )}
      <td>
        <div className='text-center'>{row?.cards}</div>
      </td>
      <td>
        <div className='text-center'>
          <span
            className={`badge ${getBadgeColor(
              row?.isVisible ? 'active' : 'inactive',
              'light'
            )} badge-lg`}
          >
            <div className='align-items-center'>{row?.isVisible ? 'Enabled' : 'Disabled'}</div>
          </span>
        </div>
      </td>
      {hasWritePermission && (
        <td>
          {isLoadingColumnOperation && columnOperationId === row?.columnId ? (
            <div className='d-flex justify-content-center'>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </div>
          ) : (
            <ColumnActionsOverlay columnId={row?.columnId} columnData={row} />
          )}
        </td>
      )}
    </tr>
  )
}

export default ColumnsTableRow
