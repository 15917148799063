import Loading from '../../../../loading'
import {useContext, useEffect, useState} from 'react'
import {HideProductPagesContext} from '../../contexts'
import usePermission from '../../../../../hook/usePermission'
import {toggleSortingOrder} from '../../../../../utils/common'
import ProductRuleTableRow from './ProductRuleTableRow'
import {RenderBothArrow} from '../../../../../utils/renderBothArrow'
import NoDataFound from '../../../../../components/NoDataFound'

function ProductRuleTable() {
  const {productRules, onSortingChange, filters, isFetching, loading}: any =
    useContext(HideProductPagesContext)
  const [direction, setDirection] = useState('')
  const {hasPermission} = usePermission()

  useEffect(() => {
    if (filters) {
      if (filters['sort_order'] === '-1') {
        setDirection('desc')
      } else {
        setDirection('asc')
      }
    }
  }, [filters])

  return (
    <>
      <div className='card-body p-0'>
        <div className='table-modes '>
          <div className='table-responsive-wrapper'>
            <div className='table-responsive position-relative'>
              <div className='table-loader-wrapper'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'>
                  <thead className='table-row-bordered'>
                    <tr className='fs-6 fw-semibold text-muted text-uppercase'>
                      <th className='min-w-300px'>Product Name</th>
                      <th className='min-w-150px'>SKU</th>
                      <th className='min-w-175px'>Promo Threshhold</th>
                      {/* <th className='min-w-120px'>Representative</th> */}
                      <th className='min-w-150px'>Disable promo</th>
                      <th className='min-w-120px'>Promo Id</th>
                      <th className='min-w-200px'>Out Of Stock Days</th>
                      <th className='min-w-100px'>Cat. Id</th>
                      <th className='min-w-250px'>Classification</th>
                      <th
                        className='min-w-200px'
                        onClick={() => onSortingChange('created_at', toggleSortingOrder(direction))}
                      >
                        <div className='d-flex align-items-center'>
                          <span className='me-2'>Created Date</span>

                          {direction === 'asc' && filters['sort_by'] === 'created_at' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && filters['sort_by'] === 'created_at' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                      <th
                        className='min-w-150px'
                        onClick={() => onSortingChange('is_active', toggleSortingOrder(direction))}
                      >
                        <div className='d-flex align-items-center'>
                          <span className='me-2'>Status</span>

                          {direction === 'asc' && filters['sort_by'] === 'is_active' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && filters['sort_by'] === 'is_active' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                      {hasPermission('cms_webpages', 'write') && (
                        <th className='w-80px text-center'>Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {productRules && productRules.length > 0
                      ? productRules.map((row: any) => (
                          <ProductRuleTableRow row={row} key={row['id']} />
                        ))
                      : !(isFetching || loading) && <NoDataFound colspan={6} />}
                  </tbody>
                </table>
                {isFetching || loading ? <Loading /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductRuleTable
