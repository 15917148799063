import {Pagination} from '../../../utils/pagination'
import {useGetMembersList} from '../hooks/members/useGetMembersList'
import {ProjectMembersPageContext} from '../context/index'
import {MemberPageHeader} from '../sections/headers/MemberPageHeader'
import {useGetDropdownMembers} from '../hooks/members/useGetDropdownMembers'
import MemberPageTable from '../sections/tables/memberTables/MemberPageTable'
import usePermission from '../../../hook/usePermission'
import useProjectMembersOperations from '../hooks/members/useProjectMembersOperations'
import {useLocation, useParams} from 'react-router-dom'
import {toTitleCase} from '../../../utils/string'

function Members() {
  const {projectId, projectName, projectStatus}: any = useParams<any>()
  const {hasPermission} = usePermission()
  const {pathname} = useLocation()
  const hasMembersPermission =
    hasPermission('projects_all projects', 'write') && !pathname.includes('archived')
  const {
    projectMembersList,
    isLoading: isLoadingProjectMembersList,
    pagination,
    onSearch,
    onPageChange,
    filters,
  }: any = useGetMembersList()
  const {
    addProjectMembers,
    removeProjectMember,
    transferProjectOwnership,
    isLoading: isLoadingOperation,
  } = useProjectMembersOperations()
  const {membersList, isLoading: isLoadingMembersList} = useGetDropdownMembers()

  const contextValue = {
    hasMembersPermission,

    projectId,
    projectName: toTitleCase(projectName),
    projectStatus,

    projectMembersList,
    isLoadingProjectMembersList,
    filters,
    onSearch,

    membersList,
    isLoadingMembersList,

    addProjectMembers,
    removeProjectMember,
    transferProjectOwnership,
    isLoadingOperation,
  }

  return (
    <>
      <ProjectMembersPageContext.Provider value={contextValue}>
        <MemberPageHeader />
        <MemberPageTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectMembersPageContext.Provider>
    </>
  )
}

export default Members
