import {useContext, useEffect, useState} from 'react'
import Loading from '../../../loading'
import NoDataFound from '../../../../components/NoDataFound'
import AggregatedProductReportTableRow from './AggregatedProductReportTableRow'
import {AnalyticsDashboardContext} from '../../contexts'
import {Link, useLocation} from 'react-router-dom'
import {toggleSortingOrder} from '../../../../utils/common'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'

const AggregatedProductReportTable = () => {
  const location = useLocation()
  const dateRangeLable = localStorage.getItem('dateRangeLable')
  const length = location?.pathname?.split('/')?.length
  const isTableView = location?.pathname?.split('/')?.[length - 1] !== 'dashboard'
  const {
    // dateRangeLable: period,
    AggregatedProductReportData,
    AggregatedProductReportMeta,
    isAggregatedProductReportDataLoading,
    filters,
    onSortingChange,
  }: any = useContext(AnalyticsDashboardContext)

  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')

  useEffect(() => {
    if (filters) {
      const array = filters['sort_by']?.split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [filters])
  return (
    <div className={`${isTableView ? 'pt-15' : 'card-body  card card-flush  border p-7'}`}>
      <div className='pb-5'>
        <div className='row d-flex align-items-center'>
          <div className='col-xl-9 col-md-8 d-flex mb-5 align-items-center'>
            {!isTableView && (
              <h2 className='text-dark fw-bolder me-5 mb-0'>Aggregated Products Report</h2>
            )}
          </div>
          <div className='col-xl-3 col-md-4 mb-5'>
            <div className='text-end sm-text-start'>
              <span>
                Period: <strong>{dateRangeLable}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table
            id='kt_datatable_vertical_scroll'
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15 sold-product-fix-table'
          >
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted text-uppercase'>
                <th className='min-w-300px'>Product Name</th>
                <th className='min-w-80px'>Available</th>
                <th
                  className='min-w-120px'
                  onClick={() => onSortingChange('quantity', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center head-col-sticky'>
                    <span className='me-2 '>{'Sold Qty'}</span>

                    {direction === 'asc' && sortBy === 'quantity' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'quantity' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th className='min-w-80px'>{AggregatedProductReportMeta?.month_4}</th>
                <th className='min-w-80px'>{AggregatedProductReportMeta?.month_3}</th>
                <th className='min-w-80px'>{AggregatedProductReportMeta?.month_2}</th>
                <th className='min-w-80px'>{AggregatedProductReportMeta?.month_1}</th>
                <th className='min-w-80px'>Total</th>
              </tr>
            </thead>
            <tbody>
              {AggregatedProductReportData && AggregatedProductReportData.length > 0
                ? AggregatedProductReportData.map((row: any) => (
                    <AggregatedProductReportTableRow row={row} key={row['id']} />
                  ))
                : !isAggregatedProductReportDataLoading && <NoDataFound colspan={8} />}
              {/* <ConsignmentsTableRow parent_data={parent_data} /> */}
            </tbody>
          </table>

          {isAggregatedProductReportDataLoading && <Loading />}
        </div>
      </div>
      {!isTableView && (
        <>
          <div className='mt-2'>
            <Link
              to='/analytics/sold-products/aggregated-product-report'
              className='btn btn-link btn-color-black btn-active-color-primary text-primary text-hover-dark text-decoration-underline'
            >
              View More
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export {AggregatedProductReportTable}
