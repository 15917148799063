/* eslint-disable */
import {
  $applyNodeReplacement,
  $isTextNode,
  DOMConversion,
  DOMConversionMap,
  DOMConversionOutput,
  NodeKey,
  TextNode,
  SerializedTextNode,
  LexicalNode,
  EditorConfig,
} from 'lexical'

// Extend the SerializedTextNode to include className
type SerializedExtendedTextNode = SerializedTextNode & {
  className?: string
  attributes?: Record<string, string>
}

// ExtendedTextNode with support for custom styling and class preservation.
export class ExtendedTextNode extends TextNode {
  __className: string
  __attributes: Record<string, string>

  constructor(
    text: string,
    className: string = '',
    attributes: Record<string, string> = {},
    key?: NodeKey
  ) {
    super(text, key)
    this.__className = className
    this.__attributes = attributes
  }

  static getType(): string {
    return 'extended-text'
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__className, node.__attributes, node.__key)
  }

  // Override createDOM to apply className on creation
  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config)
    if (this.__className) {
      dom.className = this.__className
    }
    for (const [key, value] of Object.entries(this.__attributes)) {
      dom.setAttribute(key, value)
    }
    return dom
  }

  // Override updateDOM to apply className on updates
  updateDOM(prevNode: ExtendedTextNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config)
    if (prevNode.__className !== this.__className) {
      dom.className = this.__className
      return true
    }
    for (const [key, value] of Object.entries(this.__attributes)) {
      dom.setAttribute(key, value)
    }
    return isUpdated
  }

  // Import DOM with custom style and class handling for specific tags.
  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM()
    return {
      ...importers,
      code: () => ({
        conversion: patchStyleConversion(importers?.code),
        priority: 1,
      }),
      em: () => ({
        conversion: patchStyleConversion(importers?.em),
        priority: 1,
      }),
      span: () => ({
        conversion: patchStyleConversion(importers?.span),
        priority: 1,
      }),
      strong: () => ({
        conversion: patchStyleConversion(importers?.strong),
        priority: 1,
      }),
      sub: () => ({
        conversion: patchStyleConversion(importers?.sub),
        priority: 1,
      }),
      sup: () => ({
        conversion: patchStyleConversion(importers?.sup),
        priority: 1,
      }),
    }
  }

  // Import JSON data to create an ExtendedTextNode, including className.
  static importJSON(serializedNode: SerializedExtendedTextNode): TextNode {
    const node = new ExtendedTextNode(serializedNode.text, serializedNode.className || '')
    node.setFormat(serializedNode.format)
    return node
  }

  // Check for simplified text node conditions.
  isSimpleText() {
    return this.__type === 'extended-text' && this.__mode === 0
  }

  // Set or update attributes
  setAttributes(attributes: Record<string, string>): void {
    const writableNode = this.getWritable()
    writableNode.__attributes = {...writableNode.__attributes, ...attributes}
  }

  getAttributes(): Record<string, string> {
    return this.__attributes
  }

  // Export JSON data for ExtendedTextNode, including className.
  exportJSON(): SerializedExtendedTextNode {
    return {
      ...super.exportJSON(),
      type: 'extended-text',
      version: 1,
      className: this.__className,
      attributes: this.__attributes,
    }
  }

  // Getter and setter for className property
  getClassName(): string {
    return this.__className
  }

  setClassName(className: string): void {
    const self = this.getWritable()
    self.__className = className
  }
}

// Factory function to create an ExtendedTextNode.
export function $createExtendedTextNode(text: string, className: string = ''): ExtendedTextNode {
  const node = new ExtendedTextNode(text, className)
  return $applyNodeReplacement(node)
}

// Type guard to check if a node is an ExtendedTextNode.
export function $isExtendedTextNode(
  node: LexicalNode | null | undefined
): node is ExtendedTextNode {
  return node instanceof ExtendedTextNode
}

// Utility to validate attribute names
function isValidAttributeName(name: string): boolean {
  // Check for valid HTML attribute names (basic alphanumeric check)
  return /^[a-zA-Z_:][-a-zA-Z0-9_:.]*$/.test(name)
}

function patchStyleConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    const original = originalDOMConverter?.(node)
    if (!original) {
      return null
    }
    const originalOutput = original.conversion(node)

    if (!originalOutput) {
      return originalOutput
    }

    const className = node.className
    const style = node.getAttribute('style') || ''

    // Capture valid attributes only
    const additionalAttributes: Record<string, string> = {}
    Array.from(node.attributes).forEach((attr) => {
      if (attr.name !== 'style' && attr.name !== 'class' && isValidAttributeName(attr.name)) {
        additionalAttributes[attr.name] = attr.value
      }
    })

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? ((x) => x)
        const result = originalForChild(lexicalNode, parent)

        if ($isTextNode(result)) {
          // Set the class, style, and valid custom attributes
          if (className) (result as ExtendedTextNode).setClassName(className)
          ;(result as ExtendedTextNode).setAttributes(additionalAttributes)
          result.setStyle(style)
        }
        return result
      },
    }
  }
}
