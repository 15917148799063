import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'
import {useState} from 'react'

const useProjectMembersOperations = () => {
  const Api = useApi()
  const {projectId} = useParams()
  const [isLoading, setLoading] = useState(false)

  const addProjectMembers = (payload: any) => {
    return Api.post(`/agile/projects/${projectId}/members`, payload, {
      setLoading: setLoading,
      refetch: ['project-members-list', 'projects-members-dropdown-list'],
    })
  }

  const removeProjectMember = (memberID: number) => {
    return Api.delete(
      `/agile/projects/${projectId}/members/${memberID}`,
      {},
      {
        setLoading: setLoading,
        successMessage: 'Member removed successfully',
        refetch: ['project-members-list', 'projects-members-dropdown-list'],
      }
    )
  }

  const transferProjectOwnership = (payload: any) => {
    return Api.put(`/agile/projects/${projectId}/members`, payload, {
      setLoading: setLoading,
      successMessage: 'Ownership transferred successfully',
      refetch: ['project-members-list', 'project-details'],
    })
  }

  return {
    addProjectMembers,
    removeProjectMember,
    transferProjectOwnership,
    isLoading,
  }
}

export default useProjectMembersOperations
