import {useContext} from 'react'
import {formatDate} from '../../../../../utils/date'
import {ProjectDetailsContext} from '../../../context'

const MemberPageTableRow = ({row, actionComponent}: any) => {
  const {projectDetails} = useContext(ProjectDetailsContext)
  return (
    <tr className={!row?.is_active ? 'opacity-50' : ''}>
      <td>
        <div className='align-items-center'>{row['name']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['username']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['role']}</div>
      </td>
      <td>
        <div className='text-center'>{row['card_count']}</div>
      </td>
      <td>
        <div className='align-items-center'>{formatDate(row['created_at'], false)}</div>
      </td>
      {actionComponent &&
        (projectDetails?.is_owner && row?.is_owner ? (
          <td>{actionComponent(row)}</td>
        ) : row?.card_count === 0 && !row?.is_owner ? (
          <td>{actionComponent(row)}</td>
        ) : null)}
    </tr>
  )
}

export default MemberPageTableRow
