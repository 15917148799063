import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectMembersListingModalTypes} from '../../interfaces'
import useGetProjectMembersList from '../../hooks/useGetProjectMembersList'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import Initials from '../../../../components/Initials'
import {projectMembersListingModalColumns} from '../../utils'

const ProjectMembersListingModal = ({
  show,
  onClose,
  projectId,
}: ProjectMembersListingModalTypes) => {
  const {projectMembersList, isLoading} = useGetProjectMembersList(projectId)

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={'Project Members'}
      bodyClass='mh-550px overflow-auto'
      body={
        <DynamicTable
          data={projectMembersList}
          sortableColumns={projectMembersListingModalColumns}
          loading={isLoading}
          tableClass='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
          noDataMessage='No project members found'
          TableRow={({row}: any) => {
            return (
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-circle symbol-25px me-4'>
                      <Initials text={row?.name?.charAt(0)?.toUpperCase()} />
                    </div>
                    <div className='d-flex flex-column justify-content-center'>
                      <span className='fw-bold fs-6'>{row?.name}</span>
                      <span className='text-gray-600 fs-8'>{row?.username}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='text-end'>
                    <span className='fs-6 fw-semibold'>{row?.role}</span>
                  </div>
                </td>
              </tr>
            )
          }}
        />
      }
    />
  )
}

export default ProjectMembersListingModal
