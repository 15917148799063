import {useContext, useState} from 'react'
import DndTable from '../../../../../components/dndTable/DndTable'
import AddBoardCustomFieldsModal from '../../modals/AddBoardCustomFieldsModal'
import {BoardSettingsPageContext} from '../../../context'
import CustomFIeldsTableRow from './CustomFIeldsTableRow'
import {boardCustomFieldsColumns} from '../../../utils'

export default function CustomFieldsTable() {
  const {
    updateCustomFieldTableSorting,
    boardCustomFieldsList,
    isLoadingBoardCustomFieldsList,
    hasBoardSettingsPermission,
  } = useContext(BoardSettingsPageContext)

  const [showModal, setShowModal] = useState(false)

  const handleUpdatedTableRows = (tableData: any) => {
    const customFieldsTableRowOrder = {
      project_custom_field_ids: tableData.map((item: any) => item.id),
    }
    updateCustomFieldTableSorting(customFieldsTableRowOrder)
  }

  return (
    <div className='position-relative'>
      <div className='d-flex justify-content-between align-items-center mb-7'>
        <h4 className='fs-3 fw-bolder mb-0'>Custom Fields</h4>
        {hasBoardSettingsPermission && (
          <div className='d-flex justify-content-end'>
            {!isLoadingBoardCustomFieldsList && (
              <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
                <i className='fa-solid fa-plus'></i>Add Custom Field
              </button>
            )}
            {showModal && (
              <AddBoardCustomFieldsModal
                show={showModal}
                isEdit={false}
                onClose={() => setShowModal(false)}
              />
            )}
          </div>
        )}
      </div>

      <DndTable
        id='board-custom-fields-table'
        data={boardCustomFieldsList}
        columns={boardCustomFieldsColumns}
        uniqueId='id'
        isLoading={isLoadingBoardCustomFieldsList}
        onChange={handleUpdatedTableRows}
        hasWritePermission={hasBoardSettingsPermission}
        TableRow={CustomFIeldsTableRow}
        noDataText={
          <p>
            No custom fields have been added yet. <br /> Please click on 'Add Custom Field' button
            to add custom fields.
          </p>
        }
      />
    </div>
  )
}
