import {useState, useEffect} from 'react'
import {KTSVG, useDebounce} from '../_metronic/helpers'
import {useLocation, useNavigate} from 'react-router-dom'

interface SearchProps {
  onSearch: (searchTerm: string) => void
  defaultValue?: string
  placeholder?: string
  classNames?: string
  isDisable?: boolean
  isClearInput?: boolean
}

function Search({
  onSearch,
  defaultValue,
  placeholder,
  classNames,
  isDisable,
  isClearInput,
}: SearchProps) {
  const [searchTerm, setSearchTerm] = useState<string>(defaultValue || '')
  const location = useLocation()
  const navigate = useNavigate()

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(
    () => {
      if (onSearch) {
        if (
          typeof onSearch === 'function' &&
          debouncedSearchTerm !== undefined &&
          searchTerm !== undefined
        ) {
          onSearch(debouncedSearchTerm)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]
  )

  useEffect(() => {
    // only for reset
    if (defaultValue === '' && defaultValue !== searchTerm) {
      setSearchTerm(defaultValue)
    }
    // eslint-disable-next-line
  }, [defaultValue])

  const handelSearch = (e: any) => {
    const searchValue = e.target.value
    setSearchTerm(searchValue)
    navigate(`${location.pathname}`)
  }

  const handleClear = () => {
    setSearchTerm('')
    onSearch('')
  }


  return (
    <div className='d-flex align-items-center position-relative'>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-1 position-absolute ms-6'
      />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className={`form-control ${
          classNames ? classNames : 'form-control-solid w-250px'
        } ps-14 pe-10`}
        placeholder={placeholder ? placeholder : 'search'}
        value={searchTerm}
        onChange={handelSearch}
        disabled={isDisable ? isDisable : false}
      />
      {searchTerm && !isDisable && (
        <i
          className='bi bi-x position-absolute end-0 me-4 cursor-pointer fs-2'
          onClick={handleClear}
        ></i>
      )}
    </div>
  )
}

export default Search
