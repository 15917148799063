import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import useGetCustomerIPList from '../hooks/useGetCustomerIPList'
import InfiniteScrollWrapper from '../../../components/InfiniteScrollWrapper'

const ModalCustomerIPDetails = ({show, onClose, customerID}: any) => {
  const {customerIPInformations, loadMore} = useGetCustomerIPList(customerID)

  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_trigger_build'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-lg'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Anonymous Activity (No Orders in 30 Days)</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
          <InfiniteScrollWrapper
            name='ip'
            loadMore={loadMore}
            data={customerIPInformations}
            height={300}
          >
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
              <thead className='table-row-bordered'>
                <tr className='fs-6 fw-semibold text-muted'>
                  <th className='min-w-150px'>{'SESSION DATE & TIME'}</th>
                  <th className='min-w-155px'>{'IP'}</th>
                  <th className='min-w-150px'>{'PAGES'}</th>
                  <th className='min-w-150px'>{'CITY'}</th>
                </tr>
              </thead>
              <tbody>
                {customerIPInformations?.map((row: any) => (
                  <tr key={row['sessionID']}>
                    <td>{row['dateTime']}</td>
                    <td>{row['ip']}</td>
                    <td>{row['pages']}</td>
                    <td>{row['city']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScrollWrapper>
        </div>
      </div>
    </Modal>
  )
}

export default ModalCustomerIPDetails
