import {useContext, useEffect, useState} from 'react'
import {Mention, MentionsInput} from 'react-mentions'
import classNames from '../../utils/reactMention.module.css'
import classNamesReadOnly from '../../utils/reactMentionReadOnly.module.css'
import {TicketDetailsPageContext} from '../../context'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import useGetProjectMembers from '../../hooks/useGetProjectMembers'
import {convertUTCToLocal} from '../../../../utils/date'

export const CommentsTab = () => {
  const {
    createComment,
    editComment,
    commentsList,
    isLoadingCommentsList,
    isLoadingCommentOperation,
    hasTicketWritePermission,
    deleteComment,
  } = useContext(TicketDetailsPageContext)
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()
  const [showModal, setShowModal] = useState(false)
  const [newCommentValue, setNewCommentValue] = useState('')
  const [selectedBtn, setSelectedBtn] = useState('')
  const [selectedCommentId, setSelectedCommentId] = useState(null)
  const [commentValue, setCommentValue] = useState('')

  useEffect(() => {
    if (isLoadingCommentsList) {
    } else {
      setNewCommentValue('')
      setSelectedCommentId(null)
    }
    if (!isLoadingCommentsList) {
      setShowModal(false)
    }
  }, [isLoadingCommentsList])

  const handleAction = (selectedBtn: string, selectedCommentId: any, commentValue?: any) => {
    setSelectedBtn(selectedBtn)
    setSelectedCommentId(selectedCommentId)
    commentValue && setCommentValue(commentValue)
    selectedBtn === 'delete' && setShowModal(true)
  }

  const handleAddComment = () => {
    const commentData = {comment: newCommentValue?.trim()}
    createComment(commentData)
  }

  const handleSaveComment = async () => {
    const commentData = {comment: commentValue?.trim()}
    await editComment(selectedCommentId, commentData)
  }

  const commentMentionUserList = projectMembers?.map((user: any) => ({
    id: user.username,
    display: user.name,
  }))

  return (
    <>
      {hasTicketWritePermission && (
        <MentionsInput
          placeholder='Add comment here'
          classNames={classNames}
          onChange={(e: any) => setNewCommentValue(e.target.value)}
          allowSpaceInQuery={true}
          value={newCommentValue}
        >
          <Mention
            className={classNames.mentions__mention}
            trigger='@'
            data={commentMentionUserList}
            appendSpaceOnAdd={true}
            isLoading={isLoadingProjectMembers}
            markup={`<p>__display__#__id__</p>`}
            displayTransform={(id, display) => `@${display}`}
            renderSuggestion={(entry) => {
              return (
                <div>
                  <span className='fw-bolder   text-gray-700'>{entry.display}</span>{' '}
                  <span className='text-muted '>{`(${entry.id})`}</span>
                </div>
              )
            }}
          />
        </MentionsInput>
      )}

      {newCommentValue.trim() !== '' && (
        <div className='d-flex justify-content-end gap-3'>
          <button onClick={() => setNewCommentValue('')} type='button' className='btn btn-light'>
            Cancel
          </button>
          <button onClick={handleAddComment} type='button' className='btn btn-primary'>
            {!isLoadingCommentOperation && !isLoadingCommentsList && (
              <span className='indicator-label'>Add Comment</span>
            )}
            {(isLoadingCommentOperation || isLoadingCommentsList) && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      )}

      <div className='mt-10'>
        {commentsList && commentsList.length !== 0
          ? commentsList.map((comment: any) => (
              <div className='d-flex gap-3 mb-7'>
                <div className='w-30px h-30px bg-light-primary rounded-circle border border-1 border-primary border-opacity-50 overflow-hidden d-flex justify-content-center  align-items-center'>
                  <span className='fw-bold text-primary fs-7'>{comment.created_by?.charAt(0)}</span>
                </div>
                <div className='d-flex flex-column gap-1 w-100'>
                  <div className='d-flex gap-2 align-items-center fw-bold'>
                    {comment.created_by}
                    {comment?.is_updated ? (
                      <>
                        <span
                          className='text-gray-500 fs-8'
                          title={convertUTCToLocal(comment.updated_at)}
                        >
                          {convertUTCToLocal(comment.updated_at, true, true)}
                        </span>
                        <span className='text-gray-500 fs-8'>(Edited)</span>
                      </>
                    ) : (
                      <span
                        className='text-gray-500 fs-8'
                        title={convertUTCToLocal(comment.created_at)}
                      >
                        {convertUTCToLocal(comment.created_at, true, true)}
                      </span>
                    )}
                  </div>
                  <div>
                    {selectedCommentId === comment.id && selectedBtn === 'edit' ? (
                      <MentionsInput
                        placeholder='Add comment here'
                        classNames={classNames}
                        onChange={(e: any) => setCommentValue(e.target.value)}
                        allowSpaceInQuery={true}
                        value={commentValue}
                      >
                        <Mention
                          className={classNames.mentions__mention}
                          trigger='@'
                          data={commentMentionUserList}
                          appendSpaceOnAdd={true}
                          isLoading={isLoadingProjectMembers}
                          markup={`<p>__display__#__id__</p>`}
                          displayTransform={(id, display) => `@${display}`}
                          renderSuggestion={(entry) => {
                            return (
                              <div>
                                <span className='fw-bolder   text-gray-700'>{entry.display}</span>{' '}
                                <span className='text-muted '>{`(${entry.id})`}</span>
                              </div>
                            )
                          }}
                        />
                      </MentionsInput>
                    ) : (
                      <MentionsInput
                        classNames={classNamesReadOnly}
                        value={comment.comment?.trim()}
                        disabled={true}
                      >
                        <Mention
                          className={classNamesReadOnly.mentions__mention}
                          trigger='@'
                          data={commentMentionUserList}
                          markup={`<p>__display__#__id__</p>`}
                          displayTransform={(id, display) => `@${display}`}
                        />
                      </MentionsInput>
                    )}
                  </div>
                  {hasTicketWritePermission && comment?.is_editable && (
                    <div className='d-flex gap-4'>
                      {selectedCommentId === comment.id && selectedBtn === 'edit' ? (
                        <>
                          <button
                            onClick={handleSaveComment}
                            className='btn btn-link btn-color-muted btn-active-color-primary py-2'
                          >
                            {!isLoadingCommentOperation && !isLoadingCommentsList && (
                              <span className='indicator-label'>Save</span>
                            )}
                            {(isLoadingCommentOperation || isLoadingCommentsList) && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Saving...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                          <span
                            onClick={() => handleAction('cancel', null)}
                            className='btn btn-link btn-color-muted btn-active-color-danger py-2'
                          >
                            cancel
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            onClick={() =>
                              handleAction('edit', comment?.id, comment?.comment?.trim())
                            }
                            className='btn btn-link btn-color-muted btn-active-color-primary py-2'
                          >
                            Edit
                          </span>

                          <span
                            onClick={() => handleAction('delete', comment?.id)}
                            className='btn btn-link btn-color-muted btn-active-color-danger py-2'
                          >
                            Delete
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
          : null}
      </div>

      {showModal && (
        <>
          <ConfirmationModal
            show={showModal}
            onClose={() => {
              setShowModal(false)
              setSelectedCommentId(null)
            }}
            onAction={() => deleteComment(selectedCommentId)}
            isOperationLoading={isLoadingCommentOperation || isLoadingCommentsList}
          />
        </>
      )}
    </>
  )
}
