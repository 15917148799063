import {SortableContext, useSortable} from '@dnd-kit/sortable'
import {useContext, useMemo} from 'react'
import TaskCard from './TaskCard'
import {DndBoardPageContext} from '../context'

function ColumnContainer({column, tasks}: any) {
  const {isCreateCardFromColumn, handleAddCardFromColumn, readOnly} = useContext(DndBoardPageContext)
  const tasksIds = useMemo(() => {
    return tasks?.map((task: any) => task.ticketID)
  }, [tasks])

  const {setNodeRef} = useSortable({
    id: column.columnID,
    data: {
      type: 'Column',
      column,
    },
  })

  const columnData = column

  return (
    <div
      ref={setNodeRef}
      className='column-content-container board-column-item min-w-300px w-300px'
    >
      <div className='board-card-wrapper'>
        <SortableContext items={tasksIds}>
          {tasks.map((task: any) => (
            <TaskCard key={task.ticketID} task={task} />
          ))}
        </SortableContext>
        {!readOnly && (
          <>
            {isCreateCardFromColumn ? (
              <div
                onClick={() => handleAddCardFromColumn({columnData})}
                className='px-5 py-4 create-task-link text-muted text-hover-primary cursor-pointer border d-flex align-items-center border rounded-2'
              >
                <span className='me-3'>
                <i className='fa-solid fa-plus'></i>
                </span>
                <span className='create-task-link-text'>Create Ticket</span>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ColumnContainer
