import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'

function DndDynamicTableRow({
  row,
  readOnly,
  uniqueId,
  TableRow,
  hasWritePermission,
  tableRowProps,
}: any) {
  const {setNodeRef, attributes, listeners, transform, transition, isDragging} = useSortable({
    id: row[uniqueId],
    data: {
      type: 'Row',
      row,
    },
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    outline: 0,
    cursor: !readOnly && hasWritePermission ? 'grab' : 'context-menu',
  }

  const dndSettings = {
    ref: setNodeRef,
    style: style,
    ...attributes,
    ...listeners,
  }

  if (isDragging) {
    return (
      <TableRow
        row={row}
        hasWritePermission={hasWritePermission}
        dndImage={<img src='/media/svg/general/Group556.svg' className='me-3' alt='img' />}
        dndSettings={{
          ...dndSettings,
          style: {
            ...style,
            opacity: 0.2,
            boxShadow: 'inset 0 0 0 2px #0d6efd',
            borderRadius: '5px',
          },
        }}
        {...tableRowProps}
      />
    )
  }

  return (
    <TableRow
      dndSettings={dndSettings}
      row={row}
      hasWritePermission={hasWritePermission}
      dndImage={
        hasWritePermission ? (
          <img src='/media/svg/general/Group556.svg' className='me-3' alt='img' />
        ) : null
      }
      {...tableRowProps}
    />
  )
}

export default DndDynamicTableRow
