import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

const useGetProjectKanbanStages = () => {
  const Api = useApi()
  const {projectId} = useParams()
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/columns`)

  return {
    projectKanbanStages: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetProjectKanbanStages
