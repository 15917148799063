import apiV2 from '../services/ApiV2'
import { useAppSelector } from '../redux/useTypedSelector'

function usePermission() {

  const {permissions} = useAppSelector((state) => state?.auth)

  const hasPermission = (path: string, permission: string) => {
    let pathArray = path.split('_')
    let arrayLength = pathArray?.length
    let tempData: any = permissions
    for (let i = 0; i < arrayLength; i++) {
      if (i === arrayLength - 1) {
        // Return the permission value at the final level
        return tempData?.[pathArray[i]]?.operations?.[permission] ?? null
      } else {
        tempData = tempData?.[pathArray[i]]?.children
        if (!tempData) {
          // Return false if the path does not exist
          return false
        }
      }
    }
    return false
  }

  return {
    menu: permissions || {},
    hasPermission: hasPermission
  }
}

 // eslint-disable-next-line 
const getStorePermissions = (url: string) => {
  return apiV2.get(`${url}`, {}, {}).then((d: any) => {
    return d.data
  })
}

export default usePermission