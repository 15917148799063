import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

const useGetCommentsList = () => {
  const Api = useApi()
  const {cardId} = useParams()

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/cards/${cardId}/comments`,
    {
      queryId: 'ticket-comments-list',
      isCaching: false,
    },
    {
      refetchInterval: 0,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  return {
    commentsList: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetCommentsList
