import {Link, useLocation} from 'react-router-dom'

function ProjectsPageNavigation() {
  const location = useLocation()

  return (
    <div className=''>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/projects/active' && 'active')
            }
            to='/projects/active'
          >
            Active
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/projects/archived' && 'active')
            }
            to='/projects/archived'
          >
            Archived
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default ProjectsPageNavigation
