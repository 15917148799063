import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {SectionForm} from '../../../../components/SectionForm'
import {AgileBoardModalTypes} from '../../interfaces'
import {InputText} from '../../../../components/InputText'
import TextEditor from '../../../../components/lexicalTextEdiror/TextEditor'
import {useContext, useState} from 'react'
import {AgileBoardPageContext} from '../../context'
import {createTicketValidationSchema} from '../../validations'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {createTicketDefaultValues} from '../../utils'

function CreateTicketModal({
  isCreateFromBoard = false,
  show,
  onClose,
  columnId = null,
  moduleId = null,
}: AgileBoardModalTypes) {
  const {projectMembers, isLoadingProjectMembers} = useContext(AgileBoardPageContext)
  const {projectKanbanStages, isLoadingProjectKanbanStages} = useContext(AgileBoardPageContext)
  const {projectModules, isLoadingProjectModules} = useContext(AgileBoardPageContext)
  const {projectStatuses, isLoadingProjectStatuses} = useContext(AgileBoardPageContext)
  const {projectPriorities, isLoadingProjectPriorities} = useContext(AgileBoardPageContext)
  const {createTicket, isLoadingTicketOperation, isLoadingAgileBoardData} =
    useContext(AgileBoardPageContext)
  const [isEditorValid, setIsEditorValid] = useState<boolean>(true)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid, isDirty},
    setError,
  } = useForm<any>({
    defaultValues: createTicketDefaultValues(
      projectKanbanStages,
      projectModules,
      projectStatuses,
      projectPriorities,
      isCreateFromBoard,
      columnId,
      moduleId
    ),
    resolver: yupResolver(createTicketValidationSchema),
    mode: 'onChange',
  })

  const onCreate = (data: any) => {
    if (!isEditorValid) return
    const {moduleId, ...payload} = data
    createTicket(moduleId, payload, setError)
  }

  return (
    <ConfirmationModal
      show={show}
      title='Create Ticket'
      onClose={onClose}
      body={
        <>
          <InputText
            id='ticket-title'
            label='Ticket Name'
            className='mb-10'
            isRequired={true}
            register={register('title')}
            placeholder='Enter Ticket Name'
            error={errors?.title}
            maxLength={255}
          />
          <div className='row gx-5'>
            <div className='col modal-ticket-detail-maincontent w-100'>
              <TextEditor
                id='description'
                control={control}
                registerKey='description'
                inputClass='min-h-350px'
                setIsValid={setIsEditorValid}
                maxLength={62500}
              />
            </div>
            <div className='col modal-ticket-detail-sidebar w-300px mw-300px'>
              <div className='border border rounded-3 p-6 pt-6 pb-3 position-relative position-relative bg-light bg-opacity'>
                <SectionForm
                  id='create-ticket-status'
                  className='mb-10'
                  commonClassNames='mb-6'
                  commonLabelClass='w-100'
                  commonInputClass='col'
                  register={register}
                  control={control}
                  inputs={[
                    {
                      id: 'assignee',
                      label: 'Assignee',
                      isReachSelect: true,
                      placeholder: 'Unassigned',
                      isNullable: true,
                      options: projectMembers,
                      isLoading: isLoadingProjectMembers,
                      labelKey: 'name',
                      valueKey: 'username',
                      registerKey: 'assignedTo',
                    },
                    {
                      id: 'kanban-stage',
                      label: 'Kanban Stage',
                      isReachSelect: true,
                      options: projectKanbanStages,
                      isLoading: isLoadingProjectKanbanStages,
                      registerOptions: {valueAsNumber: true},
                      valueType: 'number',
                      labelKey: 'name',
                      valueKey: 'column_id',
                      registerKey: 'currentColumnId',
                    },
                    {
                      id: 'module',
                      label: 'Module',
                      isReachSelect: true,
                      options: projectModules,
                      isLoading: isLoadingProjectModules,
                      labelKey: 'name',
                      valueKey: 'module_id',
                      valueType: 'number',
                      registerKey: 'moduleId',
                    },
                    {
                      id: 'status',
                      label: 'Status',
                      isSelect: true,
                      options: projectStatuses,
                      isLoading: isLoadingProjectStatuses,
                      registerOptions: {valueAsNumber: true},
                      labelKey: 'label',
                      valueKey: 'id',
                      registerKey: 'status',
                    },
                    {
                      id: 'priority',
                      label: 'Priority',
                      isSelect: true,
                      options: projectPriorities,
                      isLoading: isLoadingProjectPriorities,
                      registerOptions: {valueAsNumber: true},
                      labelKey: 'label',
                      valueKey: 'id',
                      registerKey: 'priority',
                    },
                    {
                      id: 'estimation-time',
                      label: 'Estimation',
                      isInputTime: true,
                      registerKey: 'estimation',
                    },
                    {
                      id: 'due-date',
                      label: 'Due Date',
                      isInputDate: true,
                      isNullable: true,
                      registerKey: 'dueDate',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      }
      dialogClassName='modal-xl'
      onAction={handleSubmit(onCreate)}
      actionName='Create'
      actionBtnClass='btn-primary'
      isDisabled={
        isLoadingProjectKanbanStages ||
        isLoadingProjectModules ||
        !isValid ||
        !isDirty ||
        !isEditorValid
      }
      isOperationLoading={isLoadingTicketOperation}
      isDataLoading={isLoadingAgileBoardData}
    />
  )
}

export default CreateTicketModal
