import React from 'react'
import ProjectTableActionsOverlay from '../sections/modals/overlays/ProjectTableActionsOverlay'

export const activeProjectsTableColumns = [
  {key: 'name', label: 'NAME', isSorted: true, headerStyle: 'min-w-400'},
  {
    key: 'business_unit',
    label: 'BUSINESS UNIT',
    isSorted: true,
    headerStyle: 'w-150px',
  },
  {
    key: 'members',
    label: 'Members',
    isSorted: true,
    style: 'justify-content-center',
    headerStyle: 'min-w-80px',
  },
  {key: 'owner_username', label: 'OWNER', isSorted: true, headerStyle: 'w-150px'},
  {key: 'created_at', label: 'CREATED DATE', isSorted: true, headerStyle: 'w-200px'},
  {
    key: 'action',
    label: 'ACTION',
    render: (row: any) => React.createElement(ProjectTableActionsOverlay, {projectDetails: row}),
  },
]

export const archivedProjectsTableColumns = [
  {key: 'name', label: 'NAME', isSorted: true, headerStyle: 'min-w-400'},
  {
    key: 'business_unit',
    label: 'BUSINESS UNIT',
    isSorted: true,
    headerStyle: 'w-150px',
  },
  {
    key: 'members',
    label: 'Members',
    isSorted: true,
    style: 'justify-content-center',
    headerStyle: 'min-w-80px',
  },
  {key: 'owner_username', label: 'OWNER', isSorted: true, headerStyle: 'w-150px'},
  {key: 'created_at', label: 'CREATED DATE', isSorted: true, headerStyle: 'w-200px'},
  {
    key: 'action',
    label: 'ACTION',
    render: (row: any) => React.createElement(ProjectTableActionsOverlay, {projectDetails: row}),
  },
]

export const projectMembersListingModalColumns = [
  {key: 'name', label: 'Name'},
  {key: 'role', label: 'Role', style: 'justify-content-end'},
]

