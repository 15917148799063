import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import GlobalCustomFieldsModal from '../modals/GlobalCustomFieldsModal'
import GlobelCustomFieldActionsDropdown from '../actionsDropdowns/GlobelCustomFieldActionsDropdown'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectsCustomFieldsPageContext} from '../../context'
import {GlobalCustomFieldActionsOverlayTypes} from '../../interfaces'

const GlobalCustomFieldActionsOverlay = ({
  customFieldData,
}: GlobalCustomFieldActionsOverlayTypes) => {
  const {
    deleteGlobalCustomField,
    isLoadingGlobalCustomFieldOperation,
    isLoadingGlobalCustomFieldList,
  } = useContext(ProjectsCustomFieldsPageContext)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <GlobelCustomFieldActionsDropdown
            customFieldData={customFieldData}
            setShowModal={setShowModal}
            setShowDeleteModal={setShowDeleteModal}
          />
        }
      />

      {showModal && (
        <GlobalCustomFieldsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          isEdit={true}
          customFieldData={customFieldData}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onAction={() => deleteGlobalCustomField(customFieldData?.custom_field_id)}
          isDataLoading={isLoadingGlobalCustomFieldList}
          isOperationLoading={isLoadingGlobalCustomFieldOperation}
          body={
            <p>
              Do you really want to remove '<strong>{customFieldData?.custom_field_name}</strong>'
              from global project custom fields?
            </p>
          }
        />
      )}
    </>
  )
}

export default GlobalCustomFieldActionsOverlay
