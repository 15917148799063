function AnonymousCustomerTableRow({row, setOpenModal, setCustomerID}: any) {
  return (
    <tr>
      <td>
        <div>{row?.['customerName']}</div>
      </td>
      <td>
        <div>{row?.['customerEmail']}</div>
      </td>
      <td>
        <div>{row?.['customerRepresentative']}</div>
      </td>
      <td>
        <div>{row?.['customerGroupName']}</div>
      </td>
      <td>
        <div>{row?.['totalOrders']}</div>
      </td>
      <td>
        <div>{row?.['lastOrderDate']}</div>
      </td>
      <td
        className=''
        onClick={() => {
          setCustomerID(row?.['customerID'])
          setOpenModal(true)
        }}
      >
        <div className="text-center">{row?.['totalSessions']}</div>
      </td>
      <td>
        <div className="text-center">{row?.['totalUniqueIPs']}</div>
      </td>
    </tr>
  )
}

export default AnonymousCustomerTableRow
