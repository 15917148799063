import {useQuery} from 'react-query'
import {useEffect} from 'react'
import {getAggregatedProductReport} from '../services'
import useFilters from '../../../hook/useFilters'
import moment from 'moment'

export const useGetAggregatedProductReport = (limit: any) => {
  const dateRange = JSON.parse(localStorage.getItem('dateRange') || '{}')

  const initialFilters = {
    start_date:
      limit === 20 ? dateRange?.startDate : moment().startOf('month').format('YYYY-MM-DD'),
    end_date: limit === 20 ? dateRange?.endDate : moment().format('YYYY-MM-DD'),
    page: 1,
    limit: limit,
    sort_by: 'quantity/-1',
  }
  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const onDateChange = (startDate: any, endDate: any) => {
    setMultipleFilters({
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    })
  }

  const {data, isFetching, refetch} = useQuery(
    ['get-AggregatedProductReport', filters],
    () =>
      getAggregatedProductReport(
        '/analytics/sold-products/state-wise-stats/aggregated-product-report',
        filters
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSortingChange = (key: string, value: string) => {
    const direction = value === 'asc' ? 1 : -1
    const sortFlag = key + '/' + direction
    setSingleFilter('sort_by', sortFlag)
  }

  return {
    AggregatedProductReportData: data?.data || [],
    AggregatedProductReportMeta: data?.meta?.month_rows[0] || {},
    isAggregatedProductReportDataLoading: isFetching,
    onDateChangeAggregatedProductReport: onDateChange,
    filters,
    onPageChange,
    onSortingChange,
    pagination: data?.['meta']?.['pagination'] || {},
  }
}
