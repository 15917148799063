import {Controller} from 'react-hook-form'
import {convertSelectedValueType, dataToSelectOptions} from '../../utils/common'
import {ReachSelectTypes} from './ReachSelectTypes'
import Select from 'react-select'
import './reachSelect.scss'

function ReachSelect({
  id,
  name,
  value,
  control,
  label,
  className = '',
  selectClass = '',
  onChange,
  disabled = false,
  isRequired,
  options,
  formLabel,
  error,
  touched,
  placeholder,
  isNullable,
  defaultValue = '',
  selectRef,
  registerKey,
  onBlur,
  placeholderValue = '',
  isReadOnly = false,
  labelClass = '',
  labelKey = 'label',
  valueKey = 'value',
  valueType = 'string',
  isLoading = false,
  registerOptions,
  disabledKey,
  ...rest
}: ReachSelectTypes) {
  const formattedOptions =
    options && options?.length > 0
      ? labelKey && valueKey
        ? dataToSelectOptions(options, labelKey, valueKey, valueType, disabledKey)
        : dataToSelectOptions(options, 'label', 'value', valueType, disabledKey)
      : []

  const handleChange = (selectedOption: any, registerOnChange?: any) => {
    const selectedValue = selectedOption
      ? convertSelectedValueType(options, selectedOption.value)
      : placeholderValue
    if (onChange)
      onChange(
        selectedOption ? selectedOption : {label: placeholderValue, value: placeholderValue},
        selectedValue
      )
    if (registerOnChange && registerKey) registerOnChange(selectedValue)
  }

  return (
    <div className={'position-relative ' + className}>
      {label && (
        <label
          className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}
          form-label={formLabel}
        >
          {label}
        </label>
      )}

      {control && registerKey ? (
        <Controller
          control={control}
          name={registerKey}
          defaultValue={defaultValue}
          rules={registerOptions}
          render={({field: {value: registerValue, onChange: registerOnChange}}) => {
            const defaultRegisteredValue = formattedOptions.find(
              (option: any) => option.value === registerValue
            )
            return (
              <Select
                options={formattedOptions}
                placeholder={placeholder}
                isLoading={isLoading}
                className={`${selectClass} ${
                  error ? 'is-invalid border border-1 border-danger' : ''
                }`}
                id={id}
                name={name}
                ref={selectRef}
                onChange={(selectedOption: any) => {
                  handleChange(selectedOption, registerOnChange)
                }}
                onBlur={onBlur}
                {...(value ? {value: value} : {value: defaultRegisteredValue ?? placeholderValue})}
                defaultValue={defaultRegisteredValue}
                isDisabled={disabled || isReadOnly}
                isClearable={isNullable}
                classNamePrefix='react-select'
                {...rest}
              />
            )
          }}
        />
      ) : (
        <Select
          options={formattedOptions}
          placeholder={placeholder}
          isLoading={isLoading}
          className={`${selectClass} ${error ? 'is-invalid border border-1 border-danger' : ''}`}
          id={id}
          name={name}
          ref={selectRef}
          onChange={handleChange}
          onBlur={onBlur}
          {...(value ? {value: value} : {})}
          defaultValue={defaultValue}
          isDisabled={disabled || isReadOnly}
          isClearable={isNullable}
          classNamePrefix='react-select'
          {...rest}
        />
      )}
      {error && error?.message && (
        <div className='form-error invalid-feedback'>{error.message}</div>
      )}
    </div>
  )
}

export default ReachSelect
