import {useContext, useState, useRef, useEffect} from 'react'
import {TicketDetailsPageContext} from '../../context'
import {TextArea} from '../../../../components/TextArea'
import HtmlPlaceholder from '../../components/HtmlPlaceholder'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import useDynamicDate from '../../../../hook/useDynamicDate'
import {convertUTCToLocal} from '../../../../utils/date'
import {ticketTitleTextareaHeight} from '../../utils'

const TicketDetailsPageHeader = ({register, onSave, ticketTitle, errors}: any) => {
  const {
    ticketDetails,
    isTicketEditable,
    setIsTicketEditable,
    deleteTicket,
    isLoadingTicketOperation,
    isLoadingTicketDetails,
    projectName,
    hasTicketWritePermission,
  } = useContext(TicketDetailsPageContext)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [showModal, setShowModal] = useState(false)
  const {dynamicTimestamp: dynamicCreatedAt} = useDynamicDate(ticketDetails?.created_at)
  const {dynamicTimestamp: dynamicUpdatedAt} = useDynamicDate(ticketDetails?.updated_at)
  const hasTicketCreatorOrUpdater = ticketDetails?.ticket_creator || ticketDetails?.ticket_updater

  useEffect(() => {
    if (textareaRef.current) {
      ticketTitleTextareaHeight(textareaRef)
      if (isTicketEditable) {
        textareaRef.current.focus()
      }
    }
  }, [isTicketEditable, ticketTitle])

  const onEnterInTitle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      onSave()
      e.preventDefault()
    }
  }

  const onDeleteTicket = () => {
    deleteTicket()
  }

  return (
    <div className={`${isTicketEditable ? 'mb-4' : ''}`}>
      <div className=''>
        {isTicketEditable ? (
          <TextArea
            id='edit-ticket-title'
            register={register('title')}
            onKeyDown={onEnterInTitle}
            className='w-100'
            rows={1}
            value={ticketTitle}
            inputClass='px-0 pt-2 pb-0 text-dark border-bottom border-top-0 border-start-0 border-end-0 resize-none rounded-0 overflow-hidden fs-2 fw-bolder lh-base p-0 m-0'
            error={errors?.title}
            textAreaRef={textareaRef}
            maxLength={255}
          />
        ) : (
          <h1
            className={`mb-0 fs-2 fw-bolder lh-base ${hasTicketWritePermission ? 'cursor-pointer' : ''}`}
            onClick={() => hasTicketWritePermission && setIsTicketEditable(true)}
          >
            <HtmlPlaceholder className='w-200px'>{ticketTitle}</HtmlPlaceholder>
          </h1>
        )}

        {hasTicketWritePermission && (
          <div className='fw-bold mt-3'>
            <span
              className='text-primary cursor-pointer text-decoration-underline'
              onClick={() => setIsTicketEditable(true)}
            >
              Edit
              <i className='ms-1 bi bi-pencil text-primary'></i>
            </span>

            <span
              className='text-danger cursor-pointer text-decoration-underline ms-4'
              onClick={() => setShowModal(true)}
            >
              Delete
              <i className='ms-1 bi bi-trash text-danger'></i>
            </span>

            {showModal && (
              <ConfirmationModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onAction={onDeleteTicket}
                isDataLoading={isLoadingTicketDetails}
                isOperationLoading={isLoadingTicketOperation}
                body={
                  <p>
                    Do you really want to remove '<strong>{ticketTitle}</strong>' ticket from '
                    <strong>{projectName}</strong>' project?
                  </p>
                }
              />
            )}
          </div>
        )}
      </div>

      {ticketDetails?.length !== 0 && !isTicketEditable && hasTicketCreatorOrUpdater && (
        <div className='mt-4 fs-7 fw-semibold'>
          <span className='text-muted'>
            Created by{' '}
            <span className='text-dark cursor-pointer fw-bolder' title={ticketDetails?.created_by}>
              {ticketDetails?.ticket_creator}
            </span>{' '}
            <span title={convertUTCToLocal(ticketDetails?.created_at)}>{dynamicCreatedAt}</span>
          </span>
          {ticketDetails?.ticket_updater && (
            <span className='text-muted border-start ps-2 ms-2'>
              Updated by{' '}
              <span
                className='text-dark cursor-pointer fw-bolder'
                title={ticketDetails?.updated_by}
              >
                {ticketDetails?.ticket_updater}
              </span>{' '}
              <span title={convertUTCToLocal(ticketDetails?.updated_at)}>{dynamicUpdatedAt}</span>
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default TicketDetailsPageHeader
