// NOTE: Don't remove any options from any array for hide tools, use the visible property instead
// NOTE: Don't remove any options from any array for hide tools, use the visible property instead
// NOTE: Don't remove any options from any array for hide tools, use the visible property instead
// NOTE: Don't remove any options from any array for hide tools, use the visible property instead

export const FEATURES_SETTINGS = {
  hasLinkAttributes: true,
  isAutocomplete: true,
  isCharLimit: true,
  isCharLimitUtf8: false,
  isMaxLength: false,
  isRichText: true,
  isDraggableBlock: false,
  isFooterActions: false,
  shouldPreserveNewLinesInMarkdown: true,
  shouldUseLexicalContextMenu: false,
  showTableOfContents: false,
  tableCellBackgroundColor: true,
  tableCellMerge: true,
}

export const TOOLBAR_SETTINGS = [
  {key: 'undo', visible: true},
  {key: 'redo', visible: true},
  {key: 'divider'},
  {
    key: 'blockFormattingDropdown',
    visible: true,
    children: [
      {key: 'normal', label: 'Normal', visible: true},
      {key: 'h1', label: 'Heading 1', visible: true},
      {key: 'h2', label: 'Heading 2', visible: true},
      {key: 'h3', label: 'Heading 3', visible: true},
      {key: 'h4', label: 'Heading 4', visible: true},
      {key: 'h5', label: 'Heading 5', visible: true},
      {key: 'h6', label: 'Heading 6', visible: true},
      {key: 'bullet', label: 'Bullet', visible: true},
      {key: 'number', label: 'Number', visible: true},
      {key: 'check', label: 'Check', visible: true},
      {key: 'quote', label: 'Quote', visible: true},
      {key: 'insertCode', label: 'Code', visible: true},
    ],
  },
  {key: 'divider'},
  {key: 'fontFamilyDropdown', visible: true},
  {key: 'codeLanguageDropdown', visible: true},
  {key: 'divider'},
  {key: 'fontSizeDropdown', visible: true},
  {key: 'divider'},
  {key: 'bold', visible: true},
  {key: 'italic', visible: true},
  {key: 'underline', visible: true},
  {key: 'insertCode', visible: true},
  {key: 'link', visible: true},
  {key: 'fontColorPicker', visible: true},
  {key: 'bgColorPicker', visible: true},
  {
    key: 'textFormattingDropdown',
    visible: true,
    children: [
      {key: 'strikethrough', label: 'Strikethrough', visible: true},
      {key: 'subscript', label: 'Subscript', visible: true},
      {key: 'superscript', label: 'Superscript', visible: true},
      {key: 'code', label: 'Code', visible: true},
    ],
  },
  {key: 'divider'},
  {
    key: 'insertElementsDropdown',
    visible: true,
    children: [
      {key: 'link', label: 'Link', visible: true},
      {key: 'image', label: 'Image', visible: true},
      {key: 'table', label: 'Table', visible: true},
      {key: 'poll', label: 'Poll', visible: true},
      {key: 'equation', label: 'Equation', visible: true},
      {key: 'sticky', label: 'Sticky', visible: true},
      {key: 'collapsible', label: 'Collapsible', visible: true},
      {key: 'columns', label: 'Columns', visible: true},
    ],
  },
  {key: 'divider'},
  {
    key: 'alignmentDropdown',
    visible: true,
    children: [
      {key: 'left', label: 'Left', visible: true},
      {key: 'center', label: 'Center', visible: true},
      {key: 'right', label: 'Right', visible: true},
      {key: 'justify', label: 'Justify', visible: true},
      {key: 'startAlign', label: 'Start Align', visible: true},
      {key: 'endAlign', label: 'End Align', visible: true},
      {key: 'outdent', label: 'Outdent', visible: true},
      {key: 'indent', label: 'Indent', visible: true},
    ],
  },
  {key: 'divider'},
  {key: 'markdownToggle', visible: false},
  // {key: 'divider'},
  {key: 'htmlToggle', visible: false},
]

export const CODE_BLOCK_MODE_TOOLS = [
  {key: 'undo', visible: true},
  {key: 'redo', visible: true},
  {key: 'divider'},
  {
    key: 'blockFormattingDropdown',
    visible: true,
    children: [
      {key: 'normal', label: 'Normal', visible: true},
      {key: 'h1', label: 'Heading 1', visible: true},
      {key: 'h2', label: 'Heading 2', visible: true},
      {key: 'h3', label: 'Heading 3', visible: true},
      {key: 'h4', label: 'Heading 4', visible: true},
      {key: 'h5', label: 'Heading 5', visible: true},
      {key: 'h6', label: 'Heading 6', visible: true},
      {key: 'bullet', label: 'Bullet', visible: true},
      {key: 'number', label: 'Number', visible: true},
      {key: 'check', label: 'Check', visible: true},
      {key: 'quote', label: 'Quote', visible: true},
      {key: 'insertCode', label: 'Code', visible: true},
    ],
  },
  {key: 'divider'},
  {key: 'codeLanguageDropDown', visible: true},
  {key: 'divider'},
  {key: 'markdownToggle', visible: true},
  {key: 'htmlToggle', visible: true},
]

export const CODE_LANGUAGES = [
  {key: 'plain', label: 'Plain Text', visible: true},
  {key: 'html', label: 'HTML', visible: true},
  {key: 'css', label: 'CSS', visible: true},
  {key: 'js', label: 'JavaScript', visible: true},
  {key: 'markdown', label: 'Markdown', visible: true},
  {key: 'clike', label: 'C-like', visible: false},
  {key: 'cpp', label: 'C++', visible: false},
  {key: 'py', label: 'Python', visible: false},
  {key: 'rust', label: 'Rust', visible: false},
  {key: 'sql', label: 'SQL', visible: false},
  {key: 'swift', label: 'Swift', visible: false},
  {key: 'c', label: 'C', visible: false},
  {key: 'java', label: 'Java', visible: false},
  {key: 'objc', label: 'Objective-C', visible: false},
  {key: 'powershell', label: 'PowerShell', visible: false},
  {key: 'php', label: 'PHP', visible: false},
  {key: 'ruby', label: 'Ruby', visible: false},
  {key: 'typescript', label: 'TypeScript', visible: false},
  {key: 'xml', label: 'XML', visible: false},
]