import useApi from '../../../../services/useApi'
import {useState} from 'react'
import {useParams} from 'react-router-dom'

function useCustomFieldsOperations() {
  const Api = useApi()
  const {projectId} = useParams()
  const [isLoading, setLoading] = useState(false)
  const [customFieldOperationId, setCustomFieldOperationId] = useState<any>(null)

  async function createCustomField(payload: any, setError: Function) {
    return Api.post(`/agile/projects/${projectId}/custom-fields`, payload, {
      successMessage: 'Custom field created successfully.',
      setLoading: setLoading,
      refetch: 'board-custom-fields',
      setUseFormError: setError,
    })
  }

  async function updateCustomField(
    cFieldId: string,
    payload: any,
    dirtyFields?: any,
    setError?: Function
  ) {
    return Api.patch(`/agile/projects/${projectId}/custom-fields/${cFieldId}`, payload, {
      dirtyFields: dirtyFields,
      successMessage: 'Custom field updated successfully.',
      setLoading: setLoading,
      refetch: 'board-custom-fields',
      setUseFormError: setError,
      onLoadingStart: () => setCustomFieldOperationId(cFieldId),
      onLoadingEnd: () => setCustomFieldOperationId(null),
    })
  }

  async function updateCustomFieldTableSorting(sortOrder: any) {
    return Api.patch(`/agile/projects/${projectId}/custom-fields`, sortOrder)
  }

  async function deleteCustomField(cFieldId: string) {
    return Api.delete(
      `/agile/projects/${projectId}/custom-fields/${cFieldId}`,
      {},
      {
        successMessage: 'Custom field deleted successfully.',
        setLoading: setLoading,
        refetch: 'board-custom-fields',
      }
    )
  }

  return {
    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    customFieldOperationId,
    isLoading: isLoading,
  }
}

export default useCustomFieldsOperations
