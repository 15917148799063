import {Route, Routes, Outlet, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'
import TicketDetails from './layouts/TicketDetails'
import {toTitleCase} from '../../utils/string'

function ProjectTicketDetailsPage() {
  useMeta('Projects')
  const {projectStatus, projectName, projectId, cardIdentifier} = useParams<any>()

  const ProjectTicketDetailsPageBreadCrumbs: Array<PageLink> = [
    {
      title: 'Projects',
      path: `/projects/`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `${toTitleCase(projectName)}`,
      path: `/projects/${projectStatus}/${projectName}/${projectId}`,
      isSeparator: false,
      isActive: true,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path={''}
          element={
            <>
              <PageTitle breadcrumbs={ProjectTicketDetailsPageBreadCrumbs}>
                #{cardIdentifier?.toUpperCase()}
              </PageTitle>
              <TicketDetails />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProjectTicketDetailsPage
