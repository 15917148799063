import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'

export const useGetAgileBoardStructure = () => {
  const Api = useApi()
  const {projectId} = useParams()

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/structure`, {
    queryId: 'agile-board-structure',
  })

  return {
    agileBoardStructure: response?.data || [],
    isLoading: isFetching,
  }
}
