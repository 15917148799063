function AnonymousCustomerTableRow({row}: any){
  return (
    <tr>
      <td>
        <div>{row?.['customerName']}</div>
      </td>
      <td>
        <div>{row?.['customerEmail']}</div>
      </td>
      <td>
        <div>{row?.['customerRepresentative']}</div>
      </td>
      <td>
        <div>{row?.['customerGroupName']}</div>
      </td>
      <td>
        <div>{row?.['dateCreated']}</div>
      </td>
    </tr>
  )
}

export default AnonymousCustomerTableRow
