import {useContext, useState} from 'react'
import {TicketLayout} from './TicketLayout'
import {AgileBoardPageContext} from '../context'
import DndBoard from '../../../components/agileBoard/DndBoard'
import CreateTicketModal from '../sections/modals/CreateTicketModal'

export default function TicketsBoard() {
  const [showModal, setShowModal] = useState(false)
  const [columnId, setColumnId] = useState(null)
  const [moduleId, setModuleId] = useState(null)
  const {
    agileBoardStructure,
    agileBoardData,
    isLoadingBoardStructure,
    isLoadingAgileBoardData,
    updateAgileBoard,
    hasBoardPermission,
  } = useContext(AgileBoardPageContext)

  const handleAddCardFromColumn = ({columnData}: any) => {
    setColumnId(columnData.cID)
    setModuleId(columnData.moduleID)
    setShowModal(true)
  }

  const handleUpdateAgileBoard = (data: any) => {
    const {newModuleID, newCID, activeTaskID, newColumnSorting} = data
    const updatedPayload = {
      module_id: newModuleID,
      current_column_id: newCID,
      ticket_ids: newColumnSorting,
    }
    updateAgileBoard(updatedPayload, activeTaskID)
  }

  const dndBoardData = {
    boardStructure: agileBoardStructure,
    boardData: agileBoardData,
    isLoadingBoardStructure: isLoadingBoardStructure,
    isLoadingBoardData: isLoadingAgileBoardData,
  }

  return (
    <>
      <DndBoard
        data={dndBoardData}
        handleTicketLayout={TicketLayout}
        isCreateCardFromColumn={true}
        handleAddCardFromColumn={handleAddCardFromColumn}
        hasPermission={hasBoardPermission}
        readOnly={!hasBoardPermission}
        onChange={handleUpdateAgileBoard}
      />
      {showModal && (
        <CreateTicketModal
          isCreateFromBoard={true}
          show={showModal}
          onClose={() => setShowModal(false)}
          columnId={columnId}
          moduleId={moduleId}
        />
      )}
    </>
  )
}
