import {useState} from 'react'
import {getBadgeColor} from '../../../../utils/badge'
import {formatDate} from '../../../../utils/date'
import {GlobalProjectMembersTableRowTypes} from '../../interfaces'
import MemberProjectsListModal from '../modals/MemberProjectsListModal'

const GlobalProjectMembersTableRow = ({
  row,
  actionComponent,
}: GlobalProjectMembersTableRowTypes) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <tr>
      <td>{row['name']}</td>
      <td>
        <div
          className='text-center cursor-pointer text-decoration-underline text-hover-primary'
          onClick={() => setShowModal(true)}
        >
          {row['usage_count']}
        </div>
      </td>
      <td>{formatDate(row['created_at'], false)}</td>
      <td>
        <div className='d-flex justify-content-center'>
          <span className={`badge ${getBadgeColor(row?.status, 'light')} badge-lg`}>
            {row?.status}
          </span>
        </div>
      </td>
      {actionComponent && <td>{actionComponent(row)}</td>}
      {showModal && (
        <MemberProjectsListModal
          show={showModal}
          onClose={() => setShowModal(false)}
          memberId={row?.username}
        />
      )}
    </tr>
  )
}

export default GlobalProjectMembersTableRow
