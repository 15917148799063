import {useState} from 'react'
import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

function useCommentOperations() {
  const Api = useApi()
  const {cardId} = useParams()
  const [isLoading, setLoading] = useState(false)

  async function createComment(payload: any) {
    return Api.post(`/agile/projects/cards/${cardId}/comments`, payload, {
      setLoading: setLoading,
      successMessage: 'Comment added successfully',
      refetch: 'ticket-comments-list',
    })
  }

  async function editComment(commentId: any, payload: any) {
    return Api.put(`/agile/projects/cards/${cardId}/comments/${commentId}`, payload, {
      setLoading: setLoading,
      successMessage: 'Comment updated successfully',
      refetch: 'ticket-comments-list',
    })
  }

  async function deleteComment(commentId: any) {
    return Api.delete(
      `/agile/projects/cards/${cardId}/comments/${commentId}`,
      {},
      {
        setLoading: setLoading,
        successMessage: 'Comment deleted successfully',
        refetch: 'ticket-comments-list',
      }
    )
  }
  return {
    createComment,
    editComment,
    deleteComment,
    isLoading,
  }
}

export default useCommentOperations
