import {useContext, useState} from 'react'
import OverlayComponent from '../../../../../_metronic/layout/components/Popover'
import ProjectsDetailsModal from '../ProjectsDetailsModal'
import ProjectArchiveConfirmationModal from '../ProjectArchiveConfirmationModal'
import {ConfirmationModal} from '../../../../../components/ConfirmationModal'
import {ProjectsListPageContext} from '../../../context'
import ProjectsTableActionDropdown from '../actionDropdowns/ProjectsTableActionDropdown'

const ProjectTableActionsOverlay = ({projectDetails}: any) => {
  const {deleteProject, isOperationLoading, isLoading} = useContext(ProjectsListPageContext)
  const [showModal, setShowModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <ProjectsTableActionDropdown
            setShowModal={setShowModal}
            setShowArchiveModal={setShowArchiveModal}
            setDeleteModal={setDeleteModal}
            projectDetails={projectDetails}
          />
        }
      />

      {showModal && (
        <ProjectsDetailsModal
          modalTitle={'Edit Project Details'}
          show={showModal}
          onClose={() => setShowModal(false)}
          projectDetails={projectDetails}
          isEdit={true}
        />
      )}

      {showArchiveModal && (
        <ProjectArchiveConfirmationModal
          show={showArchiveModal}
          onClose={() => {
            setShowArchiveModal(false)
          }}
          projectDetails={projectDetails}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setDeleteModal(false)}
          onAction={() => deleteProject(projectDetails?.id)}
          isOperationLoading={isOperationLoading}
          isDataLoading={isLoading}
        />
      )}
    </>
  )
}

export default ProjectTableActionsOverlay
