import {DragEndEvent} from '@dnd-kit/core'

export function handleDragEnd(
  event: DragEndEvent,
  onChange: ((tableData: any) => void) | undefined,
  tableData: any,
  setActiveRow: (activeRow: any) => void,
  initialIndex: number | null,
  setInitialIndex: Function,
  uniqueId: string
) {
  setActiveRow(null)

  const {active, over} = event
  if (!over) return

  const activeId = active.id
  const overId = over.id
  const newIndex = tableData.findIndex((row: any) => row[uniqueId] === overId)

  if (initialIndex === newIndex) return
  onChange?.(tableData)
  setInitialIndex(null)

  if (activeId === overId) return

  const isActiveAColumn = active.data.current?.type === 'Column'

  if (!isActiveAColumn) return
}
