import {useRef} from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {useContext} from 'react'
import {DndBoardPageContext} from '../context'

function TaskCard({task}: any) {
  const ticketData = task
  const {handleTicketLayout} = useContext(DndBoardPageContext)
  const cardRef: any = useRef<HTMLDivElement>(null)
  const {setNodeRef, attributes, listeners, transform, transition, isDragging} = useSortable({
    id: task.ticketID,
    data: {
      type: 'Task',
      task,
    },
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  if (isDragging) {
    return (
      <div
        // ref={setNodeRef}
        style={style}
        className='border border-2 rounded-1'
      >
        <div className='board-card-item text-muted text-hover-primary cursor-pointer border-gray-300 border d-flex border rounded-2 align-items-center px-5 py-4 position-relative opacity-0'>
          {handleTicketLayout({ticketData})}
        </div>
      </div>
    )
  }

  return (
    <div
      ref={(node) => {
        setNodeRef(node)
        cardRef.current = node
      }}
      style={style}
      {...attributes}
      {...listeners}
      className='board-card-item text-muted text-hover-primary cursor-pointer border-gray-300 border d-flex border rounded-2 align-items-center px-5 py-4 position-relative'
    >
      {handleTicketLayout({ticketData})}
    </div>
  )
}

export default TaskCard
