import {useState} from 'react'
import useApi from '../../../../services/useApi'
import {useParams} from 'react-router-dom'

const useProjectMappingOperations = (projectMappingDetails: any) => {
  const Api = useApi()
  const {projectId} = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const {tableId, columnId} = projectMappingDetails

  const updateTableColumn = () => {
    if (!tableId || !columnId) return
    return Api.post(
      `/agile/projects/dbtable/columns/${tableId}`,
      {column_key: columnId, project_id: projectId},
      {
        setLoading: setIsLoading,
      }
    )
  }

  const mapProjectTableStatus = (selectedOption: any, columnId: number, setError: Function) => {
    return Api.post(
      `/agile/projects/column-mapping/${projectId}/${columnId}`,
      {db_table_id: tableId, column_value: selectedOption},
      {
        successMessage: 'Project mapped successfully',
        setLoading: setIsLoading,
        setUseFormError: setError,
        refetch: 'board-columns',
      }
    )
  }

  return {
    updateTableColumn,
    mapProjectTableStatus,
    isLoading,
  }
}

export default useProjectMappingOperations
