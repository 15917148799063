import {useContext, useEffect, useState} from 'react'
import InactiveCustomerTableRow from './InactiveCustomerTableRow'
import {IdleCustomersDashboardContext} from '../../context'
import NoDataFound from '../../../../components/NoDataFound'
import {Link, useLocation} from 'react-router-dom'
import Loading from '../../../loading'
import {toggleSortingOrder} from '../../../../utils/common'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'
import {SampleCSVDownload} from '../../../../components/SampleCSVDownload'
import {useGetDataForCSV} from '../../../../hook/useGetDataForCSV'
import moment from 'moment'
import Import from '../../../../images/Import.png'
import {dataToInactiveCustomerCSV} from '../../utils'

function InactiveCustomerListing() {
  const location = useLocation()
  const {fetchDataForCSV, isLoading} = useGetDataForCSV()
  const isTableView = location?.pathname?.split('/')?.length > 3
  const {
    inactiveCustomers,
    inactiveCustomersLoading,
    InactiveCustomerFilters: filters,
    onInactiveCustomerClickSorting: onClickSorting,
  } = useContext<any>(IdleCustomersDashboardContext)

  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')

  useEffect(() => {
    if (filters) {
      const array = filters['sort_by']?.split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [filters])

  const handleDownLoadCSV = async () => {
    const formattedDateTime = moment().format('D_MMM_HH_mm').toLowerCase()
    const res: any = await fetchDataForCSV('/analytics/customers/no-orders')
    if (res?.status === 200) {
      const data = dataToInactiveCustomerCSV(res?.data)
      if (data?.length) {
        SampleCSVDownload(data || [], `Top_User_with_0_Order_${formattedDateTime}`)
      }
    }
  }

  return (
    <div className={`${isTableView ? 'pt-15' : 'card-body  card card-flush  border p-7 mb-10'}`}>
      <div className='d-flex align-items-center justify-content-between'>
        <h2 className='mb-10'>{!isTableView && 'Top User with 0 Order'}</h2>
        <button className='btn btn-outline mb-10' onClick={handleDownLoadCSV} disabled={isLoading || !inactiveCustomers?.length}>
          <div className='d-flex align-items-center'>
            <img src={Import} alt='Import File' className='me-2 import-icon' />
            <div className='d-flex align-items-center'>
              {isLoading ? 'Downloading...' : 'Export CSV'}
              {isLoading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </div>
          </div>
        </button>
      </div>

      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                <th className='min-w-250px'>{'CUSTOMER NAME'}</th>
                <th className='min-w-250px'>{'EMAIL'}</th>
                <th className='min-w-200px'>{'CUSTOMER REP'}</th>
                <th className='min-w-250px'>{'CUSTOMER GROUP'}</th>
                {isTableView ? (
                  <th
                    className='min-w-200px'
                    onClick={() => onClickSorting('date_created', toggleSortingOrder(direction))}
                  >
                    <div className='d-flex align-items-center cursor-pointer'>
                      <span className='me-2'>CREATED DATE</span>
                      {direction === 'asc' && sortBy === 'date_created' ? (
                        <i className='bi bi-caret-up-fill fs-8'></i>
                      ) : direction === 'desc' && sortBy === 'date_created' ? (
                        <i className='bi bi-caret-down-fill fs-8'></i>
                      ) : (
                        <RenderBothArrow />
                      )}
                    </div>
                  </th>
                ) : (
                  <th className='min-w-200px'>{'CREATED DATE'}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {inactiveCustomers && inactiveCustomers.length > 0
                ? inactiveCustomers?.map((row: any) => (
                    <InactiveCustomerTableRow key={row['customerID']} row={row} />
                  ))
                : !inactiveCustomersLoading && <NoDataFound colspan={6} />}
            </tbody>
          </table>
          {inactiveCustomersLoading && <Loading />}
        </div>
      </div>

      {!isTableView && (
        <>
          {inactiveCustomers && inactiveCustomers.length > 0 && (
            <>
              <div className=''>
                <Link
                  to='/analytics/idle-customers/inactive-customers'
                  className='btn btn-link btn-color-black btn-active-color-primary text-primary text-hover-dark text-decoration-underline'
                >
                  View More
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default InactiveCustomerListing
