import * as Yup from 'yup'

export const createTicketValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Ticket Name is required.')
    .max(255, 'Maximum character limit is 255.'),
})

export const AddBoardMembersSchema = Yup.object().shape({
  usernames: Yup.array().required('Members is required').min(1, 'Please add at least one member'),
})

export const AddBoardColumnSchema = Yup.object().shape({
  name: Yup.string().required('Column Name is required'),
})

export const AddBoardModuleSchema = Yup.object().shape({
  name: Yup.string().required('Module Name is required'),
})

export const AddBoardCustomFieldsSchema = Yup.object().shape({
  name: Yup.string().required('Field name is required'),
})
