import {convertKeysToSnakeCase, isEmpty} from '../../../utils/common'

export const defaultProjectTicketValues = {
  currentColumnId: 0,
  moduleId: 0,
  status: 0,
  priority: 0,
  title: '',
  description: null,
  assignedTo: '',
  spentTime: '',
  estimation: '',
  dueDate: null,
}

export const setDefaultTicketDetails = (ticketDetails: any, reset: any) => {
  if (isEmpty(ticketDetails)) return
  return reset({
    assignedTo: ticketDetails?.assignee,
    currentColumnId: ticketDetails?.columnID,
    moduleId: ticketDetails?.moduleID,
    status: ticketDetails?.status,
    priority: ticketDetails?.priority,
    title: ticketDetails?.title,
    description: ticketDetails?.description,
    spentTime: ticketDetails?.spent_time,
    estimation: ticketDetails?.estimation,
    dueDate: ticketDetails?.due_date,
  })
}

export const getUpdatedData = (dirtyFields: any, formData: any) => {
  const updatedData = Object.keys(dirtyFields).reduce((acc: any, key) => {
    acc[key] = formData[key]
    return acc
  }, {})
  return convertKeysToSnakeCase(updatedData)
}

export const defaultCustomFieldsValues = (customFormFields: any) => {
  return customFormFields.reduce((acc: any, field: any) => {
    acc[field.fieldId] = {
      [field.valueType]: field.defaultValues,
    }
    return acc
  }, {})
}

export const dataToFormFields = (customFields: any) => {
  if (isEmpty(customFields)) return []

  return [
    {
      id: 'line-break',
      label: 'Custom Fields',
      inputClass: 'd-none',
      labelClass: 'form-label w-100 fw-bolder fs-3 mb-0 pb-2 border-bottom mt-8',
    },
    ...customFields.map((customField: any) => {
      const field: any = {
        id: `custom-field-${customField.id}`,
        fieldId: customField?.id,
        label: customField.name,
      }

      if (customField.datatype === 'string') {
        field.registerKey = `${customField.id}[strValue]`
        field.valueType = 'strValue'
        field.defaultValues = customField.str_value || ''
        field.maxLength = 255
      }

      if (customField.datatype === 'group' && !customField.is_multiselect_group) {
        field.isSelect = true
        field.registerKey = `${customField.id}[selectValue]`
        field.valueType = 'selectValue'
        field.defaultValues = customField.str_value || ''
        field.options = customField?.group_values
          ?.split(',')
          .map((val: any) => ({label: val.trim(), value: val.trim()}))
      }

      if (customField.datatype === 'group' && customField.is_multiselect_group) {
        field.isMultiSelect = true
        field.registerKey = `${customField.id}[multiSelectValue]`
        field.valueType = 'multiSelectValue'
        field.defaultValues =
          customField?.str_value
            ?.split(', ')
            .map((val: any) => ({label: val.trim(), value: val.trim()})) || []
        field.inputClass = 'w-50'
        field.options = customField?.group_values
          ?.split(',')
          .map((val: any) => ({label: val.trim(), value: val.trim()}))
      }

      if (customField.datatype === 'float') {
        field.isNumberInput = true
        field.isFloat = true
        field.registerKey = `${customField.id}[floatValue]`
        field.valueType = 'floatValue'
        field.defaultValues = customField.float_value || null
        field.maxLength = 255
      }

      if (customField.datatype === 'date') {
        field.isInputDate = true
        field.registerKey = `${customField.id}[dateValue]`
        field.valueType = 'dateValue'
        field.defaultValues = customField.str_value || null
        field.isNullable = true
      }
      return field
    }),
  ]
}

export const ticketTitleTextareaHeight = (textareaRef: any) => {
  if (textareaRef.current) {
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
  }
}
