import {isEmpty} from '../../../utils/common'
import {capitalizeFirstLetter} from '../../../utils/string'

//<---------------------- Board Settings Page ----------------------->

export const boardColumnsData = (data: any) => {
  if (isEmpty(data)) return []

  return data.map((column: any) => {
    return {
      columnId: column['column_id'],
      columnName: column['name'] || '-',
      cards: column['cards'] || 0,
      description: column['description'],
      mappedColumnValue: column['mapped_column_value'] || '',
      isVisible: column['is_visible'] ?? '-',
    }
  })
}

export const boardModulesData = (data: any) => {
  if (isEmpty(data)) return []

  return data.map((module: any) => {
    return {
      moduleId: module['module_id'],
      moduleName: capitalizeFirstLetter(module['name']) || '-',
      cards: module['cards'] || 0,
      description: module?.description || '',
      isVisible: module['is_visible'] ?? '-',
    }
  })
}

export const addBoardCustomFieldsData = (data: any, isEdit: boolean) => {
  return {
    name: isEdit ? data?.custom_field_name : '',
    customfield_meta_ids: [],
  }
}
