import {useForm} from 'react-hook-form'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {useContext} from 'react'
import {ProjectMembersPageContext} from '../../context'
import MultiSelect from '../../../../components/MultiSelect/MultiSelect'
import {AddBoardMembersModalTypes} from '../../interfaces'
import {yupResolver} from '@hookform/resolvers/yup'
import {AddBoardMembersSchema} from '../../validations'

const AddBoardMembersModal = ({show, onClose}: AddBoardMembersModalTypes) => {
  const {membersList, addProjectMembers, isLoadingOperation, isLoadingProjectMembersList} =
    useContext(ProjectMembersPageContext)

  const {
    control,
    handleSubmit,
    formState: {errors, isValid, isDirty},
  } = useForm({
    defaultValues: {
      usernames: [],
    },
    resolver: yupResolver(AddBoardMembersSchema),
  })

  const onAddMember = (data: any) => {
    addProjectMembers(data)
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onAction={handleSubmit(onAddMember)}
      title={'Add Member'}
      actionName='Add'
      actionBtnClass='btn-primary'
      isOperationLoading={isLoadingOperation}
      isDataLoading={isLoadingProjectMembersList}
      isDisabled={!isDirty || !isValid || isLoadingOperation || isLoadingProjectMembersList}
      isCloseOnBackdrop={false}
      body={
        <MultiSelect
          id='add-project-members'
          control={control}
          label='Members'
          isRequired={true}
          registerKey='usernames'
          options={membersList}
          labelKey='name'
          valueKey='username'
          error={errors?.usernames}
        />
      }
    />
  )
}

export default AddBoardMembersModal
