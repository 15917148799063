import {Control, Controller} from 'react-hook-form'
import {TextArea} from '../TextArea'
import {CheckBox} from '../CheckBox'
import {RadioGroup} from '../RadioGroup'
import {Select} from '../Select/'
import {TextEditor} from '../TextEditor'
import {InputNumber} from '../InputNumber'
import {CustomFields} from '../customFields'
import {filterFalsieCustomFields} from '../../modules/myProductDetails/utils'
import {convertToNumber} from '../../utils/string'
import {DynamicTable} from '../DynamicTable'
import {InputPrice} from '../InputPrice'
import {MultiSelect} from '../MultiSelect/'
import {DropZone} from '../Dropzone'
import {InputText} from '../InputText'
import {convertSelectedValueType} from '../../utils/common'
import {SectionFormTypes} from './SectionFormTypes'
import MultiSelectTree from '../MultiSelectTree/MultiSelectTree'
import {InputTime} from '../InputTime/Index'
import InputDate from '../InputDate/InputDate'
import {getFormattedDate} from '../../utils/date'
import {NewTextEditor} from '../newTextEditor'
import {ReachSelect} from '../reachSelect'

const SectionForm = ({
  id,
  title,
  titleClass,
  children,
  register,
  setValue,
  control,
  submitBtnRef,
  errors,
  isReadOnly = false,
  isDisabled = false,
  className = '',
  commonClassNames = '',
  commonInputClass = '',
  commonLabelClass = '',
  inputs,
}: SectionFormTypes) => (
  <section id={id} className={`${className} position-relative`}>
    {title && <h3 className={titleClass ? titleClass : 'mb-5'}>{title}</h3>}
    {inputs && (
      <div className='row'>
        {inputs.map(
          ({
            id,
            registerKey,
            label,
            value,
            options,
            placeholder,
            isNullable = false,
            required,
            isTextArea,
            isCheckBox,
            isSelect,
            isRadio,
            isNumberInput,
            isFloat,
            isTextEditor,
            class: colClass = '',
            isHidden,
            isCustomFields,
            config,
            onError,
            onChange,
            isTable,
            tableRow,
            tableColumns,
            stateVariable,
            setStateFunction,
            data,
            registerOptions = {},
            ref,
            maxLength,
            maxLimit,
            decimalLimit,
            isRoundOff,
            isPriceInput,
            isMultiSelect,
            tableSectionClass,
            defaultValues,
            tableClass,
            isDeletable,
            isDisableAddBtn,
            isLoading,
            isDropZone,
            component,
            apiFunction,
            componentProps,
            isReplaceable,
            totalFileSize,
            maxFileUploads,
            errorMessageRef,
            isMultiSelectTree,
            maxFileSize,
            isSearch,
            onSearch,
            modalBodyClass,
            errorClass,
            labelKey,
            valueKey,
            childPadding,
            returnKeys,
            inputClass,
            rows,
            isDisableExpand,
            isDefaultExpandAll,
            onBlur,
            disabled,
            placeholderValue,
            rowParsingFunction,
            checkedRowId,
            labelClass,
            isInputTime,
            isInputDate,
            format,
            placement,
            valueType,
            days,
            hours,
            isPopupDefaultValue,
            isTempAddition,
            isHourConversion,
            show = true,
            isNewTextEditor,
            isReachSelect,
            children,
            readOnly,
            checked,
            isAutoFocus = false,
            disabledKey,
            ...rest
          }) =>
            isTextArea && show ? (
              <TextArea
                id={id}
                label={label}
                className={`${commonClassNames} ${colClass}`}
                register={registerKey && register && register(registerKey, registerOptions)}
                error={errors?.[`${registerKey}`]}
                textAreaRef={ref}
                onChange={onChange}
                isReadOnly={isReadOnly || readOnly}
                disabled={isDisabled || disabled}
                maxLength={maxLength}
                placeholder={placeholder}
                inputClass={`${commonInputClass} ${inputClass}`}
                rows={rows}
                value={value}
                isRequired={required}
                labelClass={`${commonLabelClass} ${labelClass}`}
                onBlur={onBlur}
                {...rest}
              />
            ) : isCheckBox && show ? (
              <CheckBox
                id={id}
                label={label}
                groupClass='form-check-solid'
                className={`${commonClassNames} ${colClass}`}
                register={registerKey && register && register(registerKey, registerOptions)}
                error={errors?.[`${registerKey}`]}
                checkboxRef={ref}
                onChange={onChange}
                isReadOnly={isReadOnly || readOnly}
                disabled={isDisabled || disabled}
                inputClass={`${commonInputClass} ${inputClass}`}
                isRequired={required}
                labelClass={`${commonLabelClass} ${labelClass}`}
                onBlur={onBlur}
                checked={checked}
                {...rest}
              />
            ) : isRadio && show && !children ? (
              <RadioGroup
                id={id}
                value={value}
                label={label}
                className={`${commonClassNames} ${colClass}`}
                register={registerKey && register && register(registerKey, registerOptions)}
                error={errors?.[`${registerKey}`]}
                radioButtonRef={ref}
                onChange={onChange}
                isReadOnly={isReadOnly || readOnly}
                disabled={isDisabled || disabled}
                inputClass={`${commonInputClass} ${inputClass}`}
                isRequired={required}
                labelClass={`${commonLabelClass} ${labelClass}`}
                onBlur={onBlur}
                isLoading={isLoading}
                {...rest}
              />
            ) : isRadio && show && children ? (
              <div className={`${commonClassNames} ${colClass}`}>
                {label && (
                  <label
                    className={`form-label ${labelClass} ${commonLabelClass} ${
                      required ? 'required' : ''
                    }`}
                  >
                    {label}
                  </label>
                )}
                {children.map((child: any) => (
                  <RadioGroup
                    id={child.id}
                    value={child.value}
                    label={child.label}
                    className={child.colClass}
                    register={registerKey && register && register(registerKey, registerOptions)}
                    error={errors?.[`${registerKey}`]}
                    radioButtonRef={child.ref}
                    onChange={child.onChange}
                    isReadOnly={isReadOnly || child.readOnly || readOnly}
                    disabled={isDisabled || child.disabled || disabled}
                    inputClass={child.inputClass}
                    isRequired={child.required}
                    labelClass={child.labelClass}
                    onBlur={child.onBlur}
                    name={child.name}
                    isLoading={isLoading}
                    {...child.rest}
                  />
                ))}
              </div>
            ) : isSelect && show ? (
              <Select
                id={id}
                label={label}
                options={options}
                className={`${commonClassNames} ${colClass}`}
                placeholder={placeholder}
                value={value}
                isNullable={isNullable}
                register={
                  registerKey &&
                  register &&
                  register(registerKey, {
                    ...registerOptions,
                    setValueAs: (v: any) => convertSelectedValueType(options, v),
                  })
                }
                error={errors?.[`${registerKey}`]}
                onChange={onChange}
                selectRef={ref}
                isReadOnly={isReadOnly || readOnly}
                disabled={isDisabled || disabled}
                selectClass={`${commonInputClass} ${inputClass}`}
                isRequired={required}
                placeholderValue={placeholderValue}
                labelClass={`${commonLabelClass} ${labelClass}`}
                labelKey={labelKey}
                valueKey={valueKey}
                valueType={valueType}
                isLoading={isLoading}
                defaultValue={defaultValues}
                onBlur={onBlur}
                {...rest}
              />
            ) : isTextEditor && show ? (
              <TextEditor
                id={id}
                control={control}
                registerKey={registerKey}
                textEditorRef={ref}
                onChange={onChange}
                isReadOnly={isReadOnly || readOnly}
                disabled={isDisabled || disabled}
                error={errors?.[`${registerKey}`]}
                errorMessageRef={errorMessageRef}
                errorClass={errorClass}
                maxLength={maxLength}
                placeholder={placeholder}
                inputClass={inputClass}
                isRequired={required}
                onBlur={onBlur}
                {...rest}
              />
            ) : isNumberInput && show ? (
              <InputNumber
                id={id}
                label={label}
                isRequired={required}
                className={`${commonClassNames} ${colClass}`}
                disabled={isDisabled || disabled}
                isFloat={isFloat}
                isRoundOff={isRoundOff}
                decimalLimit={decimalLimit}
                maxLength={maxLength}
                maxLimit={maxLimit}
                onChange={onChange}
                inputClass={`${commonInputClass} ${inputClass}`}
                register={
                  registerKey &&
                  register &&
                  register(registerKey, {
                    ...registerOptions,
                    setValueAs: convertToNumber,
                  })
                }
                error={errors?.[`${registerKey}`]}
                inputNumberRef={ref}
                isReadOnly={isReadOnly || readOnly}
                placeholder={placeholder}
                labelClass={`${commonLabelClass} ${labelClass}`}
                onBlur={onBlur}
                {...rest}
              />
            ) : isCustomFields && show ? (
              <>
                {registerKey && control ? (
                  <Controller
                    name={registerKey ? registerKey : ''}
                    control={control ?? ({} as Control)}
                    render={({field: {onChange: registerOnChange, value: registeredValue}}) => (
                      <CustomFields
                        id={id}
                        config={config}
                        onError={onError}
                        onChange={(customFields: any) => {
                          registerOnChange(filterFalsieCustomFields(customFields))
                          onChange?.(filterFalsieCustomFields(customFields))
                        }}
                        submitBtnRef={submitBtnRef}
                        values={value}
                        tableSectionClass={tableSectionClass}
                        defaultValues={defaultValues ?? registeredValue}
                        tableClass={tableClass}
                        isDeletable={isDeletable}
                        isDisableAddBtn={isDisableAddBtn}
                        readOnly={isReadOnly || readOnly}
                        isDisabled={isDisabled || disabled}
                        className={`${commonClassNames} ${colClass}`}
                        isRequired={required}
                      />
                    )}
                  />
                ) : (
                  <CustomFields
                    id={id}
                    config={config}
                    onError={onError}
                    onChange={filterFalsieCustomFields}
                    submitBtnRef={submitBtnRef}
                    values={value}
                    tableSectionClass={tableSectionClass}
                    defaultValues={defaultValues}
                    tableClass={tableClass}
                    isDeletable={isDeletable}
                    isDisableAddBtn={isDisableAddBtn}
                    readOnly={isReadOnly || readOnly}
                    isDisabled={isDisabled || disabled}
                    className={`${commonClassNames} ${colClass}`}
                    isRequired={required}
                  />
                )}
              </>
            ) : isTable && show ? (
              <DynamicTable
                id={id}
                data={data}
                checkedRows={stateVariable}
                setCheckedRows={setStateFunction}
                checkedRowId={checkedRowId}
                register={register}
                onChange={onChange}
                sortableColumns={tableColumns}
                TableRow={tableRow}
                rowParsingFunction={rowParsingFunction}
              />
            ) : isPriceInput && show ? (
              <InputPrice
                id={id}
                label={label}
                isRequired={required}
                className={`${commonClassNames} ${colClass}`}
                disabled={isDisabled || disabled}
                isFloat={isFloat}
                isRoundOff={isRoundOff}
                inputClass={`${commonInputClass} ${inputClass}`}
                decimalLimit={decimalLimit}
                maxLength={maxLength}
                maxLimit={maxLimit}
                register={
                  registerKey &&
                  register &&
                  register(registerKey, {
                    ...registerOptions,
                    setValueAs: convertToNumber,
                  })
                }
                error={errors?.[`${registerKey}`]}
                inputPriceRef={ref}
                onChange={onChange}
                isReadOnly={isReadOnly || readOnly}
                placeholder={placeholder}
                labelClass={`${commonLabelClass} ${labelClass}`}
                onBlur={onBlur}
                {...rest}
              />
            ) : isMultiSelect && show ? (
              <MultiSelect
                id={id}
                label={label}
                options={options}
                className={`${commonClassNames} ${colClass}`}
                placeholder={placeholder}
                handleSelected={onChange}
                defaultValues={defaultValues}
                control={control}
                registerKey={registerKey}
                error={errors?.[`${registerKey}`]}
                isLoading={isLoading}
                isReadOnly={isReadOnly || readOnly}
                isRequired={required}
                disabled={isDisabled || disabled}
                inputClass={`${commonInputClass} ${inputClass}`}
                labelClass={`${commonLabelClass} ${labelClass}`}
                labelKey={labelKey}
                valueKey={valueKey}
                valueType={valueType}
                onBlur={onBlur}
                {...rest}
              />
            ) : isDropZone && show ? (
              <DropZone
                id={id}
                Component={component}
                onUploadImage={onChange}
                apiFunction={apiFunction}
                totalFileSize={totalFileSize}
                maxFileUploads={maxFileUploads}
                isReplaceable={isReplaceable}
                defaultFile={defaultValues}
                isDisabled={isDisabled || disabled}
                maxFileSize={maxFileSize}
                componentProps={{
                  ...componentProps,
                  register: register,
                  setValue: setValue,
                }}
              />
            ) : isMultiSelectTree && show ? (
              <>
                {registerKey && control ? (
                  <Controller
                    name={registerKey || ''}
                    control={control}
                    render={({field: {onChange: registerOnChange, value: registeredValue}}) => (
                      <>
                        <MultiSelectTree
                          treeData={options}
                          onSubmit={(data: any) => {
                            registerOnChange(data)
                            onChange?.(data)
                          }}
                          label={label}
                          id={id}
                          error={errors[registerKey]}
                          defaultValue={defaultValues ?? registeredValue}
                          placeholder={
                            options && options?.length > 0
                              ? `Select ${label}`
                              : `No ${label} found.`
                          }
                          isRequired={required}
                          isDisable={isDisabled || disabled}
                          isReadOnly={options?.length === 0 || readOnly}
                          className={`${commonClassNames} ${colClass}`}
                          isLoading={isLoading}
                          isSearch={isSearch}
                          onSearch={onSearch}
                          modalBodyClass={modalBodyClass}
                          labelKey={labelKey}
                          valueKey={valueKey}
                          childPadding={childPadding}
                          returnKeys={returnKeys}
                          isDisableExpand={isDisableExpand}
                          isDefaultExpandAll={isDefaultExpandAll}
                        />
                      </>
                    )}
                  />
                ) : (
                  <MultiSelectTree
                    treeData={options}
                    onSubmit={onChange}
                    label={label}
                    id={id}
                    defaultValue={defaultValues}
                    placeholder={
                      options && options?.length > 0 ? `Select ${label}` : `No ${label} found.`
                    }
                    isRequired={required}
                    isDisable={isDisabled || disabled}
                    isReadOnly={options?.length === 0 || readOnly}
                    className={`${commonClassNames} ${colClass}`}
                    isLoading={isLoading}
                    isSearch={isSearch}
                    onSearch={onSearch}
                    modalBodyClass={modalBodyClass}
                    labelKey={labelKey}
                    valueKey={valueKey}
                    childPadding={childPadding}
                    returnKeys={returnKeys}
                    isDisableExpand={isDisableExpand}
                    isDefaultExpandAll={isDefaultExpandAll}
                  />
                )}
              </>
            ) : isInputTime && show ? (
              <>
                {registerKey && control ? (
                  <Controller
                    name={registerKey || ''}
                    control={control}
                    render={({field: {onChange: registerOnChange, value: registeredValue}}) => (
                      <InputTime
                        id={id}
                        label={label}
                        className={`${commonClassNames} ${colClass}`}
                        inputClass={`${commonInputClass} ${inputClass}`}
                        labelClass={`${commonLabelClass} ${labelClass}`}
                        popupPositionClass={placement}
                        days={days}
                        error={errors?.[`${registerKey}`]}
                        hours={hours}
                        isPopupDefaultValue={isPopupDefaultValue}
                        isTempAddition={isTempAddition}
                        isLoading={isLoading}
                        defaultValue={defaultValues ?? registeredValue}
                        isHourConversion={isHourConversion}
                        handleSelectedTime={(time: any) => {
                          registerOnChange(time)
                          onChange?.(time)
                        }}
                        isRequired={required}
                        errorClass={errorClass}
                        isDisabled={isDisabled || disabled}
                        isReadOnly={isReadOnly || readOnly}
                        onBlur={onBlur}
                      />
                    )}
                  />
                ) : (
                  <InputTime
                    id={id}
                    label={label}
                    inputClass={`${commonInputClass} ${inputClass}`}
                    labelClass={`${commonLabelClass} ${labelClass}`}
                    error={errors?.[`${registerKey}`]}
                    className={`${commonClassNames} ${colClass}`}
                    popupPositionClass={placement}
                    days={days}
                    hours={hours}
                    isPopupDefaultValue={isPopupDefaultValue}
                    isTempAddition={isTempAddition}
                    isLoading={isLoading}
                    defaultValue={defaultValues}
                    isHourConversion={isHourConversion}
                    handleSelectedTime={onChange}
                    isRequired={required}
                    errorClass={errorClass}
                    isDisabled={isDisabled || disabled}
                    isReadOnly={isReadOnly || readOnly}
                    onBlur={onBlur}
                  />
                )}
              </>
            ) : isInputDate && show ? (
              <>
                {registerKey && control ? (
                  <Controller
                    name={registerKey || ''}
                    control={control}
                    render={({field: {onChange: registerOnChange, value: registeredValue}}) => (
                      <InputDate
                        id={id}
                        label={label}
                        className={`${commonClassNames} ${colClass}`}
                        inputClass={`${commonInputClass} ${inputClass}`}
                        labelClass={`${commonLabelClass} ${labelClass}`}
                        isRequired={required}
                        cleanable={isNullable}
                        value={value ?? registeredValue}
                        defaultValue={defaultValues}
                        onChange={(time: any) => {
                          registerOnChange(getFormattedDate(time))
                          onChange?.(time)
                        }}
                        format={format}
                        menuClassName={modalBodyClass}
                        placement={placement}
                        error={errors?.[`${registerKey}`]}
                        errorClass={errorClass}
                        placeholder={placeholder}
                        isLoading={isLoading}
                        isDisabled={isDisabled || disabled}
                        isReadOnly={isReadOnly || readOnly}
                        onBlur={onBlur}
                      />
                    )}
                  />
                ) : (
                  <InputDate
                    id={id}
                    label={label}
                    className={`${commonClassNames} ${colClass}`}
                    inputClass={`${commonInputClass} ${inputClass}`}
                    labelClass={`${commonLabelClass} ${labelClass}`}
                    isRequired={required}
                    cleanable={isNullable}
                    value={value}
                    defaultValue={defaultValues}
                    onChange={onChange}
                    format={format}
                    menuClassName={modalBodyClass}
                    placement={placement}
                    error={errors?.[`${registerKey}`]}
                    errorClass={errorClass}
                    placeholder={placeholder}
                    isLoading={isLoading}
                    isDisabled={isDisabled || disabled}
                    isReadOnly={isReadOnly || readOnly}
                    onBlur={onBlur}
                  />
                )}
              </>
            ) : isNewTextEditor && show ? (
              <NewTextEditor
                id={id}
                control={control}
                registerKey={registerKey}
                textEditorRef={ref}
                onChange={onChange}
                isReadOnly={isReadOnly || readOnly}
                disabled={isDisabled || disabled}
                error={errors?.[`${registerKey}`]}
                errorMessageRef={errorMessageRef}
                errorClass={errorClass}
                maxLength={maxLength}
                placeholder={placeholder}
                inputClass={inputClass}
                isRequired={required}
                onBlur={onBlur}
                isAutoFocus={isAutoFocus}
                {...rest}
              />
            ) : isReachSelect && show ? (
              <ReachSelect
                id={id}
                label={label}
                options={options}
                className={`${commonClassNames} ${colClass}`}
                placeholder={placeholder}
                value={value}
                isNullable={isNullable}
                control={control}
                registerKey={registerKey}
                error={errors?.[`${registerKey}`]}
                onChange={onChange}
                selectRef={ref}
                isReadOnly={isReadOnly || readOnly}
                disabled={isDisabled || disabled}
                selectClass={`${commonInputClass} ${inputClass}`}
                isRequired={required}
                placeholderValue={placeholderValue}
                labelClass={`${commonLabelClass} ${labelClass}`}
                labelKey={labelKey}
                valueKey={valueKey}
                valueType={valueType}
                disabledKey={disabledKey}
                isLoading={isLoading}
                defaultValue={defaultValues}
                onBlur={onBlur}
                registerOptions={registerOptions}
                {...rest}
              />
            ) : (
              show && (
                <InputText
                  id={id}
                  label={label}
                  isRequired={required}
                  className={`${commonClassNames} ${colClass}`}
                  disabled={isDisabled || disabled}
                  onChange={onChange}
                  register={registerKey && register && register(registerKey, registerOptions)}
                  error={errors?.[`${registerKey}`]}
                  value={value}
                  inputTextRef={ref}
                  maxLength={maxLength}
                  isReadOnly={isReadOnly || readOnly}
                  inputClass={`${commonInputClass} ${inputClass}`}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  labelClass={`${commonLabelClass} ${labelClass}`}
                  {...rest}
                />
              )
            )
        )}
      </div>
    )}
    {children}
  </section>
)

export default SectionForm
