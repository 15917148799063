import {Route, Routes, Outlet, Navigate, useParams} from 'react-router-dom'
import ProjectPageNavigation from './components/ProjectPageNavigation'
import AgileBoard from './layouts/AgileBoard'
import useMeta from '../../hook/useMeta'
import BoardSetting from './layouts/BoardSetting'
import Members from './layouts/Members'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import usePermission from '../../hook/usePermission'
import {toTitleCase} from '../../utils/string'
import {ProjectDetailsContext} from './context'
import useGetProjectDetails from './hooks/useGetProjectDetails'

function ProjectPage() {
  useMeta('Project')

  const {hasPermission} = usePermission()
  const {projectId, projectName, projectStatus}: any = useParams<any>()
  const {projectDetails, isLoading} = useGetProjectDetails()

  const contextValue = {
    projectDetails,
    defaultProjectMapping: projectDetails?.project_mapping,
    isLoading,
  }

  const analyticsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Projects',
      path: `/projects/`,
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <ProjectDetailsContext.Provider value={contextValue}>
      <Routes>
        <Route
          element={
            <>
              <ProjectPageNavigation />
              <Outlet />
            </>
          }
        >
          <Route
            path='/agile-board'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>{toTitleCase(projectName)}</PageTitle>
                <AgileBoard />
              </>
            }
          />

          <Route
            path='/members'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>{toTitleCase(projectName)}</PageTitle>
                <Members />
              </>
            }
          />

          {hasPermission('projects_all projects', 'write') && (
            <Route
              path='/board-setting'
              element={
                <>
                  <PageTitle breadcrumbs={analyticsBreadCrumbs}>
                    {toTitleCase(projectName)}
                  </PageTitle>
                  <BoardSetting />
                </>
              }
            />
          )}

          <Route
            index
            element={
              <Navigate to={`/projects/${projectStatus}/${projectName}/${projectId}/agile-board`} />
            }
          />
        </Route>
      </Routes>
    </ProjectDetailsContext.Provider>
  )
}

export default ProjectPage
