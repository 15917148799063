import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'

const useGetBoardCustomFieldsList = () => {
  const Api = useApi()
  const params = useParams()
  const projectId = params?.['projectId'] as string

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${projectId}/custom-fields`,
    {
      queryId: 'board-custom-fields',
      isErrorPageRedirection: false
    },
  )

  return {
    boardCustomFieldsList: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetBoardCustomFieldsList
