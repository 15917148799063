import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

const useGetCustomFieldsList = () => {
  const Api = useApi()
  const {projectId, cardId} = useParams<any>() as {projectId: string; cardId: string}

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${projectId}/${cardId}/custom-fields`,
    {
      queryId: 'additional-custom-fields',
      isErrorPageRedirection: false,
      isToast: false,
      isCaching: false,
    },
    {
      refetchInterval: 0,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  return {
    customFieldsList: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetCustomFieldsList
