import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

const useGetProjectDetails = () => {
  const Api = useApi()
  const {projectId} = useParams()

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}`, {
    queryId: 'project-details',
  })

  return {
    projectDetails: response?.data?.[0] || {},
    isLoading: isFetching,
  }
}

export default useGetProjectDetails
