import useApi from '../../../../services/useApi'
import {useParams} from 'react-router-dom'
import {boardModulesData} from '../../parsings'

const useGetBoardModulesList = () => {
  const Api = useApi()
  const {projectId} = useParams()

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/modules`, {
    queryId: 'board-modules',
  })

  return {
    boardModulesList: boardModulesData(response?.data),
    isLoading: isFetching,
  }
}

export default useGetBoardModulesList
