import {useForm} from 'react-hook-form'
import {SectionForm} from '../../../components/SectionForm'
import {isEmpty} from '../../../utils/common'
import {parseNumber} from '../../../utils/parseNumber'
import {useContext} from 'react'
import {TicketDetailsPageContext} from '../context'
import {defaultCustomFieldsValues} from '../utils'

export const CustomFields = ({customFormFields}: any): any => {
  const {updateCustomField, hasTicketWritePermission} = useContext(TicketDetailsPageContext)
  const {
    register,
    control,
    watch,
    reset,
    setValue,
    formState: {dirtyFields},
  } = useForm({
    defaultValues: defaultCustomFieldsValues(customFormFields),
  })

  const onSubmit = () => {
    const customFieldId = parseNumber(Object?.entries(dirtyFields)?.[0]?.[0], null)
    setValue(`${customFieldId}.projectCustomfieldId`, customFieldId)
    if (!isEmpty(dirtyFields)) {
      const payload = watch()?.[Object?.entries(dirtyFields)?.[0]?.[0]]
      updateCustomField(payload)
      reset(watch())
    }
  }

  return (
    <SectionForm
      id='ticket-custom-fields'
      commonClassNames='position-relative mb-6 ticket-field-item'
      commonLabelClass='w-100'
      commonInputClass='col'
      register={register}
      control={control}
      isDisabled={!hasTicketWritePermission}
      inputs={customFormFields?.map((customField: any) => ({
        ...customField,
        onBlur: onSubmit,
      }))}
    />
  )
}
