import {useLocation, useParams} from 'react-router-dom'
import usePermission from '../../../hook/usePermission'
import {BoardSettingsPageContext, ProjectDetailsContext} from '../context'
import useColumnOperations from '../hooks/boardSettings/useColumnOperations'
import useCustomFieldsOperations from '../hooks/boardSettings/useCustomFieldsOperations'
import useGetBoardColumnsList from '../hooks/boardSettings/useGetBoardColumnsList'
import useGetBoardCustomFieldsList from '../hooks/boardSettings/useGetBoardCustomFieldsList'
import useGetBoardModulesList from '../hooks/boardSettings/useGetBoardModulesList'
import useModulesOperations from '../hooks/boardSettings/useModulesOperations'
import ColumnsTable from '../sections/tables/boardSettingTables/ColumnsTable'
import CustomFieldsTable from '../sections/tables/boardSettingTables/CustomFieldsTable'
import ModulesTable from '../sections/tables/boardSettingTables/ModulesTable'
import {toTitleCase} from '../../../utils/string'
import ProjectMappingSection from '../sections/ProjectMappingSection'
import useGetProjectMappingDropdowns from '../hooks/boardSettings/useGetProjectMappingDropdowns'
import useProjectMappingOperations from '../hooks/boardSettings/useProjectMappingOperations'
import {useContext, useEffect, useState} from 'react'
import {projectMappingDefaultValues} from '../utils'
import {isEmpty} from '../../../utils/common'

function BoardSetting() {
  const {hasPermission} = usePermission()
  const {projectId, projectName, projectStatus}: any = useParams<any>()
  const {pathname} = useLocation()
  const {projectDetails} = useContext(ProjectDetailsContext)
  const hasBoardSettingsPermission =
    (hasPermission('projects_all projects', 'write') &&
      !pathname.includes('archived') &&
      projectDetails?.is_owner) ||
    false
  const [projectMappingDetails, setProjectMappingDetails] = useState(
    projectMappingDefaultValues(projectDetails?.project_mapping)
  )
  const {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    columnOperationId,
    isLoading: isLoadingColumnOperation,
  } = useColumnOperations()
  const {
    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    customFieldOperationId,
    isLoading: isLoadingCustomFieldOperation,
  } = useCustomFieldsOperations()
  const {boardColumnsList, isLoading: isLoadingBoardColumnsList} = useGetBoardColumnsList()
  const {boardModulesList, isLoading: isLoadingBoardModulesList} = useGetBoardModulesList()
  const {boardCustomFieldsList, isLoading: isLoadingBoardCustomFieldsList} =
    useGetBoardCustomFieldsList()
  const {
    projectTablesDropdownList,
    isLoadingProjectTablesDropdownList,
    tableColumnsDropdownList,
    isLoadingTableColumnsDropdownList,
    tableStatusDropdownList,
    isLoadingTableStatusDropdownList,
  } = useGetProjectMappingDropdowns(projectMappingDetails)

  const {
    updateTableColumn,
    mapProjectTableStatus,
    isLoading: isLoadingProjectMappingOperations,
  } = useProjectMappingOperations(projectMappingDetails)

  const {
    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    moduleOperationId,
    isLoading: isLoadingModulesOperation,
  } = useModulesOperations()

  useEffect(() => {
    if (!isEmpty(projectDetails?.project_mapping)) {
      setProjectMappingDetails(projectMappingDefaultValues(projectDetails?.project_mapping))
    }
  }, [projectDetails])

  const contextValue = {
    hasBoardSettingsPermission,

    projectId,
    projectName: toTitleCase(projectName),
    projectStatus,

    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    columnOperationId,
    isLoadingColumnOperation,

    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    customFieldOperationId,
    isLoadingCustomFieldOperation,

    boardColumnsList,
    isLoadingBoardColumnsList,

    boardCustomFieldsList,
    isLoadingBoardCustomFieldsList,

    boardModulesList,
    isLoadingBoardModulesList,

    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    moduleOperationId,
    isLoadingModulesOperation,

    projectMappingDetails,
    setProjectMappingDetails,
    projectTablesDropdownList,
    isLoadingProjectTablesDropdownList,
    tableColumnsDropdownList,
    isLoadingTableColumnsDropdownList,
    tableStatusDropdownList,
    isLoadingTableStatusDropdownList,
    updateTableColumn,
    mapProjectTableStatus,
    isLoadingProjectMappingOperations,
  }

  return (
    <BoardSettingsPageContext.Provider value={contextValue}>
      <div className='d-flex flex-column gap-20'>
        <ProjectMappingSection />
        <ColumnsTable />
        <ModulesTable />
        <CustomFieldsTable />
      </div>
    </BoardSettingsPageContext.Provider>
  )
}

export default BoardSetting
