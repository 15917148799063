import {useContext, useState} from 'react'
import {UserContext} from '../context'
import TableRow from '../sections/table/TableRow'
import UserModal from './modals/UserModal'
import UserSearch from './UserSearch'
import usePermission from '../../../hook/usePermission'
import {Pagination} from '../../../utils/pagination'
import {toggleSortingOrder} from '../../../utils/common'
import Loading from '../../loading'
import {RenderBothArrow} from '../../../utils/renderBothArrow'
import NoDataFound from '../../../components/NoDataFound'

export default function UserTable() {
  const [modal, setModal] = useState(false)
  const {users, isFetchingUsers, pagination, onPageChange, onSortingChange, filters}: any =
    useContext(UserContext)
  const [editId, setEditId] = useState(null)
  const {hasPermission} = usePermission()
  const toggleModal = () => setModal(!modal)
  const onClose = () => setModal(false)

  return (
    <>
      <div className='d-flex justify-content-between mb-10'>
        <UserSearch />
        {hasPermission('settings_users', 'write') ? (
          <>
            <div>
              <button
                type='button'
                className='btn flex-end btn btn-primary'
                onClick={() => {
                  setModal(!modal)
                  setEditId(null)
                }}
              >
                <span className='indicator-label'>+ Add User</span>
              </button>
              <UserModal show={modal} onClose={onClose} editId={editId} />
            </div>
          </>
        ) : null}
      </div>
      <div className='card-body p-0'>
        <div className='table-responsive position-relative'>
          <div className='table-loader-wrapper'>
            <table
              id='kt_table_products'
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'
            >
              <thead className='table-row-bordered'>
                <tr className='fs-6 fw-semibold text-muted text-uppercase'>
                  <th
                    className='min-w-200px'
                    onClick={() =>
                      onSortingChange('name', toggleSortingOrder(filters['sort_order']))
                    }
                  >
                    <div className='d-flex align-items-center'>
                      <span className='me-2'>User</span>
                      {filters['sort_order'] === 'asc' && filters['sort_by'] === 'name' ? (
                        <i className='bi bi-caret-up-fill fs-8'></i>
                      ) : filters['sort_order'] === 'desc' && filters['sort_by'] === 'name' ? (
                        <i className='bi bi-caret-down-fill fs-8'></i>
                      ) : (
                        <RenderBothArrow />
                      )}
                    </div>
                  </th>
                  <th
                    className='min-w-150px'
                    onClick={() =>
                      onSortingChange('role', toggleSortingOrder(filters['sort_order']))
                    }
                  >
                    <div className='d-flex align-items-center'>
                      <span className='me-2'>Role</span>
                      {filters['sort_order'] === 'asc' && filters['sort_by'] === 'role' ? (
                        <i className='bi bi-caret-up-fill fs-8'></i>
                      ) : filters['sort_order'] === 'desc' && filters['sort_by'] === 'role' ? (
                        <i className='bi bi-caret-down-fill fs-8'></i>
                      ) : (
                        <RenderBothArrow />
                      )}
                    </div>
                  </th>
                  <th className='min-w-200px'>Last Login</th>
                  <th
                    className='min-w-100px'
                    onClick={() =>
                      onSortingChange('status', toggleSortingOrder(filters['sort_order']))
                    }
                  >
                    <div className='d-flex align-items-center'>
                      <span className='me-2'>Status</span>
                      {filters['sort_order'] === 'asc' && filters['sort_by'] === 'status' ? (
                        <i className='bi bi-caret-up-fill fs-8'></i>
                      ) : filters['sort_order'] === 'desc' && filters['sort_by'] === 'status' ? (
                        <i className='bi bi-caret-down-fill fs-8'></i>
                      ) : (
                        <RenderBothArrow />
                      )}
                    </div>
                  </th>
                  {hasPermission('settings_users', 'write') && <th className='w-80px text-center'>Action</th>}
                </tr>
              </thead>

              <tbody>
                {users && users.length
                  ? users.map((row: any, index: number) => (
                      <TableRow
                        row={row}
                        key={row['id']}
                        index={index}
                        onEdit={() => {
                          setEditId(row['username'])
                          toggleModal()
                        }}
                        toggleModal={toggleModal}
                      />
                    ))
                  : !isFetchingUsers && <NoDataFound colspan={5} />}
              </tbody>
            </table>
            {isFetchingUsers && <Loading />}
          </div>
        </div>
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </div>
    </>
  )
}
