/* eslint-disable */
import {useContext, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {TicketDetailsPageContext} from '../context'
import {SectionForm} from '../../../components/SectionForm'
import CommentSectionNavigation from '../components/CommentSectionNavigation'
import {
  dataToFormFields,
  defaultProjectTicketValues,
  getUpdatedData,
  setDefaultTicketDetails,
} from '../utils'
import {isEmpty} from '../../../utils/common'
import TicketDetailsPageHeader from './headers/TicketDetailsPageHeader'
import {CustomFields} from './CustomFields'
import {yupResolver} from '@hookform/resolvers/yup'
import {TicketDetailsFormSchema} from '../validations'
import {TextEditor} from '../../../components/lexicalTextEdiror'

const TicketForm = () => {
  const {projectMembers, isLoadingProjectMembers} = useContext(TicketDetailsPageContext)
  const {projectKanbanStages, isLoadingProjectKanbanStages} = useContext(TicketDetailsPageContext)
  const {projectModules, isLoadingProjectModules} = useContext(TicketDetailsPageContext)
  const {projectStatuses, isLoadingProjectStatuses} = useContext(TicketDetailsPageContext)
  const {projectPriorities, isLoadingProjectPriorities} = useContext(TicketDetailsPageContext)
  const {ticketDetails, isLoadingTicketDetails} = useContext(TicketDetailsPageContext)
  const {updateTicket, isLoadingTicketOperation} = useContext(TicketDetailsPageContext)
  const {isTicketEditable, setIsTicketEditable} = useContext(TicketDetailsPageContext)
  const [clickedAction, setClickedAction] = useState<any>(null)
  const {customFieldsList, hasTicketWritePermission} = useContext(TicketDetailsPageContext)
  const customFormFields = dataToFormFields(customFieldsList)
  const [isEditorValid, setIsEditorValid] = useState<boolean>(true)
  const isLoadingTicket = isLoadingTicketDetails && ticketDetails?.length === 0
  const {
    register,
    control,
    reset,
    watch,
    handleSubmit,
    formState: {dirtyFields, errors, isDirty, isValid},
    setError,
  } = useForm<any>({
    defaultValues: defaultProjectTicketValues,
    resolver: yupResolver(TicketDetailsFormSchema),
  })

  useEffect(() => {
    if (!isEmpty(ticketDetails)) {
      setDefaultTicketDetails(ticketDetails, reset)
    }
  }, [ticketDetails])

  const updatedData = getUpdatedData(dirtyFields, watch())

  const onSave = async () => {
    if (!isEmpty(updatedData) && !isLoadingTicketOperation && !isLoadingTicket && isEditorValid) {
      await updateTicket(watch(), dirtyFields, setError)
      reset(watch())
      setIsTicketEditable(false)
    }
  }

  const onCancel = () => {
    setDefaultTicketDetails(ticketDetails, reset)
    setClickedAction('cancel')
    setIsTicketEditable(false)
  }

  useEffect(() => {
    if (
      !isTicketEditable &&
      !isLoadingTicketOperation &&
      !isLoadingTicketDetails &&
      !isEmpty(updatedData)
    ) {
      onSave()
    } else {
      setClickedAction(null)
    }
  })

  return (
    <>
      <div className={`position-relative h-full ${isLoadingTicket ? 'min-h-350px' : ''}`}>
        <div className='row gx-10'>
          <div className='col ticket-detail-maincontent w-100'>
            <div className='ticket-header-sticky pb-10 pt-5 bg-white'>
              <TicketDetailsPageHeader
                register={register}
                onSave={onSave}
                ticketTitle={watch('title')}
                errors={errors}
              />
            </div>
            {(watch()?.description || isTicketEditable || isLoadingTicket) && (
              <TextEditor
                id='ticket-description'
                className={`${!isTicketEditable ? '' : 'mb-10'}`}
                editorClass={!isTicketEditable ? 'border-0' : ''}
                inputClass={isLoadingTicket ? `min-h-350px` : ''}
                registerKey='description'
                control={control}
                defaultValue={ticketDetails?.description}
                isLoadingOperation={isLoadingTicketOperation}
                isLoadingData={isLoadingTicket}
                isReadOnly={!isTicketEditable}
                isClearChanges={clickedAction === 'cancel'}
                setIsValid={setIsEditorValid}
                maxLength={62500}
              />
            )}

            {!isTicketEditable && <CommentSectionNavigation />}
          </div>
          <div className='col ticket-detail-sidebar w-350px mw-350px'>
            <SectionForm
              id='create-ticket-status'
              className='border border rounded-3 p-8 pt-8 pb-4 position-relative bg-light bg-opacity-50'
              commonClassNames='mb-6'
              commonLabelClass='w-100'
              commonInputClass='col'
              register={register}
              control={control}
              errors={errors}
              isDisabled={!hasTicketWritePermission}
              inputs={[
                {
                  id: 'assignee',
                  label: 'Assignee',
                  isReachSelect: true,
                  placeholder: 'Unassigned',
                  isNullable: true,
                  options: projectMembers,
                  labelKey: 'name',
                  valueKey: 'username',
                  registerKey: 'assignedTo',
                  disabledKey: '!is_active',
                  isLoading:
                    isLoadingProjectMembers ||
                    isLoadingTicket ||
                    (isLoadingTicketOperation && dirtyFields?.assignedTo),
                },
                {
                  id: 'kanban-stage',
                  label: 'Kanban Stage',
                  isReachSelect: true,
                  options: projectKanbanStages,
                  registerOptions: {valueAsNumber: true},
                  valueType: 'number',
                  labelKey: 'name',
                  valueKey: 'column_id',
                  registerKey: 'currentColumnId',
                  isLoading:
                    isLoadingProjectKanbanStages ||
                    isLoadingTicket ||
                    (isLoadingTicketOperation && dirtyFields?.currentColumnId),
                },
                {
                  id: 'module',
                  label: 'Module',
                  isReachSelect: true,
                  options: projectModules,
                  registerOptions: {valueAsNumber: true},
                  labelKey: 'name',
                  valueKey: 'module_id',
                  valueType: 'number',
                  registerKey: 'moduleId',
                  isLoading:
                    isLoadingProjectModules ||
                    isLoadingTicket ||
                    (isLoadingTicketOperation && dirtyFields?.moduleId),
                },
                {
                  id: 'status',
                  label: 'Status',
                  isSelect: true,
                  options: projectStatuses,
                  registerOptions: {valueAsNumber: true},
                  labelKey: 'label',
                  valueKey: 'id',
                  registerKey: 'status',
                  isLoading:
                    isLoadingProjectStatuses ||
                    isLoadingTicket ||
                    (isLoadingTicketOperation && dirtyFields?.status),
                },
                {
                  id: 'priority',
                  label: 'Priority',
                  isSelect: true,
                  options: projectPriorities,
                  registerOptions: {valueAsNumber: true},
                  labelKey: 'label',
                  valueKey: 'id',
                  registerKey: 'priority',
                  isLoading:
                    isLoadingProjectPriorities ||
                    isLoadingTicket ||
                    (isLoadingTicketOperation && dirtyFields?.priority),
                },
                {
                  id: 'estimation-time',
                  label: 'Estimation',
                  isInputTime: true,
                  registerKey: 'estimation',
                  isLoading:
                    isLoadingTicket || (isLoadingTicketOperation && dirtyFields?.estimation),
                },
                {
                  id: 'spent-time',
                  label: 'Spent Time',
                  isInputTime: true,
                  registerKey: 'spentTime',
                  isLoading:
                    isLoadingTicket || (isLoadingTicketOperation && dirtyFields?.spentTime),
                  isTempAddition: true,
                },
                {
                  id: 'due-date',
                  label: 'Due Date',
                  isInputDate: true,
                  registerKey: 'dueDate',
                  isNullable: true,
                  isLoading: isLoadingTicket || (isLoadingTicketOperation && dirtyFields?.dueDate),
                },
              ]}
            >
              {customFormFields?.length > 0 && <CustomFields customFormFields={customFormFields} />}
            </SectionForm>
          </div>
        </div>
        {isTicketEditable && (
          <div className='d-flex align-items position-sticky bottom-0 py-5 mt-5 bg-white border-top'>
            <button type='button' className='btn btn-outline bg-white me-4' onClick={onCancel}>
              Cancel
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleSubmit(onSave)}
              disabled={
                !isValid ||
                !isDirty ||
                isLoadingTicketOperation ||
                isLoadingTicketDetails ||
                !isEditorValid
              }
            >
              {isLoadingTicketOperation ? 'Saving...' : 'Save'}
              {isLoadingTicketOperation && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default TicketForm
