import {useContext, useState} from 'react'
import AddBoardColumnModal from '../../modals/AddBoardColumnModal'
import DndTable from '../../../../../components/dndTable/DndTable'
import {BoardSettingsPageContext} from '../../../context'
import ColumnsTableRow from './ColumnsTableRow'
import {boardColumnsTableColumns} from '../../../utils'

export default function ColumnsTable() {
  const [showModal, setShowModal] = useState(false)
  const {
    updateColumnTableSorting,
    boardColumnsList,
    tableStatusDropdownList,
    isLoadingBoardColumnsList,
    hasBoardSettingsPermission,
    projectMappingDetails,
  } = useContext(BoardSettingsPageContext)

  const {columnId} = projectMappingDetails

  const handleUpdatedTableRows = (tableData: any) => {
    const sortOrder = {
      column_ids: tableData.map((item: any) => item.columnId),
    }
    updateColumnTableSorting(sortOrder)
  }

  const statusMappingDropdownList = tableStatusDropdownList?.map((item: any) => ({
    ...item,
    disabled: boardColumnsList?.some((column: any) => column.mappedColumnValue === item.label),
  }))

  return (
    <div className='position-relative'>
      <div className='d-flex justify-content-between align-items-center mb-7'>
        <h4 className='fs-3 fw-bolder mb-0'>Columns</h4>
        {hasBoardSettingsPermission && (
          <div className='d-flex justify-content-end'>
            {!isLoadingBoardColumnsList && (
              <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
                <i className='fa-solid fa-plus'></i>Add Column
              </button>
            )}
            {showModal && (
              <AddBoardColumnModal
                show={showModal}
                isEdit={false}
                onClose={() => setShowModal(false)}
              />
            )}
          </div>
        )}
      </div>

      <DndTable
        id='board-columns-table'
        data={boardColumnsList}
        columns={boardColumnsTableColumns(columnId)}
        uniqueId='columnId'
        isLoading={isLoadingBoardColumnsList}
        onChange={handleUpdatedTableRows}
        hasWritePermission={hasBoardSettingsPermission}
        TableRow={ColumnsTableRow}
        tableRowProps={{statusMappingDropdownList}}
        noDataText={
          <p>
            No columns have been added yet. <br /> Please click on 'Add Column' button to add
            columns.
          </p>
        }
      />
    </div>
  )
}
