import useFilters from '../../../../hook/useFilters'
import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'

export const useGetMembersList = () => {
  const Api = useApi()
  const params = useParams()
  const projectId = params?.['projectId']

  const initialFilters = {
    page: true,
    search: true,
    limit: true,
  }

  const {filters, onSearch, onPageChange} = useFilters(initialFilters)

  const {data: response, isFetching} = Api.useGetQuery(`agile/projects/${projectId}/members`, {
    queryId: 'project-members-list',
    filters: filters,
  })

  return {
    projectMembersList: response?.data?.data || [],
    isLoading: isFetching,
    pagination: response?.meta?.pagination || {},
    onPageChange,
    filters,
    onSearch,
  }
}
