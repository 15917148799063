import {useContext} from 'react'
import {UserContext} from '../../context'
import {useAppSelector} from '../../../../redux/useTypedSelector'

interface UserActionDropdownProps {
  userName: string
  onEdit: any
  handleModel: any
  onResetPassword: any
  id: any
}

export default function UserActionDropdown(props: UserActionDropdownProps) {
  const {onEdit, handleModel, onResetPassword, id, userName} = props
  const {userLogin} = useContext(UserContext)
  const {user} = useAppSelector((state) => state.auth)

  return (
    <div className=''>
      <div className='px-4 py-2' onClick={onEdit}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-4 py-2' onClick={onResetPassword}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Reset Password</label>
        </span>
      </div>
      <div className='px-4 py-2'>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label onClick={handleModel}>Delete</label>
        </span>
      </div>
      {user?.role_id === '66f4f1aacb3b3b19fd774f98' && user?.username !== userName && (
        <div className='px-4 py-2'>
          <span
            onClick={() => {
              userLogin(id)
            }}
            className='text-dark fw-bold text-hover-primary fs-6'
          >
            <label>Login</label>
          </span>
        </div>
      )}
    </div>
  )
}
