import {Pagination} from '../../../utils/pagination'
import ProjectsDefaultColumnsHeader from '../sections/headers/ProjectsDefaultColumnsHeader'
import {ProjectsDefaultColumnsPageContext} from '../context'
import useGetGlobalDefaultColumnsList from '../hooks/defaultColumns/useGetGlobalDefaultColumnsList'
import useGlobalDefaultColumnsOperations from '../hooks/defaultColumns/useGlobalDefaultColumnsOperations'
import DefaultColumnsTable from '../sections/tables/DefaultColumnsTable'
import usePermission from '../../../hook/usePermission'

export default function ProjectsDefaultColumns() {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')
  const {
    globalDefaultColumnsList,
    isLoading: isLoadingGlobalDefaultColumnsList,
    pagination,
    onSearch,
    searchValue,
    onPageChange,
  } = useGetGlobalDefaultColumnsList()
  const {
    createGlobalDefaultColumn,
    updateGlobalDefaultColumn,
    updateGlobalDefaultColumnsTableSorting,
    deleteGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation,
  } = useGlobalDefaultColumnsOperations()

  const contextValue = {
    hasProjectSettingsPermission,
    globalDefaultColumnsList: globalDefaultColumnsList,
    isLoadingGlobalDefaultColumnsList: isLoadingGlobalDefaultColumnsList,
    pagination: pagination,
    onSearch: onSearch,
    searchValue,
    onPageChange: onPageChange,

    createGlobalDefaultColumn: createGlobalDefaultColumn,
    updateGlobalDefaultColumn: updateGlobalDefaultColumn,
    updateGlobalDefaultColumnsTableSorting: updateGlobalDefaultColumnsTableSorting,
    deleteGlobalDefaultColumn: deleteGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation: isLoadingGlobalDefaultColumnOperation,
  }

  return (
    <>
      <ProjectsDefaultColumnsPageContext.Provider value={contextValue}>
        <ProjectsDefaultColumnsHeader />
        <DefaultColumnsTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectsDefaultColumnsPageContext.Provider>
    </>
  )
}
