function RecentlyInactiveCustomerTableRow({row}: any) {
  return (
    <tr>
      <td>
        <div>{row?.['customerName']}</div>
      </td>
      <td>
        <div>{row?.['customerEmail']}</div>
      </td>
      <td>
        <div>{row?.['customerRepresentative']}</div>
      </td>
      <td>
        <div>{row?.['customerGroupName']}</div>
      </td>
      <td>
        <div>{row?.['totalOrders']}</div>
      </td>
      <td>
        <div>{row?.['orderDate']}</div>
      </td>
      <td>
        <div>{row?.['customerDateCreated']}</div>
      </td>
    </tr>
  )
}

export default RecentlyInactiveCustomerTableRow
