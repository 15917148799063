import useApi from '../../../../services/useApi'

const useGetProjectMappingDropdowns = (projectMappingDetails: any) => {
  const Api = useApi()
  const {tableId, columnId} = projectMappingDetails

  // Get project tables dropdown list
  const {data: projectTablesResponse, isFetching: isLoadingProjectTablesDropdownList} =
    Api.useGetQuery(`/agile/projects/project-mapping/tables`)

  // Get table columns dropdown list
  const {data: tableColumnsResponse, isFetching: isLoadingTableColumnsDropdownList} =
    Api.useGetQuery(
      `/agile/projects/dbtable/columns/${tableId}`,
      {},
      {enabled: !!tableId && tableId !== 0}
    )

  // Get table status dropdown list
  const {data: tableStatusResponse, isFetching: isLoadingTableStatusDropdownList} = Api.useGetQuery(
    `/agile/projects/column/mapping/${tableId}/${columnId}`,
    {queryId: 'tableStatusDropdownList'},
    {
      enabled: !!columnId,
    }
  )

  return {
    projectTablesDropdownList: projectTablesResponse?.data,
    isLoadingProjectTablesDropdownList,
    tableColumnsDropdownList: tableColumnsResponse?.data,
    isLoadingTableColumnsDropdownList,
    tableStatusDropdownList: tableStatusResponse?.data,
    isLoadingTableStatusDropdownList,
  }
}

export default useGetProjectMappingDropdowns
