import {useState} from 'react'
import useApi from '../../../../services/useApi'
import {useParams} from 'react-router-dom'

function useModulesOperations() {
  const Api = useApi()
  const {projectId} = useParams()
  const [isLoading, setLoading] = useState(false)
  const [moduleOperationId, setModuleOperationId] = useState<any>(null)

  async function createModule(payload: any, setError: Function) {
    return Api.post(`/agile/projects/${projectId}/modules`, payload, {
      successMessage: 'Module created successfully.',
      setLoading: setLoading,
      refetch: 'board-modules',
      setUseFormError: setError,
    })
  }

  async function updateModule(
    moduleId: string,
    payload: any,
    dirtyFields?: any,
    setError?: Function
  ) {
    return Api.patch(`/agile/projects/${projectId}/modules/${moduleId}`, payload, {
      dirtyFields: dirtyFields,
      successMessage: 'Module updated successfully.',
      setLoading: setLoading,
      refetch: 'board-modules',
      setUseFormError: setError,
      onLoadingStart: () => setModuleOperationId(moduleId),
      onLoadingEnd: () => setModuleOperationId(null),
    })
  }

  async function updateModuleTableSorting(sortOrder: any) {
    return Api.patch(`/agile/projects/${projectId}/modules`, sortOrder)
  }

  async function deleteModule(moduleId: string) {
    return Api.delete(
      `/agile/projects/${projectId}/modules/${moduleId}`,
      {},
      {
        successMessage: 'Module deleted successfully.',
        setLoading: setLoading,
        refetch: 'board-modules',
      }
    )
  }

  return {
    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    moduleOperationId,
    isLoading,
  }
}

export default useModulesOperations
