import {useContext, useEffect, useState} from 'react'
import Loading from '../../../loading'
import AnonymousCustomerTableRow from './AnonymousCustomerTableRow'
import {IdleCustomersDashboardContext} from '../../context'
import ModalCustomerIPDetails from '../../components/ModalCustomerIPDetails'
import NoDataFound from '../../../../components/NoDataFound'
import {Link, useLocation} from 'react-router-dom'
import {toggleSortingOrder} from '../../../../utils/common'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'
import moment from 'moment'
import {SampleCSVDownload} from '../../../../components/SampleCSVDownload'
import Import from '../../../../images/Import.png'
import {dataToAnonymousCustomerCSV} from '../../utils'
import { useGetDataForCSV } from '../../../../hook/useGetDataForCSV'

function AnonymouslyActiveCustomerListing() {
  const location = useLocation()
  const {fetchDataForCSV, isLoading} = useGetDataForCSV()
  const isTableView = location?.pathname?.split('/')?.length > 3
  const {
    anonymousCustomers,
    anonymousCustomersLoading,
    onAnonymouslyActiveCustomerClickSorting: onClickSorting,
    AnonymouslyActiveCustomerFilters: filters,
  } = useContext<any>(IdleCustomersDashboardContext)

  const [openModal, setOpenModal] = useState(false)
  const [customerID, setCustomerID] = useState(null)

  const onClose = () => {
    setOpenModal(false)
  }

  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')

  useEffect(() => {
    if (filters) {
      const array = filters['sort_by']?.split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [filters])

  const handleDownLoadCSV = async () => {
    const formattedDateTime = moment().format('D_MMM_HH_mm').toLowerCase()
    const res: any = await fetchDataForCSV('/analytics/customers/active/no-orders')
    if (res?.status === 200) {
      const data = dataToAnonymousCustomerCSV(res?.data)
      if (data?.length) {
        SampleCSVDownload(data || [], `Anonymous_Activity_${formattedDateTime}`)
      }
    }
  }

  return (
    <div className={`${isTableView ? 'pt-15' : 'card-body  card card-flush  border p-7'}`}>
      <div className='d-flex align-items-center justify-content-between'>
        <h2 className='mb-10'>
          {!isTableView && 'Anonymous Activity (No Orders In Last 30 Days)'}
        </h2>
        <button
          className='btn btn-outline mb-10'
          onClick={handleDownLoadCSV}
          disabled={isLoading || !anonymousCustomers?.length}
        >
          <div className='d-flex align-items-center'>
            <img src={Import} alt='Import File' className='me-2 import-icon' />
            <div className='d-flex align-items-center'>
              {isLoading ? 'Downloading...' : 'Export CSV'}
              {isLoading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </div>
          </div>
        </button>
      </div>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                <th className='min-w-175px'>{'CUSTOMER NAME'}</th>
                <th className='min-w-250px'>{'EMAIL'}</th>
                <th className='min-w-200px'>{'CUSTOMER REP'}</th>
                <th className='min-w-250px'>{'CUSTOMER GROUP'}</th>
                {isTableView ? (
                  <>
                    <th
                      className='min-w-80px'
                      onClick={() => onClickSorting('total_orders', toggleSortingOrder(direction))}
                    >
                      <div className='d-flex align-items-center cursor-pointer'>
                        <span className='me-2'>ORDERS</span>
                        {direction === 'asc' && sortBy === 'total_orders' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'total_orders' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                    <th
                      className='min-w-200px'
                      onClick={() =>
                        onClickSorting('last_order_date_from_orders', toggleSortingOrder(direction))
                      }
                    >
                      <div className='d-flex align-items-center cursor-pointer'>
                        <span className='me-2'>LAST ORDER DATE</span>
                        {direction === 'asc' && sortBy === 'last_order_date_from_orders' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'last_order_date_from_orders' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                  </>
                ) : (
                  <>
                    <th className='min-w-80px'>{'ORDERS'}</th>
                    <th className='min-w-200px'>{'LAST ORDER DATE'}</th>
                  </>
                )}

                <th className='min-w-80px text-center'>{'SESSIONS'}</th>
                <th className='min-w-100px text-center'>{'UNIQUE IPs'}</th>
              </tr>
            </thead>
            <tbody>
              {anonymousCustomers && anonymousCustomers.length > 0
                ? anonymousCustomers.map((row: any) => (
                    <AnonymousCustomerTableRow
                      key={row['customerID']}
                      row={row}
                      setOpenModal={setOpenModal}
                      setCustomerID={setCustomerID}
                    />
                  ))
                : !anonymousCustomersLoading && <NoDataFound colspan={6} />}
            </tbody>
          </table>

          {customerID ? (
            <ModalCustomerIPDetails show={openModal} onClose={onClose} customerID={customerID} />
          ) : null}
          {anonymousCustomersLoading && <Loading />}
        </div>
      </div>

      {!isTableView && (
        <>
          <div className='mt-2'>
            <Link
              to='/analytics/idle-customers/anonymously-active-customers'
              className='btn btn-link btn-color-black btn-active-color-primary text-primary text-hover-dark text-decoration-underline'
            >
              View More
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export default AnonymouslyActiveCustomerListing
