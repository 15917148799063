import {useForm} from 'react-hook-form'
import useGetGlobalCustomFieldsList from '../../../projectsSettings/hooks/customFields/useGetGlobalCustomFieldsList'
import {BoardSettingsPageContext} from '../../context'
import {useContext} from 'react'
import {AddBoardCustomFieldsModalTypes} from '../../interfaces'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SectionForm} from '../../../../components/SectionForm'
import {yupResolver} from '@hookform/resolvers/yup'
import {AddBoardCustomFieldsSchema} from '../../validations'
import {addBoardCustomFieldsData} from '../../parsings'

const AddBoardCustomFieldsModal = ({
  show,
  onClose,
  isEdit,
  customFieldData,
}: AddBoardCustomFieldsModalTypes) => {
  const {globalCustomFieldsList} = useGetGlobalCustomFieldsList()
  const {
    createCustomField,
    updateCustomField,
    isLoadingBoardCustomFieldsList,
    isLoadingCustomFieldOperation,
  } = useContext(BoardSettingsPageContext)
  const {
    handleSubmit,
    control,
    register,
    formState: {dirtyFields, errors, isDirty, isValid},
    setError,
  } = useForm<any>({
    defaultValues: addBoardCustomFieldsData(customFieldData, isEdit),
    resolver: isEdit ? yupResolver(AddBoardCustomFieldsSchema) : undefined,
  })
  const onSubmit = (data: any) => {
    const {customfield_meta_ids, name} = data
    if (!isEdit) {
      const createPayload = {
        customfield_meta_ids: customfield_meta_ids,
      }
      createCustomField(createPayload, setError)
    } else {
      const editPayload = {
        name: name,
      }
      updateCustomField(customFieldData?.id, editPayload, dirtyFields, setError)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Field`}
      onAction={handleSubmit(onSubmit)}
      actionName={`${isEdit ? 'Update' : 'Add'}`}
      actionBtnClass='btn-primary'
      isDataLoading={isLoadingBoardCustomFieldsList}
      isOperationLoading={isLoadingCustomFieldOperation}
      isDisabled={
        !isValid || !isDirty || isLoadingCustomFieldOperation || isLoadingBoardCustomFieldsList
      }
      body={
        <SectionForm
          id='add-project-custom-fields'
          register={register}
          control={control}
          commonClassNames='mb-8'
          errors={errors}
          inputs={[
            {
              id: 'project-custom-field-name',
              label: 'Field Name',
              registerKey: 'name',
              required: true,
              placeholder: 'Enter field name',
              show: isEdit,
              maxLength: 20,
            },
            {
              id: 'project-custom-fields',
              isMultiSelect: true,
              label: 'Custom Field Type',
              required: true,
              registerKey: 'customfield_meta_ids',
              options: globalCustomFieldsList,
              labelKey: 'custom_field_name',
              valueKey: 'custom_field_id',
              valueType: 'number',
              show: !isEdit,
            },
          ]}
        />
      }
    />
  )
}

export default AddBoardCustomFieldsModal
