import {useContext, useEffect, useState} from 'react'
import RecentlyInactiveCustomerTableRow from './RecentlyInactiveCustomerTableRow'
import {IdleCustomersDashboardContext} from '../../context'
import NoDataFound from '../../../../components/NoDataFound'
import {Link, useLocation} from 'react-router-dom'
import Loading from '../../../loading'
import {toggleSortingOrder} from '../../../../utils/common'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'
import {useGetDataForCSV} from '../../../../hook/useGetDataForCSV'
import {SampleCSVDownload} from '../../../../components/SampleCSVDownload'
import moment from 'moment'
import Import from '../../../../images/Import.png'
import {dataToRecentlyInactiveCustomerCSV} from '../../utils'

function RecentlyInactiveCustomerListing() {
  const location = useLocation()
  const {fetchDataForCSV, isLoading} = useGetDataForCSV()
  const isTableView = location?.pathname?.split('/')?.length > 3
  const {
    recentlyInactiveCustomers,
    recentlyInactiveCustomersLoading,
    onRecentlyInactiveCustomerClickSorting: onClickSorting,
    RecentlyInactiveCustomerFilters: filters,
  } = useContext<any>(IdleCustomersDashboardContext)
  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')

  useEffect(() => {
    if (filters) {
      const array = filters['sort_by']?.split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [filters])

  const handleDownLoadCSV = async () => {
    const formattedDateTime = moment().format('D_MMM_HH_mm').toLowerCase()
    const res: any = await fetchDataForCSV('/analytics/customers/no-recent-orders')
    if (res?.status === 200) {
      const data = dataToRecentlyInactiveCustomerCSV(res?.data)
      if (data?.length) {
        SampleCSVDownload(data || [], `Idle_User_${formattedDateTime}`)
      }
    }
  }

  return (
    <div className={`${isTableView ? 'pt-15' : 'card-body  card card-flush  border p-7 mb-10'}`}>
      <div className='d-flex align-items-center justify-content-between'>
        <h2 className='mb-10'>{!isTableView && 'Idle User (Last Order Placed Before 6 Months)'}</h2>
        <button
          className='btn btn-outline mb-10'
          onClick={handleDownLoadCSV}
          disabled={isLoading || !recentlyInactiveCustomers?.length}
        >
          <div className='d-flex align-items-center'>
            <img src={Import} alt='Import File' className='me-2 import-icon' />
            <div className='d-flex align-items-center'>
              {isLoading ? 'Downloading...' : 'Export CSV'}
              {isLoading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </div>
          </div>
        </button>
      </div>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                <th className='min-w-175px'>{'CUSTOMER NAME'}</th>
                <th className='min-w-250px'>{'EMAIL'}</th>
                <th className='min-w-200px'>{'CUSTOMER REP'}</th>
                <th className='min-w-250px'>{'CUSTOMER GROUP'}</th>
                {isTableView ? (
                  <>
                    <th
                      className='min-w-80px'
                      onClick={() => onClickSorting('total_orders', toggleSortingOrder(direction))}
                    >
                      <div className='d-flex align-items-center cursor-pointer'>
                        <span className='me-2'>ORDERS</span>
                        {direction === 'asc' && sortBy === 'total_orders' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'total_orders' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                    <th
                      className='min-w-200px'
                      onClick={() =>
                        onClickSorting('order_created_date_time', toggleSortingOrder(direction))
                      }
                    >
                      <div className='d-flex align-items-center cursor-pointer'>
                        <span className='me-2'>ORDER DATE</span>
                        {direction === 'asc' && sortBy === 'order_created_date_time' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'order_created_date_time' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                  </>
                ) : (
                  <>
                    <th className='min-w-80px'>{'ORDERS'}</th>

                    <th className='min-w-200px'>{'ORDER DATE'}</th>
                  </>
                )}
                <th className='min-w-200px'>{'CREATED DATE'}</th>
              </tr>
            </thead>
            <tbody>
              {recentlyInactiveCustomers && recentlyInactiveCustomers.length > 0
                ? recentlyInactiveCustomers?.map((row: any) => (
                    <RecentlyInactiveCustomerTableRow key={row['customerID']} row={row} />
                  ))
                : !recentlyInactiveCustomersLoading && <NoDataFound colspan={6} />}
            </tbody>
          </table>
          {recentlyInactiveCustomersLoading && <Loading />}
        </div>
      </div>

      {!isTableView && (
        <>
          <div className='mt-2'>
            <Link
              to='/analytics/idle-customers/recently-inactive-customers'
              className='btn btn-link btn-color-black btn-active-color-primary text-primary text-hover-dark text-decoration-underline'
            >
              View More
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export default RecentlyInactiveCustomerListing
