import {convertDateFormatForCsv, formatName} from '../../../utils/common'
import {formatDate} from '../../../utils/date'

/**
 * Converts data to an inactive customer listing format.
 * @param data - The data to be converted.
 * @returns An array of inactive customer listings.
 */
export const dataToInactiveCustomerListing = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      customerID: item?.customer_id || '',
      customerName: formatName(item?.first_name, item?.last_name),
      dateCreated: formatDate(item?.date_created, false) || 'N/A',
      customerEmail: item?.email || 'N/A',
      customerPhone: item?.phone || 'N/A',
      customerGroupName: item?.customer_group_name || '-',
      customerRepresentative: item?.rep_name || '-',
    })
  })

  return results
}

/**
 * Converts data to a recently inactive customer listing format.
 * @param data - The data to be converted.
 * @returns An array of recently inactive customer listings.
 */
export const dataToRecentlyInactiveCustomerListing = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      customerID: item?.customer_id || '',
      customerName: formatName(item.first_name, item.last_name),
      customerDateCreated: formatDate(item?.customer_date_created, false) || 'N/A',
      customerEmail: item?.email || 'N/A',
      customerPhone: item?.phone || 'N/A',
      customerGroupName: item?.customer_group_name || '-',
      totalOrders: item?.total_orders || 0,
      customerRepresentative: item?.rep_name || '-',
      orderDate: formatDate(item?.order_created_date_time, false) || 'N/A',
    })
  })

  return results
}

/**
 * Converts data to an anonymous customer listing format.
 * @param data - The data to be converted.
 * @returns An array of anonymous customer listings.
 */
export const dataToAnonymousCustomerListing = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      customerID: item?.customer_id || '',
      customerName: formatName(item.first_name, item.last_name),
      dateCreated: item?.date_created || 'N/A',
      customerEmail: item?.email || 'N/A',
      customerPhone: item?.phone || 'N/A',
      customerGroupName: item?.customer_group_name || '-',
      totalSessions: item?.total_sessions || 0,
      totalUniqueIPs: item?.total_unique_ips || 0,
      lastOrderDate: formatDate(item?.last_order_date, false) || '-',
      totalOrders: item?.total_orders || 0,
      customerRepresentative: item?.rep_name || '-',
    })
  })

  return results
}

/**
 * Converts data to customer IP information objects.
 * @param data - The data to be converted.
 * @returns An array of customer IP information objects.
 */
export const dataToCustomerIPInformationList = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      ip: item?.ip_address || '-',
      city: item?.city || '-',
      pages: item?.pages || '-',
      dateTime: formatDate(item?.login_timestamp, false) || '',
    })
  })

  return results
}

export const dataToAnonymousCustomerCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    const transformedItem = {
      'CUSTOMER NAME': formatName(item.first_name, item.last_name),
      EMAIL: item?.email || 'N/A',
      'CUSTOMER REPRESENTATIVE': item?.rep_name || '-',
      'CUSTOMER GROUP': item?.customer_group_name || '-',
      ORDERS: item?.total_orders || 0,
      'LAST ORDER DATE': convertDateFormatForCsv(item?.last_order_date) || '-',
      SESSIONS: item?.total_sessions || 0,
      'UNIQUE IPS': item?.total_unique_ips || 0,
    }

    results.push(transformedItem)
  })

  return results
}

export const dataToRecentlyInactiveCustomerCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    const transformedItem = {
      'CUSTOMER NAME': formatName(item.first_name, item.last_name),
      EMAIL: item?.email || 'N/A',
      'CUSTOMER REPRESENTATIVE': item?.rep_name || '-',
      'CUSTOMER GROUP': item?.customer_group_name || '-',
      ORDERS: item?.total_orders || 0,
      'ORDER DATE': convertDateFormatForCsv(item?.order_created_date_time) || 'N/A',
    }

    results.push(transformedItem)
  })

  return results
}

export const dataToInactiveCustomerCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      'CUSTOMER NAME': formatName(item?.first_name, item?.last_name),
      EMAIL: item?.email || 'N/A',
      'CUSTOMER REPRESENTATIVE': item?.rep_name || '-',
      'CUSTOMER GROUP': item?.customer_group_name || '-',
      'CREATED DATE': convertDateFormatForCsv(item?.date_created) || 'N/A',
    })
  })

  return results
}
