import Loading from '../../../loading'
import {useContext, useEffect, useState} from 'react'
import {ExecutedProductRulesPageContext} from '../../contexts'
// import usePermission from '../../../../hook/usePermission'
import {toggleSortingOrder} from '../../../../utils/common'
import ExecutedRuleTableRow from './ExecutedRuleTableRow'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'
import NoDataFound from '../../../../components/NoDataFound'

function ExecutedRuleTable() {
  const {executedRules, onSortingChange, filters, isFetching, loading}: any = useContext(
    ExecutedProductRulesPageContext
  )
  const [direction, setDirection] = useState('')
  useEffect(() => {
    if (filters) {
      if (filters['sort_order'] === '-1') {
        setDirection('desc')
      } else {
        setDirection('asc')
      }
    }
  }, [filters])

  return (
    <>
      <div className='card-body p-0'>
        <div className='table-responsive position-relative'>
          <div className='table-loader-wrapper'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'>
              <thead className='table-row-bordered'>
                <tr className='fs-6 fw-semibold text-muted text-uppercase'>
                  <th className='min-w-300px'>Product Name</th>
                  <th className='min-w-150px'>Product Id</th>
                  <th
                    className='w-200px'
                    onClick={() => onSortingChange('executed_date', toggleSortingOrder(direction))}
                  >
                    <div className='d-flex align-items-center'>
                      <span className='me-2'>Executed date</span>

                      {direction === 'asc' && filters['sort_by'] === 'executed_date' ? (
                        <i className='bi bi-caret-up-fill fs-8'></i>
                      ) : direction === 'desc' && filters['sort_by'] === 'executed_date' ? (
                        <i className='bi bi-caret-down-fill fs-8'></i>
                      ) : (
                        <RenderBothArrow />
                      )}
                    </div>
                  </th>
                  <th className='w-100px'>Executed</th>
                  {/* {hasPermission('cms_webpages', 'write') ? <th className='min-w-120px'>Action</th> : null} */}
                </tr>
              </thead>
              <tbody>
                {executedRules && executedRules.length > 0
                  ? executedRules.map((row: any) => (
                      <ExecutedRuleTableRow row={row} key={row['id']} />
                    ))
                  : !(isFetching || loading) && <NoDataFound colspan={4} />}
              </tbody>
            </table>
            {isFetching || loading ? <Loading /> : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default ExecutedRuleTable
