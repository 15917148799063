import {useFieldArray, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useContext} from 'react'
import {ProjectsCustomFieldsPageContext} from '../../context'
import {customFieldsSchema} from '../../validations'
import {SectionForm} from '../../../../components/SectionForm'
import {GlobalCustomFieldsModalTypes} from '../../interfaces'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {GlobalCustomFieldsDefaultValues} from '../../parsings'
import {customFieldTypesDropdownList} from '../../utils'
import {InputText} from '../../../../components/InputText'

const GlobalCustomFieldsModal = ({
  show,
  onClose,
  isEdit,
  customFieldData,
}: GlobalCustomFieldsModalTypes) => {
  const {
    createGlobalCustomField,
    updateGlobalCustomField,
    isLoadingGlobalCustomFieldOperation,
    isLoadingGlobalCustomFieldList,
  } = useContext(ProjectsCustomFieldsPageContext)

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {dirtyFields, errors, isDirty, isValid},
    setError,
  }: any = useForm<any>({
    defaultValues: GlobalCustomFieldsDefaultValues(customFieldData, isEdit),
    resolver: yupResolver(customFieldsSchema),
    mode: 'onChange',
  })

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'group_values',
  })

  const handleSelectChange = (e: any) => {
    const selectedValue = e.target.value
    if (selectedValue === 'group' || selectedValue === 'multi-group') {
      append({value: ''})
    } else {
      setValue('group_values', [])
    }
  }

  const onSubmit = (data: any) => {
    const isMultiselectGroup = data.datatype === 'group' || data.datatype === 'multi-group'
    const {is_multiselect_group, group_values, ...remainingData} = data

    const formattedData = {
      ...remainingData,
      is_multiselect_group: isMultiselectGroup,
      ...(isMultiselectGroup && {
        group_values: data.group_values.map((field: any) => field.value).join(','),
      }),
    }

    if (isEdit) {
      updateGlobalCustomField(
        customFieldData?.custom_field_id,
        formattedData,
        dirtyFields,
        setError
      )
    } else {
      createGlobalCustomField(formattedData, setError)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Field`}
      onAction={handleSubmit(onSubmit)}
      isDisabled={
        !isDirty ||
        !isValid ||
        isLoadingGlobalCustomFieldOperation ||
        isLoadingGlobalCustomFieldList
      }
      isOperationLoading={isLoadingGlobalCustomFieldOperation}
      isDataLoading={isLoadingGlobalCustomFieldList}
      actionName={customFieldData ? 'Update' : 'Create'}
      actionBtnClass='btn-primary'
      body={
        <>
          <SectionForm
            id='add-global-projects-custom-fields'
            register={register}
            control={control}
            errors={errors}
            commonClassNames='mb-8'
            inputs={[
              {
                id: 'global-custom-field-name',
                label: 'Field Name',
                placeholder: 'Enter field name',
                maxLength: 20,
                registerKey: 'name',
                required: true,
              },
              {
                id: 'global-custom-field-type',
                label: 'Field Type',
                isSelect: true,
                options: customFieldTypesDropdownList,
                placeholder: 'Select field type',
                disabled: isEdit,
                registerKey: 'datatype',
                required: true,
                onChange: handleSelectChange,
              },
            ]}
          />
          <div className='mb-8'>
            {(watch('datatype') === 'group' || watch('datatype') === 'multi-group') && (
              <>
                <div>
                  <label className='form-label required mb-5'>Options</label>
                  {fields.map((field, index) => (
                    <div key={field.id} className='d-flex align-items-center gap-3 mb-8'>
                      <InputText
                        id={`option-${index}`}
                        register={register(`group_values.${index}.value`)}
                        placeholder={`option ${index + 1}`}
                        className='w-100'
                        inputClass='bg-light'
                        error={errors?.group_values?.[index]?.value}
                      />

                      {fields?.length > 1 && (
                        <button type='button' onClick={() => remove(index)} className='btn p-0 '>
                          <span className='fa-solid fa-circle-minus text-danger'></span>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <button
                  type='button'
                  onClick={() => append({value: ''})}
                  className='btn btn-primary'
                >
                  + Add Option
                </button>
              </>
            )}
          </div>
        </>
      }
    />
  )
}

export default GlobalCustomFieldsModal
