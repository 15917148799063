import React, {useEffect, useContext, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {KTSVG} from '../../../../_metronic/helpers'
import {UserContext} from '../../context'
import Select from '../../../../components/Select'
import {dataToRolesOptions, STATUS_OPTIONS} from '../../utils'
import useGetRoles from '../../hooks/useGetRoles'
import {getUserDetails} from '../../services'
import {addUserValidationSchema as addSchema} from '../../validations'
import { editUserValidationSchema as editSchema } from '../../validations'

const defaultValues = {
  name: '',
  username: '',
  password: '',
  confirm_password: '',
  role_id: '',
  status: '',
}

function UserForm({onClose, editId}: any) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
    reset,
    control,
  } = useForm({
    defaultValues,
    resolver: yupResolver(editId ? editSchema : addSchema),
  })

  const {addUser, updateUser, isLoading} = useContext(UserContext)
  const [lastUserName, setLastUserName] = useState('')

  const {roles} = useGetRoles()

  const ROLE_OPTIONS = dataToRolesOptions(roles)

  const onSubmit = async (data: any) => {    
    if (editId) {
      data['lastusername'] = lastUserName 
      delete data["confirm_password"]
      delete data["password"]        
      await updateUser(data, editId)
    } else {
      await addUser(data)      
    }
    onCancle()
  }

  const onCancle = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    if (editId) {
      const reFillDetails = async () => {
        const userDetails = await getUserDetails(`${editId}`)

        setLastUserName(userDetails['username'])

        if (userDetails) {
          Object.keys(userDetails).forEach((key: any) => {
            if (key === 'role_id') {
              setValue('role_id', userDetails['role'])
            }
            setValue(key, userDetails[key])
          })
        }
      }
      reFillDetails()
    }
  }, [editId, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='modal-header'>
        <h4 className='modal-title'>{editId ? 'Update' : 'Add'} User</h4>

        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onCancle}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
        </div>
      </div>

      <div className='modal-body'>
        {/* {!editId ? ( */}
        <div className='mb-8'>
            <label className='form-label required form-label'>Full Name</label>
            <input type='text' className={`form-control ${errors?.name?.message ? 'is-invalid' : ''}`} {...register('name')} />
            <div className='form-error invalid-feedback'>{errors.name?.message}</div>
          </div>
        {/* ) : null} */}

        {/* {!editId ? ( */}
        <div className='mb-8'>
          <label className='form-label required form-label'>Email</label>
          <input
            type='text'
            className={`form-control ${errors?.username?.message ? 'is-invalid' : ''}`}
            id='email'
            {...register('username')}
          />
          <div className='form-error invalid-feedback'>{errors.username?.message}</div>
        </div>
        {/* ) : null} */}

        {!editId ? (
        <div className='mb-8'>
          <label className='form-label required form-label'>Password</label>
          <input
            type='password'
            className={`form-control ${errors?.password?.message ? 'is-invalid' : ''}`}
            id='password'
            {...register('password')}
          />
          <div className='form-error invalid-feedback'>{errors.password?.message}</div>
        </div>
         ) : null}

        {!editId ? (
        <div className='mb-8'>
          <label className='form-label required form-label'>Confirm Password</label>
          <input
            type='password'
            className={`form-control ${errors?.confirm_password?.message ? 'is-invalid' : ''}`}
            id='confirm-password'
            {...register('confirm_password')}
          />
          <div className='form-error invalid-feedback'>{errors.confirm_password?.message}</div>
        </div>
         ) : null}

        <div className='mb-8'>
          <Controller
            name='role_id'
            control={control}
            render={({field: {name, value, onChange}}) => (
              <Select
                label='Role'
                name={name}
                isRequired={true}
                options={ROLE_OPTIONS}
                errors={errors}
                value={value}
                onChange={(e: any) => {
                  setValue('role_id', e.target.value)
                }}
              />
            )}
          />
        </div>

        <div className=''>
          <Controller
            name='status'
            control={control}
            render={({field: {name, value, onChange}}) => (
              <Select
                label='Status'
                name={name}
                isRequired={true}
                options={STATUS_OPTIONS}
                errors={errors}
                value={value}
                onChange={(e: any) => {
                  setValue('status', e.target.value)
                }}
              />
            )}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-outline btn-active-light-primary' onClick={onClose}>
          Cancel
        </button>
        <button type='submit' className='btn btn-primary'>
          {!isLoading ? <span className='indicator-label'>Save</span> : null}

          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export default UserForm
