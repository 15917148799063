import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'
import {useState} from 'react'

export default function useTicketOperations() {
  const Api = useApi()
  const {projectId, cardId, projectName}: any = useParams<any>()
  const [isLoading, setLoading] = useState(false)

  async function updateTicket(data: any, dirtyFields: any, setError: Function) {
    return await Api.patch(`/agile/projects/${projectId}/cards/${cardId}`, data, {
      dirtyFields: dirtyFields,
      setLoading: setLoading,
      refetch: 'project-ticket-details',
      setUseFormError: setError,
    })
  }

  async function deleteTicket() {
    return await Api.delete(
      `/agile/projects/${projectId}/cards/${cardId}`,
      {},
      {
        setLoading: setLoading,
        successMessage: 'Ticket deleted successfully',
        navigate: `/projects/active/${projectName}/${projectId}/agile-board`,
      }
    )
  }

  return {
    updateTicket,
    deleteTicket,
    isLoading,
  }
}
