import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'

const initialFilters = {
  search: true,
  page: true,
  limit: 10,
}

const useGetGlobalDefaultColumnsList = () => {
  const Api = useApi()
  const {filters, onSearch, onPageChange, searchValue} = useFilters(initialFilters)

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/default/columns`, {
    queryId: 'project-default-columns',
    filters: filters,
  })

  return {
    globalDefaultColumnsList: response?.data || [],
    isLoading: isFetching,
    pagination: response?.pagination || {},
    onSearch,
    searchValue,
    onPageChange,
  }
}

export default useGetGlobalDefaultColumnsList
