import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import AddBoardCustomFieldsModal from '../modals/AddBoardCustomFieldsModal'
import BoardCustomFieldsActionDropdown from '../actionDropdowns/BoardCustomFieldsActionDropdown'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {BoardSettingsPageContext} from '../../context'

const CustomFieldActionsOverlay = ({customFieldData}: any) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const {
    deleteCustomField,
    isLoadingCustomFieldOperation,
    isLoadingBoardCustomFieldsList,
    projectName,
  } = useContext(BoardSettingsPageContext)

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <BoardCustomFieldsActionDropdown
            setShowModal={setShowModal}
            setDeleteModal={setDeleteModal}
            customFieldData={customFieldData}
          />
        }
      />

      {showModal && (
        <AddBoardCustomFieldsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          isEdit={true}
          customFieldData={customFieldData}
        />
      )}

      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setDeleteModal(false)}
        onAction={() => deleteCustomField(customFieldData?.id)}
        isOperationLoading={isLoadingCustomFieldOperation}
        isDataLoading={isLoadingBoardCustomFieldsList}
        body={
          <p>
            Do you really want to remove '<strong>{customFieldData?.name}</strong>' field from '
            <strong>{projectName}</strong>' project?
          </p>
        }
      />
    </>
  )
}

export default CustomFieldActionsOverlay
