import {useContext} from 'react'
import Loading from '../../../loading'
import DndTable from '../../../../components/dndTable/DndTable'
import {ProjectsCustomFieldsPageContext} from '../../context'
import GlobalProjectsCustomFieldsTableRow from './GlobalProjectsCustomFieldsTableRow'
import {globalCustomFieldsTableColumns} from '../../utils'

export default function GlobalProjectsCustomFieldsTable() {
  const {
    updateGlobalCustomFieldTableSorting,
    globalCustomFieldsList,
    isLoadingGlobalCustomFieldList,
    hasProjectSettingsPermission,
    searchValue,
  } = useContext(ProjectsCustomFieldsPageContext)

  const onTableChange = (tableData: any) => {
    const customFieldsTableRowOrder = {
      custom_field_ids: tableData.map((item: any) => item.custom_field_id),
    }
    updateGlobalCustomFieldTableSorting(customFieldsTableRowOrder)
  }

  return (
    <div className='position-relative'>
      {isLoadingGlobalCustomFieldList && <Loading />}
      <DndTable
        id='global-custom-fields-table'
        data={globalCustomFieldsList}
        columns={globalCustomFieldsTableColumns}
        uniqueId='custom_field_id'
        TableRow={GlobalProjectsCustomFieldsTableRow}
        isLoading={isLoadingGlobalCustomFieldList}
        onChange={onTableChange}
        hasWritePermission={hasProjectSettingsPermission}
        searchValue={searchValue}
        noDataText={
          <p>
            No custom fields have been added yet. <br /> Please click on 'Add Field' button to add
            custom fields.
          </p>
        }
      />
    </div>
  )
}
