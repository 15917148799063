import {Link, useLocation, useParams} from 'react-router-dom'
import usePermission from '../../../hook/usePermission'
import {formatForLink} from '../../../utils/string'

function ProjectPageNavigation() {
  const location = useLocation()
  const {hasPermission} = usePermission()
  const {projectId, projectName: projectTitle, projectStatus}: any = useParams<any>()
  const projectName = formatForLink(projectTitle)

  return (
    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>
      <li className='nav-item'>
        <Link
          className={
            `nav-link text-active-primary me-6 ` +
            (location.pathname ===
              `/projects/${projectStatus}/${projectName}/${projectId}/agile-board` && 'active')
          }
          to={`/projects/${projectStatus}/${projectName}/${projectId}/agile-board`}
        >
          Agile Board
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          className={
            `nav-link text-active-primary me-6 ` +
            (location.pathname ===
              `/projects/${projectStatus}/${projectName}/${projectId}/members` && 'active')
          }
          to={`/projects/${projectStatus}/${projectName}/${projectId}/members`}
        >
          Members
        </Link>
      </li>
      {hasPermission('projects_all projects', 'write') && (
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname ===
                `/projects/${projectStatus}/${projectName}/${projectId}/board-setting` && 'active')
            }
            to={`/projects/${projectStatus}/${projectName}/${projectId}/board-setting`}
          >
            Board Setting
          </Link>
        </li>
      )}
    </ul>
  )
}

export default ProjectPageNavigation
