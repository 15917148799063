import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'

const useGetProjectMembers = () => {
  const Api = useApi()
  const {projectId} = useParams()
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/members`)

  return {
    projectMembers: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetProjectMembers
