/* eslint-disable */
import './EditorComponent.css'
import {useEffect, useRef, useState} from 'react'
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin'
import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin'
import {ClickableLinkPlugin} from '@lexical/react/LexicalClickableLinkPlugin'
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary'
import {HashtagPlugin} from '@lexical/react/LexicalHashtagPlugin'
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin'
import {HorizontalRulePlugin} from '@lexical/react/LexicalHorizontalRulePlugin'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin'
import {TablePlugin} from '@lexical/react/LexicalTablePlugin'
import {useLexicalEditable} from '@lexical/react/useLexicalEditable'
import {useSettings} from '../context/SettingsContext'
import {HistoryContext, useHistoryContext} from '../context/HistoryContext'
import ActionsPlugin from '../plugins/ActionsPlugin'
import AutocompletePlugin from '../plugins/AutocompletePlugin'
import AutoEmbedPlugin from '../plugins/AutoEmbedPlugin'
import AutoLinkPlugin from '../plugins/AutoLinkPlugin'
import CodeActionMenuPlugin from '../plugins/CodeActionMenuPlugin'
import CodeHighlightPlugin from '../plugins/CodeHighlightPlugin'
import CollapsiblePlugin from '../plugins/CollapsiblePlugin'
import CommentPlugin from '../plugins/CommentPlugin'
import ComponentPickerPlugin from '../plugins/ComponentPickerPlugin'
import ContextMenuPlugin from '../plugins/ContextMenuPlugin'
import DragDropPaste from '../plugins/DragDropPastePlugin'
import DraggableBlockPlugin from '../plugins/DraggableBlockPlugin'
import EmojiPickerPlugin from '../plugins/EmojiPickerPlugin'
import FigmaPlugin from '../plugins/FigmaPlugin'
import FloatingLinkEditorPlugin from '../plugins/FloatingLinkEditorPlugin'
import FloatingTextFormatToolbarPlugin from '../plugins/FloatingTextFormatToolbarPlugin'
import ImagesPlugin from '../plugins/ImagesPlugin'
import InlineImagePlugin from '../plugins/InlineImagePlugin'
import KeywordsPlugin from '../plugins/KeywordsPlugin'
import {LayoutPlugin} from '../plugins/LayoutPlugin/LayoutPlugin'
import LinkPlugin from '../plugins/LinkPlugin'
import ListMaxIndentLevelPlugin from '../plugins/ListMaxIndentLevelPlugin'
import MarkdownShortcutPlugin from '../plugins/MarkdownPlugin'
import {MaxLengthPlugin} from '../plugins/MaxLengthPlugin'
import MentionsPlugin from '../plugins/MentionsPlugin'
import PageBreakPlugin from '../plugins/PageBreakPlugin'
import PollPlugin from '../plugins/PollPlugin'
import TabFocusPlugin from '../plugins/TabFocusPlugin'
import TableCellActionMenuPlugin from '../plugins/TablePlugin/TableActionMenuPlugin'
import TableCellResizer from '../plugins/TablePlugin/TableCellResizer'
import TableHoverActionsPlugin from '../plugins/TablePlugin/TableHoverActionsPlugin'
import TableOfContentsPlugin from '../plugins/TablePlugin/TableOfContentsPlugin'
import ToolbarPlugin from '../plugins/ToolbarPlugin'
import YouTubePlugin from '../plugins/YouTubePlugin'
import {CAN_USE_DOM} from '../utils/canUseDOM'
import {TableContext} from '../plugins/TablePlugin/TablePlugin'
import {AutocompleteContext} from '../context/AutocompleteContext'
import DynamicStyles from '../plugins/DynamicStyles'
import {mergeRefs} from '../utils/custom'
import {OnChangePlugin} from '../plugins/OnChangePlugin'
import {TextEditorTypes} from '../TextEditorTypes'
import LexicalContentEditable from '../ui/ContentEditable'
import CheckValidationsPlugin from '../plugins/CheckValidationsPlugin'
import {CharacterLimitPlugin} from '../plugins/CharacterLimitPlugin'
import HtmlPlugin from '../plugins/HtmlPlugin'
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {CheckListPlugin} from '../plugins/CheckListPlugin'


interface EditorComponentTypes {
  id: string
  setEditorKey?: (value: any) => void
  className?: string
  onChange?: (value: any) => void
  control?: any
  inputClass?: string
  registerKey?: string
  label?: string
  isRequired?: boolean
  error?: any
  errorClass?: string
  labelClass?: string
  errorMessageRef?: any
  readOnly?: boolean
  disabled?: any
  maxLength?: number
  rules?: any
  textEditorRef?: any
  value?: any
  defaultValue?: string | null
  hasDefaultValue?: boolean
  isChangeOnSave?: boolean
  isLoadingOperation?: boolean
  isLoadingData?: boolean
  isReadOnly?: boolean
  editorClass?: string
  isClearChanges?: boolean
  placeholder?: string
  onBlur?: (event: any) => void
  placeholderText?: string
}

export default function EditorComponent({
  id,
  setEditorKey,
  inputClass = '',
  textEditorRef,
  maxLength = 62500,
  isLoadingData = undefined,
  isReadOnly = false,
  placeholderText = 'Enter text here...',
  editorClass = '',
  onChange,
  defaultValue = null,
  isChangeOnSave = false,
  isLoadingOperation = undefined,
  disabled,
  value,
  isClearChanges = false,
  onBlur,
}: EditorComponentTypes): JSX.Element {
  const {
    settings: {
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      hasLinkAttributes,
      isCharLimitUtf8,
      isRichText,
      isDraggableBlock,
      isFooterActions,
      showTableOfContents,
      shouldUseLexicalContextMenu,
      shouldPreserveNewLinesInMarkdown,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings()
  const isEditable = useLexicalEditable()
  const [editor] = useLexicalComposerContext()
  const placeholder = isLoadingData || isReadOnly ? '' : placeholderText
  const {historyState} = useHistoryContext()
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false)
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)
  const [skeletonPlaceholders, setSkeletonPlaceholders] = useState<JSX.Element[]>([])
  const [isDirty, setIsDirty] = useState(false)
  const [editorValue, setEditorValue] = useState<string>('<p class="texteditor_paragraph"></p>')
  const editorRef = useRef<HTMLDivElement | null>(null)

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  const onChangeTextEditor = (value: string) => {
    setEditorValue(value)
    if (!isChangeOnSave) {
      onChange?.(value)
    }
  }

  const onSave = () => {
    onChange?.(editorValue)
    if (isLoadingOperation === undefined) {
      setIsDirty(false)
    }
  }

  const onCancel = () => {
    setEditorKey?.((prevKey: number) => prevKey + 1)
  }

  useEffect(() => {
    if (isClearChanges) {
      onCancel()
    }
  }, [isClearChanges])

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport)
      }
    }
    updateViewPortWidth()
    window.addEventListener('resize', updateViewPortWidth)

    return () => {
      window.removeEventListener('resize', updateViewPortWidth)
    }
  }, [isSmallWidthViewport])

  useEffect(() => {
    if (editor) {
      if (disabled || isReadOnly) {
        editor.setEditable(false)
      } else {
        editor.setEditable(true)
      }
    }
  }, [disabled, isReadOnly, editor])

  useEffect(() => {
    const updatePlaceholders = () => {
      if (editorRef.current) {
        const editorHeight = editorRef.current.clientHeight // Get the height of the editor
        const numPlaceholders = Math.floor(editorHeight / 16) // Calculate number of placeholders (1em = 16px)
        const newPlaceholders = Array.from({length: numPlaceholders}, (_, index) => {
          const randomColSize = Math.floor(Math.random() * 11) + 1 // Random column size between 1 and 12
          return (
            <div
              key={index}
              className={`placeholder col-${randomColSize} rounded-3 mt-2 bg-secondary  cursor-default`}
            ></div>
          )
        })
        setSkeletonPlaceholders(newPlaceholders)
      }
    }

    updatePlaceholders()
    window.addEventListener('resize', updatePlaceholders)

    return () => {
      window.removeEventListener('resize', updatePlaceholders)
    }
  }, [])

  return (
    <div className={`editor-shell ${editorClass}`} id={id}>
      <HistoryContext>
        <TableContext>
          <AutocompleteContext>
            <div
              className={`editor-container ${!isRichText ? 'plain-text' : ''} ${
                isReadOnly ? 'border-0' : ''
              }`}
            >
              {isRichText && !isLoadingData && !isReadOnly && (
                <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
              )}
              {isMaxLength && <MaxLengthPlugin maxLength={30} />}
              <DragDropPaste />
              <AutoFocusPlugin />
              <ClearEditorPlugin />
              <ComponentPickerPlugin />
              <EmojiPickerPlugin />
              <AutoEmbedPlugin />
              <MentionsPlugin />
              <HashtagPlugin />
              <KeywordsPlugin />
              <AutoLinkPlugin />
              <CommentPlugin />
              {isRichText ? (
                <>
                  <HistoryPlugin externalHistoryState={historyState} />

                  <RichTextPlugin
                    contentEditable={
                      <div className='editor-scroller'>
                        <div className='editor' ref={mergeRefs(editorRef, onRef, textEditorRef)}>
                          {!isLoadingData && (
                            <LexicalContentEditable
                              className={
                                `editor-input ${isReadOnly ? 'p-0' : 'min-h-300px '}` + inputClass
                              }
                              placeholder={placeholder}
                              isReadOnly={isReadOnly}
                              disabled={disabled}
                            />
                          )}
                          {isLoadingData && (
                            <p
                              className={`d-flex gap-3 position-absolute w-100 top-0 right-0 row placeholder-glow `}
                            >
                              {skeletonPlaceholders}
                            </p>
                          )}
                        </div>
                      </div>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <MarkdownShortcutPlugin />
                  <CodeHighlightPlugin />
                  <ListPlugin />
                  <CheckListPlugin isAllowCheckOnReadOnly={true} />
                  <ListMaxIndentLevelPlugin maxDepth={7} />
                  <TablePlugin
                    hasCellMerge={tableCellMerge}
                    hasCellBackgroundColor={tableCellBackgroundColor}
                  />
                  <TableCellResizer />
                  <ImagesPlugin />
                  <InlineImagePlugin />
                  <LinkPlugin hasLinkAttributes={hasLinkAttributes} />
                  <PollPlugin />
                  <YouTubePlugin />
                  <FigmaPlugin />
                  <ClickableLinkPlugin disabled={isEditable} />
                  <HorizontalRulePlugin />
                  <TabFocusPlugin />
                  <TabIndentationPlugin />
                  <CollapsiblePlugin />
                  <PageBreakPlugin />
                  <LayoutPlugin />
                  {floatingAnchorElem && !isSmallWidthViewport && (
                    <>
                      {isDraggableBlock && <DraggableBlockPlugin anchorElem={floatingAnchorElem} />}
                      <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                      <FloatingLinkEditorPlugin
                        anchorElem={floatingAnchorElem}
                        isLinkEditMode={isLinkEditMode}
                        setIsLinkEditMode={setIsLinkEditMode}
                      />
                      <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
                      <TableHoverActionsPlugin anchorElem={floatingAnchorElem} />
                      {!isReadOnly && !disabled && (
                        <FloatingTextFormatToolbarPlugin
                          anchorElem={floatingAnchorElem}
                          setIsLinkEditMode={setIsLinkEditMode}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <PlainTextPlugin
                    contentEditable={
                      <LexicalContentEditable
                        placeholder={placeholder}
                        isReadOnly={isReadOnly}
                        disabled={disabled}
                      />
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <HistoryPlugin externalHistoryState={historyState} />
                </>
              )}

              {(isCharLimit || isCharLimitUtf8) && !isLoadingData && !isReadOnly && !disabled && (
                <CharacterLimitPlugin charset={'UTF-8'} maxLength={maxLength} />
              )}

              {isAutocomplete && <AutocompletePlugin />}
              <div>{showTableOfContents && <TableOfContentsPlugin />}</div>
              {shouldUseLexicalContextMenu && <ContextMenuPlugin />}
              {isFooterActions && (
                <ActionsPlugin
                  isRichText={isRichText}
                  shouldPreserveNewLinesInMarkdown={shouldPreserveNewLinesInMarkdown}
                />
              )}
              <DynamicStyles />
              <OnChangePlugin
                onChange={onChangeTextEditor}
                setIsDirty={setIsDirty}
                defaultValue={defaultValue}
              />
              <CheckValidationsPlugin />
              <HtmlPlugin />
              {isDirty && isChangeOnSave && !isReadOnly && (
                <div className='editor-save position-sticky bottom-0 d-flex gap-3 align-items-center justify-content-end border-top py-3 px-5 '>
                  <button className='btn btn-outline bg-white' onClick={onCancel}>
                    Cancel
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={onSave}
                    disabled={isLoadingOperation}
                  >
                    {isLoadingOperation ? 'Saving...' : 'Save'}
                    {isLoadingOperation && (
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </AutocompleteContext>
        </TableContext>
      </HistoryContext>
    </div>
  )
}
