import React, { useContext } from 'react'
import { ProductLimitReportContext } from '../../contexts'
import Search from '../../../../components/Search'
import useGetJobData from '../../../jobs/hooks/useGetJobData'
import usePermission from '../../../../hook/usePermission'
import { formatDate } from '../../../../utils/date'

const ProductLimitReportHeader = () => {
  const {onSearch} = useContext(ProductLimitReportContext)
  const {hasPermission} = usePermission()
  let job_name = 'update_products'
  const {jobData, isProcessing, runTask} = useGetJobData(job_name)

  return (
    <div className='mb-10 d-flex justify-content-between'>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch(searchTerm)
        }}
      />
      <div>
        {hasPermission('settings_jobs', 'write') && (
          <div className='position-relative d-flex align-items-center'>
            <span className='me-2'>Last Sync:</span>
            <span className='me-3'>{formatDate(jobData.last_run_end_time, false)} </span>
            {!isProcessing && (
              <div className='d-flex justify-content-center flex-shrink-0'>
                {
                  <i
                    className='fas fa-sync cursor-pointer fs-5'
                    onClick={() => runTask('update_products')}
                  ></i>
                }
              </div>
            )}
            {isProcessing && (
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-success' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductLimitReportHeader