import {useRef, useState} from 'react'
import ColumnContainer from './components/ColumnContainer'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {createPortal} from 'react-dom'
import TaskCard from './components/TaskCard'
import Loading from '../../modules/loading'
import {capitalizeFirstLetter} from '../../utils/string'
import {handleDragStart} from './utils/handleDragStart'
import {handleDragOver} from './utils/handleDragOver'
import {handleAgileBoardData} from './utils/handleAgileBoardData'
import {DndBoardPageContext} from './context'
import AgileBoardAccordion from './components/AgileBoardAccordion'
import useDefaultDataHandler from './utils/handleDefaultData'
import {CustomPointerSensor} from './utils/customPointerSensor'
import handleDragEnd from './utils/handleDragEnd'

function DndBoard({
  data,
  handleTicketLayout,
  isCreateCardFromColumn,
  handleAddCardFromColumn,
  hasPermission = true,
  readOnly = false,
  onChange,
}: any) {
  const dndComponentRef = useRef<any>(null)
  const [tasks, setTasks] = useState([])
  const [activeTask, setActiveTask] = useState(null)
  const [updatedTaskDetails, setUpdatedTaskDetails] = useState<any>()
  const [tasksCounter, setTasksCounter] = useState<any>(null)
  const [componentWidth, setComponentWidth] = useState(null)

  const {mergedData, uniqueModuleNames, uniqueColumnNames, updatedTasksList, isLoading} =
    handleAgileBoardData(data)

  useDefaultDataHandler(
    updatedTasksList,
    setTasks,
    setTasksCounter,
    isLoading,
    dndComponentRef,
    setComponentWidth,
    tasks
  )

  function onDragStart(event: DragStartEvent) {
    handleDragStart(event, setUpdatedTaskDetails, setActiveTask)
  }

  function onDragOver(event: DragOverEvent) {
    handleDragOver(event, setTasks, setUpdatedTaskDetails, setTasksCounter)
  }

  function onDragEnd(event: DragEndEvent) {
    handleDragEnd(event, setActiveTask, updatedTaskDetails, onChange)
  }

  const sensors = useSensors(
    useSensor(CustomPointerSensor, {
      activationConstraint: {
        distance: 0,
      },
    })
  )

  const accordionItems = uniqueModuleNames.map((module: any) => ({
    id: module.moduleID,
    title: (
      <div className='dnd-module-name'>
        {capitalizeFirstLetter(module.moduleName)} ({tasksCounter?.modules?.[module.moduleID] || 0})
      </div>
    ),
    content: (
      <div className='board-column-wrapper d-flex row m-0'>
        {mergedData
          .filter((column: any) => column.moduleName === module.moduleName)
          .map((column: any) => (
            <ColumnContainer
              key={column.columnID}
              column={column}
              tasks={tasks.filter((task: any) => task.columnID === column.columnID)}
            />
          ))}
      </div>
    ),
  }))

  return (
    <DndBoardPageContext.Provider
      value={{
        handleTicketLayout,
        isCreateCardFromColumn,
        handleAddCardFromColumn,
        hasPermission,
        readOnly,
      }}
    >
      <div
        className='board-main-container overflow-auto w-100 position-relative min-h-350px'
        dnd-board-area='true'
        ref={dndComponentRef}
      >
        {isLoading && <Loading />}
        {!isLoading && mergedData?.length === 0 && <div className='text-center fw-bold'>No data found.</div>}
        <div className='board-heading-section-sticky'>
        <div className='board-heading-section d-flex row mx-0 pt-5 pb-5 mb-5'>
          {uniqueColumnNames.map((column: any) => (
            <div className='board-heading-item w-300px'>
              <div className='d-flex align-items-center px-6 py-3 fw-bolder text-dark text-uppercase rounded-1 justify-content-between bg-secondary bg-opacity-50'>
                {capitalizeFirstLetter(column.columnName)}
                <span className='bg-secondary w-25px h-25px board-heading-item-count text-dark ms-2 fs-8 fw-semibold'>
                {tasksCounter?.columns?.[column.cID] || 0}
                </span>
              </div>
            </div>
          ))}
        </div>
        </div>
        <div
          style={{width: componentWidth ? `${componentWidth}px` : 'auto'}}
          className='board-card-section'
        >
          {!readOnly ? (
            <DndContext
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              sensors={sensors}
            >
              <AgileBoardAccordion items={accordionItems} defaultOpen='All' isLoading />

              {createPortal(
                <DragOverlay>{activeTask && <TaskCard task={activeTask} />}</DragOverlay>,
                document.body
              )}
            </DndContext>
          ) : (
            <>
              <AgileBoardAccordion items={accordionItems} defaultOpen='All' />

              {createPortal(
                <DragOverlay>{activeTask && <TaskCard task={activeTask} />}</DragOverlay>,
                document.body
              )}
            </>
          )}
        </div>
      </div>
    </DndBoardPageContext.Provider>
  )
}

export default DndBoard
