import {useContext} from 'react'
import Loading from '../../../loading'
import DndTable from '../../../../components/dndTable/DndTable'
import {ProjectsDefaultColumnsPageContext} from '../../context'
import DefaultColumnsTableRow from './DefaultColumnsTableRow'
import { defaultColumnsTableColumns } from '../../utils'

export default function DefaultColumnsTable() {
  const {
    updateGlobalDefaultColumnsTableSorting,
    globalDefaultColumnsList,
    isLoadingGlobalDefaultColumnsList,
    hasProjectSettingsPermission,
    searchValue,
  } = useContext(ProjectsDefaultColumnsPageContext)

  const handleUpdatedTableRows = (tableData: any) => {
    const defaultColumnsTableRowOrder = {
      default_columns_ids: tableData.map((item: any) => item.id),
    }
    updateGlobalDefaultColumnsTableSorting(defaultColumnsTableRowOrder)
  }

  return (
    <div className='position-relative'>
      {isLoadingGlobalDefaultColumnsList && <Loading />}
      <DndTable
        id='global-default-columns-table'
        data={globalDefaultColumnsList}
        columns={defaultColumnsTableColumns}
        uniqueId='id'
        TableRow={DefaultColumnsTableRow}
        isLoading={isLoadingGlobalDefaultColumnsList}
        onChange={handleUpdatedTableRows}
        hasWritePermission={hasProjectSettingsPermission}
        searchValue={searchValue}
        noDataText={
          <p>
            No default columns have been added yet. <br /> Please click on 'Add Column' button to
            add default columns.
          </p>
        }
      />
    </div>
  )
}
