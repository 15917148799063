export const DefaultColumnsDefaultValues = (defaultColumnDetails: any, isEdit: boolean) => ({
  name: isEdit ? defaultColumnDetails?.name : '',
  description: isEdit ? defaultColumnDetails?.description : '',
})

export const GlobalCustomFieldsDefaultValues = (customFieldData: any, isEdit: boolean) => ({
  name: isEdit ? customFieldData?.custom_field_name : '',
  group_values: isEdit
    ? customFieldData?.group_values?.split(',').map((value: any) => ({value: value.trim()}))
    : [],
  datatype: isEdit ? customFieldData?.field_type : '',
  is_multiselect_group: isEdit ? customFieldData?.is_multiselect_group : false,
})
