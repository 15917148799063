//<---------------------- Agile Board Page ----------------------->

// Default Values
export const createTicketDefaultValues = (
  columns: any,
  modules: any,
  statuses: any,
  priorities: any,
  isCreateFromBoard: boolean,
  columnId: number | null,
  moduleId: number | null
) => {
  const defaultStatus = statuses?.find((status: any) => status?.is_default)
    ? statuses?.find((status: any) => status?.is_default)?.id
    : statuses?.[0]?.id || 1

  const defaultPriority = priorities?.find((priority: any) => priority?.is_default)
    ? priorities.find((priority: any) => priority?.is_default)?.id
    : priorities?.[0]?.id || 1

  return {
    currentColumnId: isCreateFromBoard ? columnId : columns?.[0]?.column_id,
    moduleId: isCreateFromBoard ? moduleId : modules?.[0]?.module_id,
    status: defaultStatus,
    priority: defaultPriority,
    title: '',
    description: '',
    assignedTo: '',
    estimation: '',
    dueDate: null,
    isArchived: false,
  }
}

//<---------------------- Board Settings Page ----------------------->

// Table Columns
export const boardColumnsTableColumns = (columnId: any) => [
  {key: 'name', label: 'NAME', headerClass: 'min-w-300px'},
  {key: 'description', label: 'DESCRIPTION', headerClass: 'min-w-300px'},
  {
    key: 'statusMapping',
    label: 'STATUS MAPPING',
    headerClass: 'w-250px',
    show: !!columnId,
  },
  {key: 'cards', label: 'CARDS', headerClass: 'w-100px text-center'},
  {key: 'isVisible', label: 'VISIBILITY', headerClass: 'w-100px text-center'},
  {key: 'action', label: 'ACTION', headerClass: 'w-80px text-center'},
]

export const boardModulesTableColumns = [
  {
    key: 'moduleName',
    label: 'NAME',
    headerClass: 'min-w-300px',
  },
  {
    key: 'description',
    label: 'Description',
    headerClass: 'min-w-300px',
  },
  {
    key: 'cards',
    label: 'CARDS',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'isVisible',
    label: 'VISIBILITY',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    headerClass: 'w-80px text-center',
  },
]

export const boardCustomFieldsColumns = [
  {
    key: 'custom_field_name',
    label: 'NAME',
    headerClass: 'min-w-400px',
  },
  {
    key: 'field_type',
    label: 'FIELD TYPE',
    headerClass: 'w-150px',
  },
  {
    key: 'card_counts',
    label: 'USED IN CARDS',
    headerClass: 'w-150px text-center',
  },
  {
    key: 'isVisible',
    label: 'VISIBILITY',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    headerClass: 'w-80px text-center',
  },
]

// Default Values

export const projectMappingDefaultValues = (projectMappingDetails: any) => {
  return {
    projectType: projectMappingDetails?.table_id > 0 ? 'custom' : 'standard',
    tableId: projectMappingDetails?.table_id ?? 0,
    columnId: projectMappingDetails?.db_table_column ?? '',
    isColumnSelected: true,
  }
}

export const AddBoardColumndefaultValues = (columnData: any, isEdit: boolean): any => ({
  name: isEdit ? columnData.columnName : '',
  description: isEdit ? columnData.description : '',
  isVisible: isEdit ? columnData.is_visible : true,
})

export const AddBoardModuledefaultValues = (moduleData: any, isEdit: boolean): any => ({
  name: isEdit ? moduleData.moduleName : '',
  description: isEdit ? moduleData.description : '',
  isVisible: isEdit ? moduleData.isVisible : true,
})
