import React from 'react'
import GlobalMemberActionsOverlay from '../sections/overlays/GlobalMemberActionsOverlay'

//<---------------------- Global Project Members Page ---------------------->

export const globalProjectMembersTableColumns = [
  {key: 'name', label: 'NAME', isSorted: true, headerStyle: 'min-w-180px'},
  {
    key: 'usage_count',
    label: 'PROJECTS',
    isSorted: true,
    headerStyle: 'w-125px',
  },
  {
    key: 'rcreated_at',
    label: 'REGISTRATION DATE',
    isSorted: false,
    headerStyle: 'w-225px',
  },
  {
    key: 'status',
    label: 'STATUS',
    isSorted: false,
    headerStyle: 'w-150px',
    style: 'justify-content-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    render: (row: any) => React.createElement(GlobalMemberActionsOverlay, {memberData: row}),
    headerStyle: 'w-80px',
  },
]

export const memberProjectsListModalColumns = [
  {key: 'project_name', label: 'PROJECT'},
  {key: 'status', label: 'STATUS', style: 'justify-content-end'},
]

//<---------------------- Default Columns Page ---------------------->

export const defaultColumnsTableColumns = [
  {
    key: 'name',
    label: 'Name',
    headerClass: 'min-w-400px',
  },
  {
    key: 'description',
    label: 'Description',
    headerClass: 'min-w-400px',
  },
  {
    key: 'action',
    label: 'Action',
    headerClass: 'w-80px text-center',
  },
]

//<---------------------- Global Custom Fields Page ---------------------->

export const globalCustomFieldsTableColumns = [
  {
    key: 'name',
    label: 'NAME',
    headerClass: 'min-w-400px',
  },
  {
    key: 'field_type',
    label: 'FIELD TYPE',
    headerClass: 'w-150px',
  },
  {
    key: 'used_in_projects',
    label: 'USED IN PROJECTS',
    headerClass: 'w-200px',
  },
  {
    key: 'action',
    label: 'ACTION',
    headerClass: 'w-80px text-center',
  },
]

export const customFieldTypesDropdownList = [
  {label: 'Text', value: 'string'},
  {label: 'Number', value: 'float'},
  {label: 'Date', value: 'date'},
  {label: 'Selection', value: 'group'},
  {label: 'Multiple Selection', value: 'multi-group'},
]
