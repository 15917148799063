import {useLocation} from 'react-router-dom'
import store from '../../../../../redux/store'

const ProjectsTableActionDropdown = ({
  setShowModal,
  setShowArchiveModal,
  setDeleteModal,
  projectDetails,
}: any) => {
  const isActiveProjects = useLocation().pathname === '/projects/active'
  const user: any = store.getState()?.auth?.user

  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleArchiveproject = () => {
    setShowArchiveModal(true)
    document.body.click()
  }

  const handleDeleteproject = () => {
    setDeleteModal(true)
    document.body.click()
  }
  return (
    <>
      <div className='px-2 py-1'>
        <span className='text-dark fw-bold text-hover-primary fs-6' onClick={handleEditClick}>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-2 py-1'>
        <span
          className={`text-dark fw-bold text-hover-primary fs-6 text-hover-${isActiveProjects ? 'danger' : 'primary'} fs-6`}
          onClick={handleArchiveproject}
        >
          <label>{isActiveProjects ? 'Archive' : 'Unarchive'}</label>
        </span>
      </div>
      {!isActiveProjects && user?.username === projectDetails?.ownerUsername && (
        <div className='px-2 py-1'>
          <span className='text-dark fw-bold text-hover-danger fs-6' onClick={handleDeleteproject}>
            <label>Delete</label>
          </span>
        </div>
      )}
    </>
  )
}

export default ProjectsTableActionDropdown
