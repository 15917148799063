/* eslint-disable */
import {DatePicker} from 'rsuite'
import InputDateTypes from './inputDateTypes'
import {isEmpty} from '../../utils/common'
import {useEffect, useState} from 'react'

const InputDate = ({
  id,
  className = '',
  inputClass = '',
  labelClass = '',
  isRequired = false,
  label,
  onChange,
  format = 'dd MMM yyyy',
  menuClassName = 'input-date-picker',
  placement = 'auto',
  error,
  errorClass,
  cleanable = true,
  value,
  defaultValue,
  placeholder = 'DD MMM YYYY',
  isLoading,
  isDisabled,
  isReadOnly,
  onBlur,
}: InputDateTypes) => {
  const [selectedTime, setSelectedTime] = useState(null)
  // Handle onBlur event
  const handleBlur = () => {
    if (onBlur) {
      onBlur(selectedTime)
    }
  }

  // Mutation observer to detect when rs-picker-date-menu disappears for handle onBlur event
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const dropdownContent = document.querySelector('.rs-picker-date-menu')
        if (!dropdownContent && mutation.removedNodes.length > 0) {
          handleBlur()
        }
      })
    })
    // Observe the document body for changes to detect when the rs-picker-date-menu disappears
    const targetNode = document.body
    observer.observe(targetNode, {childList: true, subtree: true})
    return () => {
      observer.disconnect()
    }
  }, [selectedTime])

  return (
    <div className={className} id={id}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <style>
        {isLoading &&
          `#${id} .rs-stack-item {
          .rs-icon{
              display: none;
            }
          }
        `}
      </style>
      <div className='position-relative'>
        <DatePicker<any>
          id={id}
          className={`ad-date-picker d-flex w-100 z-index-0 position-relative px-0 ${inputClass}`}
          placement={placement}
          menuClassName={menuClassName}
          format={format}
          cleanable={cleanable}
          onChange={(time: any) => {
            onChange?.(time)
            setSelectedTime(time)
          }}
          {...(!isEmpty(value) && value !== 'None' ? {value: new Date(value)} : {})}
          defaultValue={
            !isEmpty(defaultValue) && defaultValue !== 'None' ? new Date(defaultValue) : null
          }
          placeholder={placeholder}
          disabled={isDisabled}
          readOnly={isLoading || isReadOnly}
        />

        {isLoading && (
          <div className='form-field-loader'>
            <span className='text-primary spinner-border spinner-border-sm align-middle z-index-1 bg-white'></span>
          </div>
        )}

        {error && error?.message ? (
          <p className={`invalid-feedback ${errorClass}`}>{error.message}</p>
        ) : null}
      </div>
    </div>
  )
}

export default InputDate
