/* eslint-disable */
import {useEffect, useMemo, useRef, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import {Controller} from 'react-hook-form'
import {dataToSelectOptions, isEmpty} from '../../utils/common'
import {MultipleSelectTypes} from './MultiSelectTypes'

function MultipleSelect({
  id,
  options,
  defaultValues,
  placeholder = 'Select',
  disabled,
  error,
  isOpen,
  label,
  isRequired,
  className,
  inputClass = '',
  onMenuToggle,
  handleSelected,
  registerKey,
  control,
  rules,
  isLoading,
  isReadOnly = false,
  hasSelectAll = true,
  labelClass = '',
  labelKey = 'label',
  valueKey = 'value',
  valueType = 'string',
  onBlur,
}: MultipleSelectTypes) {
  const [selected, setSelected] = useState<Array<{label: string; value: string}>>([])
  const [registeredValue, setRegisteredValue] = useState<any[]>([])
  const [isDefaultSelected, setIsDefaultSelected] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const formattedOptions =
    options && options?.length > 0
      ? labelKey && valueKey
        ? dataToSelectOptions(options, labelKey, valueKey, valueType)
        : dataToSelectOptions(options, 'label', 'value', valueType)
      : []

  // Memoize default selected values
  const defaultSelected = useMemo(() => {
    if (!isEmpty(options)) {
      if (!isEmpty(defaultValues) && registerKey && isEmpty(registeredValue)) {
        return options.filter((option: any) => defaultValues?.includes(option?.value))
      }
      if (!isEmpty(registeredValue) && registerKey && isEmpty(defaultValues)) {
        return options.filter((option: any) => registeredValue?.includes(option?.value))
      }
      if (!isEmpty(registeredValue) && registerKey && isEmpty(defaultValues)) {
        return []
      }
      if (!isEmpty(defaultValues)) {
        return defaultValues
      }
    }
  }, [defaultValues, registeredValue, options, registerKey])

  // Handle onBlur event
  const handleBlur = () => {
    if (onBlur) {
      onBlur(selected)
    }
  }

  useEffect(() => {
    if (!isEmpty(defaultSelected) && !isDefaultSelected) {
      setSelected(defaultSelected)
      setIsDefaultSelected(true)
    }
  }, [defaultSelected, isDefaultSelected])

  // Mutation observer to detect when dropdown-content disappears for handle onBlur event
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const dropdownContent = document.querySelector('.dropdown-content')
        if (!dropdownContent && mutation.removedNodes.length > 0) {
          handleBlur()
        }
      })
    })
    // Observe the document body for changes to detect when the dropdown-content disappears
    const targetNode = document.body
    observer.observe(targetNode, {childList: true, subtree: true})
    return () => {
      observer.disconnect()
    }
  }, [selected])

  const renderMultiSelect = (onChange?: (values: string[]) => void) => (
    <MultiSelect
      className={`p-0 form-select border ${inputClass} ${
        error ? 'is-invalid border-1 border-danger' : 'border-0'
      }`}
      options={formattedOptions}
      value={selected}
      onChange={(values: any) => {
        setSelected(values)
        handleSelected?.(values)
        onChange?.(values?.map((value: any) => value?.value))
      }}
      labelledBy={placeholder}
      isLoading={isLoading}
      disabled={disabled || isReadOnly}
      isOpen={isOpen}
      onMenuToggle={onMenuToggle}
      overrideStrings={{selectSomeItems: placeholder}}
      hasSelectAll={hasSelectAll}
    />
  )

  return (
    <div className={className} id={id} ref={selectRef}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      {registerKey && control ? (
        <Controller
          name={registerKey}
          control={control}
          rules={rules}
          render={({field: {onChange, value}}) => {
            setRegisteredValue(value)
            return renderMultiSelect(onChange)
          }}
        />
      ) : (
        renderMultiSelect(() => {})
      )}
      {error?.message && <p className='invalid-feedback'>{error.message}</p>}
    </div>
  )
}

export default MultipleSelect
