import {createContext} from 'react'
interface InquiresPageListingContextType {
  InquiresData: any[]
  isLoading: boolean
  onPageChange: (e: number) => void 
  onSearch: (searchValue: string) => void
  onSortingChange: (key: string, value: string) => void
  filters: Record<string, any> 
  columns: Array<Record<string, string>> | any
  refetch: () => void
  openModal: boolean
  setOpenModal: (e: boolean) => void 
  clickedId: string
  setClickedId: (e: string) => void
  Representatives: any[]
  onChangeCustomerFilter: (e: string) => void 
}

// Create context with default values and proper type annotations
export const InquiresPageListingContext = createContext<InquiresPageListingContextType>({
  InquiresData: [],
  isLoading: false,
  onPageChange: () => {},
  onSearch: () => {},
  onSortingChange: () => {},
  filters: {},
  columns: [], // Default to empty array
  refetch: () => {},
  openModal: false,
  setOpenModal: () => {},
  clickedId: '',
  setClickedId: () => {},
  Representatives: [],
  onChangeCustomerFilter: () => {},
})
