import {DragEndEvent} from '@dnd-kit/core'

const handleDragEnd = (
  event: DragEndEvent,
  setActiveTask: Function,
  updatedTaskDetails: any,
  onChange: Function
) => {
  setActiveTask(null)
  const {active} = event
  const {newCID, prevCID, newModuleID, prevModuleID, prevColumnSorting} = updatedTaskDetails
  const newColumnSorting = active?.data?.current?.sortable?.items

  const hasChanged =
    newCID !== prevCID ||
    newModuleID !== prevModuleID ||
    JSON.stringify(newColumnSorting) !== JSON.stringify(prevColumnSorting)

  const updatedData = {...updatedTaskDetails, newColumnSorting}

  if (hasChanged) {
    onChange?.(updatedData)
  }
}

export default handleDragEnd
