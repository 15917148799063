import InputNumberTypes from './InputNumberTypes'
import {validateNumberInputField} from '../../utils/validateNumberInputField'
import {useEffect, useState} from 'react'
import {parseNumber} from '../../utils/parseNumber'
import {capitalizeFirstLetter} from '../../utils/string'

function InputNumber({
  id,
  name,
  placeholder,
  error,
  label,
  register,
  isRequired,
  value,
  className = '',
  inputClass = '',
  onChange,
  onBlur,
  inputNumberRef,
  isFloat = false,
  decimalLimit = 2,
  isRoundOff = false,
  maxLimit = Infinity,
  minLimit = -Infinity,
  isFormatted = false,
  isReadOnly = false,
  maxLength = Infinity,
  totalLimit = Infinity,
  disabled = false,
  isErrorWarning = false,
  errorClass = '',
  onFocus,
  customErrorMessage,
  isCheckValidationOnLoad = true,
  setIsValid,
  labelClass = '',
  ...rest
}: InputNumberTypes) {
  const [errorMessage, setErrorMessage] = useState<any>(null)
  const {
    name: registerName = name,
    onChange: registerOnChange = onChange,
    onBlur: registerOnBlur = onBlur,
    ref: registerRef = inputNumberRef,
  } = register && register.name ? register : {}

  useEffect(() => {
    if (value && setIsValid && isCheckValidationOnLoad) {
      const convertedNumber = parseNumber(value, null)
      if (convertedNumber === null) {
        if (isRequired) {
          setErrorMessage(
            `${capitalizeFirstLetter(label ? label : name ? name : 'this')} is required.`
          )
        } else {
          return
        }
        setIsValid?.(false)
      } else if (convertedNumber && convertedNumber > maxLimit) {
        setIsValid(false)
        setErrorMessage(
          customErrorMessage?.maxLimitError
            ? customErrorMessage.maxLimitError(maxLimit)
            : `Max allowed value is ${maxLimit}`
        )
      } else if (convertedNumber && convertedNumber < minLimit) {
        setIsValid(false)
        setErrorMessage(
          customErrorMessage?.minLimitError
            ? customErrorMessage.minLimitError(minLimit)
            : `Min allowed value is ${minLimit}`
        )
      } else if (convertedNumber && convertedNumber > totalLimit) {
        setIsValid(false)
        setErrorMessage(
          customErrorMessage?.totalLimitError
            ? customErrorMessage.totalLimitError(totalLimit)
            : `Total allowed value is ${totalLimit}`
        )
      } else {
        setIsValid(true)
        setErrorMessage(null)
      }
    }
    // eslint-disable-next-line
  }, [value, minLimit, maxLimit])

  const handleChange = (e: any) => {
    const inputValue = parseNumber(e.target.value, null)

    if (isErrorWarning) {
      if (inputValue === null) {
        if (isRequired) {
          setErrorMessage(
            `${capitalizeFirstLetter(label ? label : name ? name : 'this')} is required.`
          )
        } else {
          return
        }
        setIsValid?.(false)
      } else if (inputValue > (parseNumber(maxLimit) || Infinity)) {
        setErrorMessage(
          customErrorMessage?.maxLimitError
            ? customErrorMessage.maxLimitError(maxLimit)
            : `Max allowed value is ${maxLimit}`
        )
        setIsValid?.(false)
      } else if (inputValue < (parseNumber(minLimit) || -Infinity)) {
        setErrorMessage(
          customErrorMessage?.minLimitError
            ? customErrorMessage.minLimitError(minLimit)
            : `Min allowed value is ${minLimit}`
        )
        setIsValid?.(false)
      } else if (inputValue > (parseNumber(totalLimit) || Infinity)) {
        setErrorMessage(
          customErrorMessage?.totalLimitError
            ? customErrorMessage.totalLimitError(totalLimit)
            : `Total allowed value is ${totalLimit}`
        )
      } else {
        setErrorMessage(null)
        setIsValid?.(true)
      }
    }

    if (onChange) onChange(e)
    if (registerOnChange) registerOnChange(e)
  }

  const handleBlur = (e: any) => {
    if (onBlur) onBlur(e)
    if (registerOnBlur) registerOnBlur(e)
  }

  const handleRef = (e: any) => {
    if (inputNumberRef)
      (inputNumberRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = e
    if (registerRef) registerRef(e)
  }

  return (
    <div className={className}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <input
        type='text'
        className={`form-control ${inputClass} ${error || errorMessage ? 'is-invalid' : ''}`}
        aria-label='number input'
        name={registerName || name}
        ref={handleRef}
        onChange={handleChange}
        onBlur={handleBlur}
        id={id}
        placeholder={placeholder}
        readOnly={isReadOnly}
        disabled={disabled}
        {...(value ? {value: value} : {})}
        maxLength={maxLength}
        onFocus={onFocus}
        onInput={(e: any) => {
          validateNumberInputField(
            e,
            isFloat,
            decimalLimit,
            isRoundOff,
            maxLimit,
            isFormatted,
            isErrorWarning
          )
        }}
        {...rest}
      />
      {isErrorWarning && (minLimit || maxLimit) && errorMessage && (
        <p className={`text-danger mt-2 mb-0 ${errorClass}`}>{errorMessage}</p>
      )}
      {error && error?.message ? (
        <p className={`invalid-feedback ${errorClass}`}>{error.message}</p>
      ) : null}
    </div>
  )
}

export default InputNumber
