import {useContext} from 'react'
import CustomFieldActionsOverlay from '../../overlays/CustomFieldActionsOverlay'
import {BoardSettingsPageContext} from '../../../context'
import {getBadgeColor} from '../../../../../utils/badge'

const CustomFIeldsTableRow = ({dndSettings, row, hasWritePermission, dndImage}: any) => {
  const {isLoadingCustomFieldOperation, customFieldOperationId} =
    useContext(BoardSettingsPageContext)
  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row?.custom_field_name}
      </td>
      <td>{row?.field_type}</td>
      <td>
        <div className='text-center'>{row?.card_counts}</div>
      </td>
      <td>
        <div className='text-center'>
          <span
            className={`badge ${getBadgeColor(
              row?.is_visible ? 'active' : 'inactive',
              'light'
            )} badge-lg`}
          >
            <div className='align-items-center'>{row?.is_visible ? 'Enabled' : 'Disabled'}</div>
          </span>
        </div>
      </td>
      {hasWritePermission && (
        <td>
          {isLoadingCustomFieldOperation && customFieldOperationId === row?.id ? (
            <div className='d-flex justify-content-center'>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </div>
          ) : (
            <CustomFieldActionsOverlay customFieldData={row} />
          )}
        </td>
      )}
    </tr>
  )
}

export default CustomFIeldsTableRow
