import {useParams} from 'react-router-dom'
import useApi from '../../../../services/useApi'

export const useGetAgileBoardData = () => {
  const Api = useApi()
  const {projectId} = useParams()

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/cards`, {
    queryId: 'agile-board-data',
  })

  return {
    agileBoardData: response?.data || [],
    isLoading: isFetching,
  }
}
