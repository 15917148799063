import {Link, useLocation} from 'react-router-dom'

function ProjectsSettingsPageNavigation() {
  const location = useLocation()

  return (
    <div className=''>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/projects/settings/members' && 'active')
            }
            to='/projects/settings/members'
          >
            Members
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/projects/settings/custom-fields' && 'active')
            }
            to='/projects/settings/custom-fields'
          >
            Custom Fields
          </Link>
        </li>

        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/projects/settings/default-columns' && 'active')
            }
            to='/projects/settings/default-columns'
          >
            Default Columns
          </Link>
        </li>

        {/* <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/projects/settings/system-fields' && 'active')
            }
            to='/projects/settings/system-fields'
          >
            System Fields
          </Link>
        </li> */}
      </ul>
    </div>
  )
}

export default ProjectsSettingsPageNavigation
