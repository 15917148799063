import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../context'

const BoardModulesActionDropdown = ({setShowModal, setDeleteModal, moduleData}: any) => {
  const {boardModulesList, updateModule} = useContext(BoardSettingsPageContext)
  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setDeleteModal(true)
    document.body.click()
  }

  const handleModuleVisibility = () => {
    updateModule(moduleData?.moduleId, {isVisible: !moduleData?.isVisible})
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={() => handleEditClick()}
      >
        Edit
      </span>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={handleModuleVisibility}
      >
        {moduleData?.isVisible ? 'Disable' : 'Enable'}
      </span>
      {moduleData?.cards === 0 && boardModulesList?.length > 1 && (
        <span
          onClick={() => handleDeleteClick()}
          className='text-dark fw-bold text-hover-danger fs-6 px-2 py-1 cursor-pointer'
        >
          Delete
        </span>
      )}
    </div>
  )
}

export default BoardModulesActionDropdown
