import usePermission from '../../../../../hook/usePermission';
import { getBadgeColor } from '../../../../../utils/badge';
import parseHideProducts from '../../services/hideProducts';
import OverlayModal from '../modal/OverlayModal';

function HideVariantRuleTableRow({row, key}: any) {
  const product = parseHideProducts(row)   
  const {hasPermission} = usePermission()
  return (
    <>
      <tr key={key}>
        <td>
          <div className='align-items-center'>{product['product_name']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['variant_sku']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['product_id']}</div>
        </td>        
        <td>
          <div className='align-items-center'>{product['out_of_stock_days']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['hide_classification']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['hide_product_name_prefix']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['created_at']}</div>
        </td>
        <td>
          <span className={`badge ${getBadgeColor(product['is_active'], 'light')} badge-lg`}>
            <div className='align-items-center'>{product['is_active']}</div>
          </span>
        </td>
        {hasPermission('analytics_automation', 'write') && (

       
          <td>
            <div className='d-flex justify-content-center flex-shrink-0'>
            <OverlayModal
              is_product={false}
              sku={product['variant_sku']}
              outOfDays={product['out_of_stock_days']}
              hide_classification={product['hide_classification']}
              prefix={product['hide_product_name_prefix']}
            />
            </div>
          </td>)}
      </tr>
    </>
  )
}

export default HideVariantRuleTableRow
