import useApi from '../../../services/useApi'

const useGetProjectPriorites = () => {
  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/card-priorities`)

  return {
    projectPriorities: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetProjectPriorites
