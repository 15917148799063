import {useForm} from 'react-hook-form'
import {SectionForm} from '../../../components/SectionForm'
import {BoardSettingsPageContext, ProjectDetailsContext} from '../context'
import {useContext, useEffect} from 'react'
import {projectMappingDefaultValues} from '../utils'

const ProjectMappingSection = () => {
  const {
    hasBoardSettingsPermission,
    projectMappingDetails,
    setProjectMappingDetails,
    projectTablesDropdownList,
    isLoadingProjectTablesDropdownList,
    tableColumnsDropdownList,
    isLoadingTableColumnsDropdownList,
    isLoadingTableStatusDropdownList,
    isLoadingProjectMappingOperations,
    updateTableColumn,
  } = useContext(BoardSettingsPageContext)
  const {projectDetails, isLoading} = useContext(ProjectDetailsContext)

  const {
    register,
    control,
    watch,
    reset,
    setValue,
    formState: {isDirty},
  } = useForm<any>({
    defaultValues: projectMappingDetails,
  })

  const {projectType, tableId} = watch()
  const isCustomProject = projectType === 'custom'
  const isProjectSelected = tableId !== 0

  useEffect(() => {
    if (!isDirty) {
      reset(projectMappingDetails)
    }
  }, [projectMappingDetails, isDirty, reset])

  return (
    <div className='d-flex flex-column gap-7'>
      <SectionForm
        id='project-mapping'
        title='Project Mapping'
        titleClass='mb-7'
        className='row col-md-6'
        commonClassNames='d-flex gap-3 mb-5 align-items-center'
        commonLabelClass='col-md-3 mb-0'
        commonInputClass='col-md-4'
        register={register}
        control={control}
        isDisabled={isLoading || !hasBoardSettingsPermission}
        inputs={[
          {
            id: 'project-type',
            label: 'Project Type',
            isRadio: true,
            registerKey: 'projectType',
            isLoading: isLoading,
            disabled: projectDetails?.project_mapping?.table_id,
            children: [
              {
                id: 'project-type-standard',
                label: 'Standard',
                value: 'standard',
                name: 'projectType',
                onChange: () => {
                  reset(projectMappingDefaultValues)
                  setProjectMappingDetails(projectMappingDefaultValues)
                },
              },
              {
                id: 'project-type-custom',
                label: 'Custom',
                value: 'custom',
                name: 'projectType',
                colClass: 'me-5',
              },
            ],
          },
          {
            id: 'project-table',
            label: 'Project Table',
            isReachSelect: true,
            isNullable: true,
            show: isCustomProject,
            options: projectTablesDropdownList,
            isLoading: isLoadingProjectTablesDropdownList,
            registerKey: 'tableId',
            labelKey: 'lable',
            valueKey: 'id',
            placeholderValue: 0,
            valueType: 'number',
            disabled: projectDetails?.project_mapping?.table_id,
            onChange: (option: any) => {
              setValue('columnId', '')
              setProjectMappingDetails((prev: any) => ({
                ...prev,
                tableId: option.value,
                columnId: '',
              }))
            },
          },
          {
            id: 'select-project-column',
            label: 'Select Column',
            isReachSelect: true,
            isNullable: true,
            show: isCustomProject && isProjectSelected,
            options: tableColumnsDropdownList,
            isLoading:
              isLoadingTableColumnsDropdownList ||
              isLoadingProjectMappingOperations ||
              isLoadingTableStatusDropdownList,
            disabled:
              isLoadingTableColumnsDropdownList ||
              isLoadingProjectMappingOperations ||
              isLoadingTableStatusDropdownList ||
              projectDetails?.project_mapping?.db_table_column,
            registerKey: 'columnId',
            labelKey: 'column_label',
            valueKey: 'column_key',
            onChange: (option) => {
              updateTableColumn()
              setProjectMappingDetails((prev: any) => ({
                ...prev,
                columnId: option.value,
              }))
            },
          },
        ]}
      />

      {isCustomProject && (
        <SectionForm
          id='ticket-setting'
          title='Ticket Setting'
          titleClass='mb-7'
          className='row col-md-6'
          commonClassNames='d-flex gap-3 mb-5 align-items-center'
          commonLabelClass='col-md-3 mb-0'
          commonInputClass='col-md-4'
          isDisabled={!hasBoardSettingsPermission}
          inputs={[
            {
              id: 'ticket-name',
              label: 'Ticket Name',
              isReachSelect: true,
            },
            {
              id: 'default-assignee',
              label: 'Default Assignee',
              isReachSelect: true,
            },
          ]}
        />
      )}
    </div>
  )
}

export default ProjectMappingSection
