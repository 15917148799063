import {useContext, useState} from 'react'
import {AgileBoardPageContext} from '../../context'
import CreateTicketModal from '../modals/CreateTicketModal'

const AgileBoardPageHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const {hasBoardPermission} = useContext(AgileBoardPageContext)

  return (
    <div className='d-flex justify-content-end mb-8'>
      {hasBoardPermission && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Create Ticket
        </button>
      )}
      {showModal && <CreateTicketModal show={showModal} onClose={() => setShowModal(false)} />}
    </div>
  )
}

export {AgileBoardPageHeader}
