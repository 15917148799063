import {createContext} from 'react'

export const ProjectsMembersPageContext = createContext({
  hasProjectSettingsPermission: false,
  globalProjectsMembersDropdownList: [],
  isLoadingGlobalProjectsMembersDropdownList: false,

  globalProjectsMembersList: [],
  isLoadingGlobalProjectsMembersList: false,
  onSearch: (e: string) => {},
  searchValue: '',
  filters: {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},

  addGlobalProjectMember: (globalProjectMemberDetails: any) => {},
  removeGlobalProjectMember: (globalMemberId: string) => {},
  isLoadingGlobalProjectsMembersOperation: false,
})

export const ProjectsCustomFieldsPageContext = createContext({
  hasProjectSettingsPermission: false,
  globalCustomFieldsList: [],
  isLoadingGlobalCustomFieldList: false,
  onSearch: (e: string) => {},
  searchValue: '',

  createGlobalCustomField: (customFieldDetails: any, setError: Function) => {},
  updateGlobalCustomField: (
    cFieldId: string,
    customFieldDetails: any,
    dirtyFields: any,
    setError: Function
  ) => {},
  updateGlobalCustomFieldTableSorting: (customFieldsTableRowOrder: any) => {},
  deleteGlobalCustomField: (cFieldId: string) => {},
  isLoadingGlobalCustomFieldOperation: false,
})

export const ProjectsDefaultColumnsPageContext = createContext({
  hasProjectSettingsPermission: false,
  globalDefaultColumnsList: [],
  isLoadingGlobalDefaultColumnsList: false,
  onSearch: (e: string) => {},
  searchValue: '',

  createGlobalDefaultColumn: (defaultColumnDetails: any, setError: Function) => {},
  updateGlobalDefaultColumn: (
    defaultColumnId: string,
    defaultColumnDetails: any,
    dirtyFields: any,
    setError: Function
  ) => {},
  updateGlobalDefaultColumnsTableSorting: (defaultColumnsTableRowOrder: any) => {},
  deleteGlobalDefaultColumn: (defaultColumnId: string) => {},
  isLoadingGlobalDefaultColumnOperation: false,
})
